import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResultModel } from '../../models/api.result.model/result.model';
import { saveGLDraftSuggestion, SPLicenseConfigBean } from '../../models/citizen.login.model/citizen.profile.model';
import { AppSetting, AppSettingService } from '../app.setting';
import { CustomHttpClient } from '../httpclient/custom.httpclient';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AESEncryptDecryptService } from 'projects/common-lib/src/lib/helper-lib/services/aesencrypt-decrypt.service';



@Injectable({
    providedIn: 'root'
})
export class CommonService {
    [x: string]: any;

    private settings!: AppSetting;

    constructor(private appSettingsService: AppSettingService, private customeHttpClient: CustomHttpClient, private http: HttpClient,private _AESEncryptDecryptService: AESEncryptDecryptService) {
        this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
            this.settings = settings;
        });

    }

    getAppSetting(){
        return this.settings;
    }

    getDistricts(): Observable<any> {
        let encVal = this._AESEncryptDecryptService.encrypt("type_id=3&parent_id=1");
        return this.customeHttpClient.Get<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/get_demographies_map?request="+encVal
        ).pipe(
            map((result: any) => {

                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }



    getAllDraftCitizenGL() {
        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_services_gl_url + "common/gl/getAllDraftCitizenGlData"
            ).pipe(
                map((result: any) => {
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }
                })
            );
    }

    getDistrictsWithParent(parent: any): Observable<any> {
        let encVal = this._AESEncryptDecryptService.encrypt("type_id=3&parent_id=" + parent);
        return this.customeHttpClient.Get<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/get_demographies_map?request=" + encVal
        ).pipe(
            map((result: any) => {

                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }

    getStates(): Observable<any> {
        let encVal = this._AESEncryptDecryptService.encrypt("type_id=1");
        return this.customeHttpClient.Get<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/get_demographies_map?request="+encVal
        ).pipe(
            map((result: any) => {

                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }

    getBlocks(disId: number) {
        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/get_demography_by_parent" + "?parent=" + disId
            ).pipe(
                map((result: any) => {


                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }

    getFranchiseApi(): Observable<any> {
        return this.customeHttpClient.Get<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/get_all_api_list"
        ).pipe(
            map((result: any) => {

                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }

    getAllSpFunctionalityList(): Observable<any> {
        return this.customeHttpClient.Get<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/get_all_sp_functionality_list"
        ).pipe(
            map((result: any) => {

                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }

    getBankDetails(): Observable<any> {
        return this.customeHttpClient.Get<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/get_all_bank_master_list"
        ).pipe(
            map((result: any) => {

                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }

    getIfscCodeList(bank_id: any, search: any) {
        //
        //  
        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/get_bank_ifsc_list?bank_id=" + bank_id + "&search=" + search
            ).pipe(
                map((result: any) => {

                    //     
                    return result.responseData;
                })
            );
    }

    getdemographyByParentAndtype(typeid: any, parentid: any): Observable<any> {
        let encVal = this._AESEncryptDecryptService.encrypt("type_id=" + typeid + "&parent_id=" + parentid);
        return this.customeHttpClient.Get<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/get_demographies_map?request=" + encVal
        ).pipe(
            map((result: any) => {

                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }


    getdraftDemographyByParentAndtype(typeid: any, parentid: any): Observable<any> {
        let encVal = this._AESEncryptDecryptService.encrypt("type_id=" + typeid + "&parent_id=" + parentid);
        return this.customeHttpClient.Get<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/get_draft_demographies_map?request=" + encVal 
        ).pipe(
            map((result: any) => {

                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }

    getdraftDemographyById(encVal: any): Observable<any> {
       // let encVal = this._AESEncryptDecryptService.encrypt("type_id=" + typeid + "&demographyId=" + demographyId);
        return this.customeHttpClient.Get<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/get_draft_demographiesbyId?request=" + encVal 
        ).pipe(
            map((result: any) => {

                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }


    getAllStatusByType(type: any): Observable<any> {
        return this.customeHttpClient.Get<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/get_all_status_by_type?type=" + type
        ).pipe(
            map((result: any) => {


                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }

    getWalletDetailsByUserWise(sPLicenseConfigBeanNew: SPLicenseConfigBean) {
        return this.customeHttpClient
            .Post<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/getonlinepaymentresdetails",
                sPLicenseConfigBeanNew
            ).pipe(
                map((result: any) => {
                    if (result && result.responseStatus == 'true') {
                        return result.responseData;
                    } else {
                        return result.responseMessage;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }
    // service for draft guideline for suggestion based on district and district office
    get_GLDraftDetailsByOfficeId(search: any,
        filterbystatus: any, active: any,
        financialYear: any,
        statusid: any,
        officeId: any,
        pageno: any,
        iDisplayLength: any,
        usedBy: any
    ) {
        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_services_gl_url
                + "common/gl/search_gl_draft_common?search=" + search
                + "&filterbystatus=" + filterbystatus
                + "&active=" + active + "&financialYear=" + financialYear + "&statusid=" + statusid
                + "&officeId=" + officeId + "&pageno=" + pageno + "&iDisplayLength=" + iDisplayLength
                + "&usedBy=" + usedBy
            ).pipe(
                map((result: any) => {
                    if (result && result.responseStatus == 'true') {
                        return result.responseData;
                    } else {
                        return result;
                    }
                })
            );
    }
    save_GLDraftSuggestion(saveSuggestionForm: saveGLDraftSuggestion) {
        //post
        //API save_gl_draft_suggestion 
        let httpHeaders = new HttpHeaders({
            "Content-Type": "application/json",
            "Accept-Language": sessionStorage.getItem("selectedLanguage") || ""
          });
        console.log("saveSuggestionForm   =  " + saveSuggestionForm);
        return this.customeHttpClient
            .Post<ResultModel<any>>(
                this.settings.igrs_services_gl_url
                + "common/gl/save_gl_draft_suggestion",
                saveSuggestionForm,  { headers: httpHeaders }
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }





    // getdemographyByParentId(typeid:any,parentid:any):Observable<any>{
    //     return this.customeHttpClient.Get<ResultModel<any>>(
    //         // get_demographies_map
    //          //this.settings.igrs_gateway_url + "common/get_draft_demographies_map?type_id="+typeid+"&parent_id="+parentid
    //          this.settings.igrs_gateway_url + "common/get_demographies_map_by_parentId?type_id="+typeid+"&parent_id="+parentid
    //      ).pipe(
    //          map((result: any) => {

    //              if (result && result.responseStatus == 'true') {
    //                  return result;
    //              } else {
    //                  return result;
    //              }

    //          })
    //      );
    //  }

    getdemographyByParentIdForWardVillageColony(parentid: any): Observable<any> {
        let encVal = this._AESEncryptDecryptService.encrypt("parent_id=" + parentid);
        return this.customeHttpClient.Get<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/get_demographies_map_by_parentId?request=" + encVal
        ).pipe(
            map((result: any) => {

                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }
    // need to change document search module
    getCountries(): Observable<any> {
        return this.customeHttpClient.Get<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/getAllCountry"
        ).pipe(
            map((result: any) => {

                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }

    // getCountries(): Observable<any> {
    //     return this.customeHttpClient.Get<ResultModel<any>>(
    //         this.settings.igrs_services_url + "common/estamp/getAllCountry"
    //     ).pipe(
    //         map((result: any) => {

    //             if (result && result.responseStatus == 'true') {
    //                 return result;
    //             } else {
    //                 return result;
    //             }

    //         })
    //     );
    // }

    getAllMstQmsStatus(): Observable<any> {
        return this.customeHttpClient.Get<ResultModel<any>>(
            this.settings.igrs_services_url + "common/getAllMstQmsStatus"
        ).pipe(
            map((result: any) => {

                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }

    getSROfficeList(param: any): Observable<any> {
        return this.customeHttpClient.Post<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/get_sro_list", param
        ).pipe(
            map((result: any) => {
                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }

    getAllMstRegistryType(): Observable<any> {
        return this.customeHttpClient.Get<ResultModel<any>>(
            this.settings.igrs_services_url + "common/getAllMstRegistryType"
        ).pipe(
            map((result: any) => {

                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }

    getUserMstStatusDescrp(param:any): Observable<any> {
        return this.customeHttpClient.Get<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/getUserMstStatusDescrp",param
        ).pipe(
            map((result: any) => {

                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }
    getUserAadhaarDetails(param:string): Observable<any> {

        return this.customeHttpClient
        .Get<ResultModel<any>>(
          //this.settings.igrs_gateway_url + "common/get_aadhaar_txn_details?refTxn="+param, param
          this.settings.igrs_gateway_url + "common/get_aadhaar_txn_details?refTxn=" + param
        ).pipe(
          map((result: any) => {
            if (result && result.responseStatus == 'true') {
              return result;
            } else {
              return result;
            }
          })
        );
    }

    getUserPanDetails(param: any) {
        //
        return this.customeHttpClient
            .Get<ResultModel<any>>(
              this.settings.igrs_gateway_url + "common/get_pan_details?refTxn=" + param
            ).pipe(
                map((result: any) => {
    
                    if (result && result.responseStatus == 'true') {
    
                        return result;
                    } else {
                        return result;
                    }
    
                })
            );
    }

    getPOAType(): Observable<any> {
        return this.customeHttpClient.Get<ResultModel<any>>(
            this.settings.igrs_services_url + "common/ereg/getPartyPOAType"
        ).pipe(
            map((result: any) => {

                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }

    PostMorFinAuthClient(method:any,jsonData:any,isBodyAvailable:any){
        if(isBodyAvailable == 0){
          return this.http
          .post<ResultModel<any>>(
            this.settings.mfs500_rd_url + method, {}
          ).pipe(
            map((result: any) => {
              if (result) {
                return result;
              }
            },
              (error: any) => {
                return error;
              }
            )
          );
        }else{
          return this.http
          .post<ResultModel<any>>(
            this.settings.mfs500_rd_url + method, jsonData
          ).pipe(
            map((result: any) => {
              if (result) {
                return result;
              }
            },
              (error: any) => {
                return error;
              }
            )
          );
        }
        
      }
      getTehsilWithParent(parent: any): Observable<any> {
        let encVal = this._AESEncryptDecryptService.encrypt("type_id=12&parent_id=" + parent);
        return this.customeHttpClient.Get<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/get_demographies_map?request=" + encVal
        ).pipe(
            map((result: any) => {

                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }
    getBankFinantialInstituteType() {
        return this.customeHttpClient
          .Get<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/get_bank_finantial_institute_type", {}
          ).pipe(
            map((result: any) => {
    
              if (result && result.responseStatus == 'true') {
                return result;
              } else {
                return result;
              }
    
    
            })
          );
      }

      getIfscCodeListByBankId(bank_id:any) {
              return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/get_bank_ifsc_list_by_bankId?bank_id=" + bank_id
            ).pipe(
                map((result: any) => {
                    return result;
                })
            );
    }
    findBankDetailByIfscCodeAndBankId(ifscCode:any,bankId:any) {
        return this.customeHttpClient
      .Get<ResultModel<any>>(
          this.settings.igrs_gateway_url + "common/findBankDetailByIfscCodeAndBankId?ifscCode=" + ifscCode+"&bankId="+bankId
      ).pipe(
          map((result: any) => {
              return result;
          })
      );
}
findBankBranchListByDistrictIdAndBankId(districtId:any,bankId:any) {
    return this.customeHttpClient
  .Get<ResultModel<any>>(
      this.settings.igrs_gateway_url + "common/findBankBranchListByDistrictId?districtId=" + districtId +"&bankId="+bankId
  ).pipe(
      map((result: any) => {
          return result;
      })
  );
}
getAllLegalEntityTypes() {
    return this.customeHttpClient
  .Get<ResultModel<any>>(
      this.settings.igrs_gateway_url + "common/getAllLegalEntityTypes"
  ).pipe(
      map((result: any) => {
          return result;
      })
  );
}

searchIfscCodeList(bank_id: any, search: any) {
        return this.customeHttpClient
        .Get<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/find_bank_ifsc_list_by_bankId?bank_id=" + bank_id + "&search=" + search
        ).pipe(
            map((result: any) => {
                   return result;
            })
        );
}
getBankDetailByIfscCode(ifscCode:any) {
    return this.customeHttpClient
  .Get<ResultModel<any>>(
      this.settings.igrs_gateway_url + "common/findBankDetailByIfscCode?ifscCode=" + ifscCode
  ).pipe(
      map((result: any) => {
          return result;
      })
  );
}

saveuserLocation(param:any) {
    return this.customeHttpClient.PostApiToken<ResultModel<any>>(
        this.settings.igrs_gateway_url+"citizen/updateGeoLocation",param
      ).pipe(
        map((result: any) => {
          if (result) {
            return result;
          }
        },
          (error: any) => {
            return error;
          }
        )
      )
}

getOfficeLocationDistricWise(url:string,id:string): Observable<any> {
    return this.customeHttpClient.Get<ResultModel<any>>(
        this.settings.igrs_gateway_url + url+id
    ).pipe(
        map((result: any) => {

            if (result && result.responseStatus == 'true') {
                return result;
            } else {
                return result;
            }

        })
    );
}

getBankIfscDetails(bank_id: any, ifsc_id: any) {
    //
    //  
    return this.customeHttpClient
        .Get<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/getBankAndIfscDetailsByBankIdAndIfscDetailId?bankDetailId=" + bank_id + "&ifscDetailId=" + ifsc_id
        ).pipe(
            map((result: any) => {

                //     
                return result;
            })
        );
}
findBankBranchDetailByIfscCode(ifscCode:any) {
    return this.customeHttpClient
  .Get<ResultModel<any>>(
      this.settings.igrs_gateway_url + "common/findBankBranchDetailByIfscCode?ifscCode=" + ifscCode
  ).pipe(
      map((result: any) => {
          return result;
      })
  );
}

    getColonySuggetion(distid:number, searchtext:string){
        return this.customeHttpClient
        .Get<ResultModel<any>>(
            this.settings.igrs_services_search_url + "common/docsearch/searchColonybyname?distid="+distid+"&searchText=" + searchtext
        ).pipe(
            map((result: any) => {
                return result;
            })
        );
    }
}