import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { AlertmessageComponent } from 'projects/common-lib/src/lib/component/alertmessage/alertmessage.component';
import { AESEncryptDecryptService } from 'projects/common-lib/src/lib/helper-lib/services/aesencrypt-decrypt.service';
import { CitizenAuthenticationService } from 'projects/common-lib/src/lib/helper-lib/services/auth.service/citizen-authentication.service';
import { CommonServiceService } from 'projects/common-lib/src/lib/helper-lib/services/common/common-service.service';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { Captcha, Citizen, CitizenForgot, RequestObject } from 'projects/common-lib/src/lib/models';
import { CitizenUserProfileDetails } from 'projects/common-lib/src/lib/models/citizen.login.model/citizen.profile.model';
import { Subscription, timer } from 'rxjs';
import Swal from 'sweetalert2';
import { CitizenService } from '../../service/citizen.service';

@Component({
  selector: 'citizen-forgot-password',
  templateUrl: './citizen-forgot-password.component.html',
  styleUrls: ['./citizen-forgot-password.component.scss']
})
export class CitizenForgotPasswordComponent implements OnInit {
  credential: Citizen = {};
  userProfileModel: CitizenUserProfileDetails = new CitizenUserProfileDetails();
  passhide = true;
  passhide1 = true;
  show = false;
  autohide = true;


  usernameOption: boolean = true;
  otpOption: boolean;
  passwordOption: boolean;
  userName: string;
  otp: string;
  password: string;
  confPassword: string;
  captchaHandler?: Captcha = {};
  captchaImageBase64Img: string = '';
  captchaStr: string = '';
  captchaStr2: string = '';
  captchaId: string = '';
  lan: number;
  lanVal: string = sessionStorage.getItem("selectedLanguage") || "en";
  checkedVal: boolean;
  countDown: Subscription;
  counter = 0; // 10 min 10*60
  tick = 1000; // 1 sec
  @ViewChild(AlertmessageComponent, { static: false }) alertmessage: AlertmessageComponent;
  citizenForgot: CitizenForgot = new CitizenForgot();
  requestObject: RequestObject = new RequestObject();
  paramSource: string;

  userList: CitizenUserProfileDetails[] = [];
  isUser: boolean = false;
  selectedUser: number;
  isMobileNumber: boolean = false;
  status: any;

  constructor(private translateService: TranslateHEService, private ngxService: NgxUiLoaderService
    , private authenticationService: CitizenAuthenticationService
    , private commonService: CommonServiceService
    , private router: Router
    , private translate: TranslateService
    , private titleService: Title
    , private userService: CitizenService
    , private route: ActivatedRoute
    , private _AESEncryptDecryptService: AESEncryptDecryptService) {
      this.translateService.currentApprovalStageMessage.subscribe(msg => {
        this.lan = msg
        this.translate.use(msg==1?'hi':'en')
       })
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(param => {
      //if (param['status'] != undefined && param['status'] == "1")
      console.log("param['source']", param.source);
      this.paramSource = param.source;

    })
    this.getStartupCaptcha();
  }

  changeContactNo(userStatus: number) {
    this.userList = [];
    this.isUser = false;
    this.status = userStatus

    let userName: any = this.userName;
    //var pattern = '^[6-9]\d{9}$';
    var pattern = '^[0-9]*$';
    if (userName != undefined && userName != '') {
      if (userName.match(pattern)) {
        this.isMobileNumber = true;
      } else {
        this.isMobileNumber = false;
      }
    } else {
      this.isMobileNumber = false;
    }

    if ((this.userName != null || this.userName != undefined || this.userName?.trim() != '') && this.isMobileNumber) {
      this.ngxService.start();
      let param = { mobileNo: this.userName, status: userStatus};
      this.authenticationService.getUserListByContactNo(param).subscribe(result => {
        this.ngxService.stop();
        if (result.responseStatus == 'true') {
          this.selectedUser = null;
          if (result.responseData.length > 1) {
            this.isUser = true;
            this.userList = result.responseData;
          } else if (result.responseData.length == 0) {
            this.isUser = false;
            this.alertmessage.errorAlertMessage('office-not-assign');
          } else {
            this.isUser = false;
            this.selectedUser = result.responseData[0].id;
            this.userList = result.responseData;
          }

        } else {
          this.isUser = false;
        }

      },
        err => {
          this.isUser = false;
          this.ngxService.stop()
          //  Swal.fire("Error", "TECHNICAL ERROR PLEASE TRY AFTER SOMETIME !", "error");
          this.alertmessage.errorAlertMessage('tech_error_msg');
        }
      );
    }
  }

  selectUser() {
    console.log("Select User---------", this.selectedUser)
  }

  onSubmitUserNameForOtp() {

    if(this.isMobileNumber){
      // this.citizenForgot.userId = this.selectedUser;
      // this.citizenForgot.userName = this.userName;
      this.credential.userId = this.selectedUser;
      this.credential.userName = this.userName;
    } else {
      // this.citizenForgot.userId = null;
      // this.citizenForgot.userName = this.userName;
      this.credential.userId = null;
      this.credential.userName = this.userName;
    }

    this.ngxService.start()
    //this.citizenForgot.userName = this.userName;
    this.credential.userType = 'citizen';
    this.credential.status = this.status;
    this.credential.captchaId = this.captchaId;
    this.authenticationService.getOtpForForget(this.credential, this.translate.currentLang).subscribe(s => {

      this.ngxService.stop()
      if (s.responseStatus == 'true') {
        this.counter = 30; // 3 min 3*60
        this.countDown = timer(0, this.tick).subscribe(() => {
          if (this.counter > 0) {
            --this.counter;
          }
        })
        this.getStartupCaptcha();
        this.usernameOption = false;
        this.otpOption = true;
        this.passwordOption = false;
        this.alertmessage.successAlertMessage(s);
        //Swal.fire("Success", s.responseMessage, "success");
        //this.router.navigate(['login']);

      }
      else {
        this.usernameOption = true;
        this.otpOption = false;
        this.passwordOption = false;
        this.alertmessage.errorAlertMessage(s);
        //Swal.fire("Error", s.responseMessage, "error");
      }
    },
      err => {

        this.usernameOption = true;
        this.otpOption = false;
        this.passwordOption = false;
        this.ngxService.stop()
        this.alertmessage.errorAlertMessage('tech_error_msg');
        //Swal.fire("Error", 'tech_error_msg', "error");
      })
  }

  onOtpForPassword() {
    this.ngxService.start()
    this.authenticationService.verifyOtpForForget(this.userName, this.credential.userId, this.otp, this.captchaId, this.captchaStr, this.credential.status).pipe().subscribe(s => {

      this.ngxService.stop()
      if (s.responseStatus == 'true') {
        this.usernameOption = false;
        this.otpOption = false;
        this.passwordOption = true;
        this.alertmessage.successAlertMessage(s);
        //Swal.fire("Success", s.responseMessage, "success");
        //this.router.navigate(['login']);

      }
      else {
        this.usernameOption = false;
        this.otpOption = true;
        this.passwordOption = false;
        this.alertmessage.errorAlertMessage(s);
        //Swal.fire("Error", s.responseMessage, "error");
      }
    },
      err => {

        this.usernameOption = false;
        this.otpOption = true;
        this.passwordOption = false;
        this.ngxService.stop()
        this.alertmessage.errorAlertMessage('tech_error_msg');
        //Swal.fire("Error", 'tech_error_msg', "error");
      })
  }

  getStartupCaptcha() {

    this.ngxService.start();
    this.authenticationService.getLoginCaptcha().subscribe(result => {
      this.ngxService.stop();
      this.captchaHandler = result;
      this.captchaImageBase64Img = "data:image/png;base64," + this.captchaHandler.responseData?.captchaBase64;
      this.captchaId = this.captchaHandler.responseData?.id || "";
      //this.captchaId = this.captchaHandler.responseData?.id;
      //
    },
      err => {
        this.ngxService.stop()
        this.alertmessage.errorAlertMessage("TECHNICAL ERROR PLEASE TRY AFTER SOMETIME !");
      }
    );
  }

  onPasswordForSubmit() {
    if (this.password == undefined || this.password == null || this.password == ''
      || this.confPassword == undefined || this.confPassword == null || this.confPassword == '') {
      this.alertmessage.errorAlertMessage('invalid-password');
      return;
    }
    this.ngxService.start();
    //let formData = new FormData();

    //formData.append("userName", this.userName);
    //formData.append("userType", 'citizen');
    //formData.append("newPassword",this._AESEncryptDecryptService.encrypt(this.password));
    //formData.append("confirmPassword",this._AESEncryptDecryptService.encrypt(this.confPassword));
    //formData.append("otp",this.otp);
    //formData.append("captchaId",this.captchaId);
    //formData.append("captchaStr",this.captchaStr);
    this.requestObject.userId = this.selectedUser+'';
    this.requestObject.userName = this.userName;
    this.requestObject.userType = 'citizen';
    this.requestObject.newPassword = this.password;
    this.requestObject.confirmPassword = this.confPassword;
    this.requestObject.otp = this.otp;
    this.requestObject.captchaId = this.captchaId;
    this.requestObject.captchaStr = this.captchaStr;
    //this.otp, this.captchaId, this.captchaStr
    // this.authenticationService.submitForgetPassword(this.userName, this.password, this.confPassword).pipe().subscribe(s => {
    //
    this.commonService.resetPasswordForUser(this.requestObject).subscribe(s => {
      if (s.responseStatus == 'true') {
        this.alertmessage.successAlertMessage(s);
        setTimeout(() => {
          this.usernameOption = true;
          this.otpOption = false;
          this.passwordOption = false;
          this.ngxService.stop()
          this.router.navigate(['clogin']);
        }, 5000)

      }
      else {
        this.usernameOption = false;
        this.otpOption = false;
        this.passwordOption = true;
        this.alertmessage.errorAlertMessage(s);
        this.ngxService.stop()
      }
    },
      err => {

        this.usernameOption = false;
        this.otpOption = false;
        this.passwordOption = true;
        this.ngxService.stop()
        this.alertmessage.errorAlertMessage('tech_error_msg');
        //Swal.fire("Error", 'tech_error_msg', "error");
      })

  }

  changeLan(ev: any) {
    //

    if (ev.value == 1) {
      this.lan = 0;
      sessionStorage.setItem("selectedLanguage", 'en');
      this.checkedVal = sessionStorage.getItem("selectedLanguage") == 'hi' ? true : false;
      //this.translate.use(sessionStorage.getItem("selectedLanguage") || "en");
      this.translateService.updateApprovalMessage(this.lan);
    } else {
      this.lan = 1;
      sessionStorage.setItem("selectedLanguage", 'hi');
      this.checkedVal = sessionStorage.getItem("selectedLanguage") == 'hi' ? true : false;
   //   this.translate.use(sessionStorage.getItem("selectedLanguage") || "hi");
      this.translateService.updateApprovalMessage(this.lan);
    }
    //window.location.reload();
    this.translate.get("forgot-password-title").subscribe(name => {
      0
      this.titleService.setTitle(name);
    });
  }

  activateUser() {
    this.updateCitizenProfileDetail();
  }

  updateCitizenProfileDetail() {
    this.userProfileModel.id = this.selectedUser;
    this.userProfileModel.userName = this.userName;
    this.userProfileModel.status = this.status;
    this.userService.activateCitizenUserProfile(this.userProfileModel).subscribe(res => {
      this.ngxService.stop();

      if (res.responseStatus == "true") {
        this.alertmessage.successAlertMessage(res);

        setTimeout(() => {
          this.router.navigate(['']);
        }, 2000)
      } else {
        this.alertmessage.errorAlertMessage(res);
      }
    })
  }

}
