import { IgrsAllDutyDto } from "./igrs-all-duty-dto";
import { IgrsDutyConsenterTxnDto } from "./igrs-duty-consenter-txn-dto";
import { IgrsMovablePropDto } from "./igrs-movable-prop-dto";

export class DutyCalculationResponse {

	calculatedPSD : number;
	calculatedMunicipalDuty: number;
	calculatedJanpadDuty: number;
	calculatedUpkarDuty: number;
	calculatedRegFee: number;
	
	totalStampDuty: number;	
	totalRegFee: number;

    stampDutyExemption: number;
	regFeeExemption: number;

    totalPayableStampDuty: number;
	totalPayableRegFee: number;
    totalPayableStampAndReg: number;

	igrsAllDutyDtoList:IgrsAllDutyDto[];
	igrsMovablePropDtoList:IgrsMovablePropDto[];

	igrsDutyConsenterTxnDtoListInsert:IgrsDutyConsenterTxnDto[];

	calculatedMvPSD :number;
	calculatedMvRegFee:number;

    totalConsenterPSD:number;
    totalConsenterRegFee:number;
}

export class DutyDetailsBean {
    consAmount:number;
    glValue:number;
    upkarDuty:number;
    principalStampDuty: number;
    muncipalDuty: number;
    janpadDuty:number;
    excempStamp: number;
    totalPayableStamp:number;
    regFees:number;
    excempRegFees: number;
    totalPayableRegFees:number;
    totalAmount:number;
}

export class IgrsDutyCalculationTxnDto {
    dutyId:number;
    upkarDuty:number;
    principalStampDuty: number;
    muncipalDuty: number;
    janpadDuty:number;
    registrationFee:number;

    totalStamp: number;
    totalReg:number;

    totalPayableStamp:number;
    totalPayableRegfee:number;

    totalPayable: number;
    exempStamp: number;
    exempReg: number;
    mvPSD: number;
    mvRegFee: number;
}

