import { OwmPrinterComponent } from 'projects/common-lib/src/lib/component/owm-printer/owm-printer.component';
import { DefaultComponent } from './default/default.component';
import { AboutComponent } from './modules/about/about.component';
import { CitizenForgotPasswordComponent } from './modules/citizen-forgot-password/citizen-forgot-password.component';

import { CitizenRegistrationComponent } from './modules/citizen-registration/citizen-registration.component';
import { ComplaintStatusComponent } from './modules/complaint-status/complaint-status/complaint-status.component';
import { EstampContentUpdationComponent } from './modules/deed-content-updation/estamp-content-updation/estamp-content-updation.component';
import { EstampLoginComponent } from './modules/deed-content-updation/estamp-login/estamp-login.component';
import { EstampUpdationListComponent } from './modules/deed-content-updation/estamp-updation-list/estamp-updation-list.component';
import { DemographicDetailsComponent } from './modules/demographic-details/demographic-details.component';
import { DutyCalculationComponent } from './modules/duty-calculation/duty-calculation.component';
import { InstrumentFieldMappingFormComponent } from './modules/duty-calculation/instrument-field-mapping-form/instrument-field-mapping-form.component';
import { ForgotPasswordComponent } from './modules/forgot-password/forgot-password.component';
import { GlDraftSuggestionComponent } from './modules/gl-draft-suggestion/gl-draft-suggestion.component';
import { HomeComponent } from './modules/home/home.component';
import { KycDetailsComponent } from './modules/kyc-details/kyc-details.component';
import { LegalPersonRegistrationComponent } from './modules/legal-person-registration/legal-person-registration.component';
import { LivingPersonRegistrationComponent } from './modules/living-person-registration/living-person-registration.component';
import { LoginKycComponent } from './modules/login-kyc/login-kyc.component';

import { MobileVerifyComponent } from './modules/mobile-verify/mobile-verify.component';
import { PropertyValuationComponent } from './modules/property-valuation/property-valuation.component';
import { SelectofficeComponent } from './modules/selectoffice/selectoffice.component';

import { QuickRegistrationComponent } from './modules/quick-registration/quick-registration.component';

import { QrCodeViewComponent } from './modules/qr-code-view/qr-code-view.component';
import { QuickRegDemographicDetailsComponent } from './modules/quick-reg-demographic-details/quick-reg-demographic-details.component';
import { PrintGuidelineComponent } from './modules/print-guideline/print-guideline.component';
import { UnregistuserTicketComponent } from './modules/unregistuser-ticket/unregistuser-ticket.component';
import { UnregisTicketViewComponent } from './modules/unregistuser-ticket/unregis-ticket-view/unregis-ticket-view.component';
import { UnregisTicketCreationComponent } from './modules/unregistuser-ticket/unregis-ticket-creation/unregis-ticket-creation.component';
import { SrOfficeDisplayComponent } from './modules/sr-office-display/sr-office-display.component';
import { FingerprintScannerComponent } from './modules/fingerprint-scanner/fingerprint-scanner.component';
import { QrcodeViewEregiComponent } from './modules/qrcode-view-eregi/qrcode-view-eregi.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ComplaintRequestComponent } from './modules/complaint-request/complaint-request.component';
import { SaleAssignmentComponent } from './modules/duty-calculation/sale-assignment/sale-assignment.component';


import { DeedTemplateComponent } from 'projects/common-lib/src/lib/page/deed-template/deed-template.component';
import { EsignComponent } from 'projects/common-lib/src/lib/page/esign/esign.component';
import { EregDeedTemplateComponent } from 'projects/common-lib/src/lib/page/ereg-deed-template/ereg-deed-template.component';

import { SoftwDownloadComponent } from './modules/softw-download/softw-download.component';
 import { UserManualComponent } from './modules/user-manual/user-manual.component';
import { PrivacyPolicyComponent } from './modules/privacy-policy/privacy-policy.component';
import { EncryptEregidComponent } from './modules/encrypt-eregid/encrypt-eregid.component';
import { EregDetailsComponent } from './modules/details_pages/ereg-details/ereg-details.component';
import { EstampDetailsComponent } from './modules/details_pages/estamp-details/estamp-details.component';
import { WalletDetailsComponent } from './modules/details_pages/wallet-details/wallet-details.component';
import { ServiceproviderLicenseDetailsComponent } from './modules/details_pages/serviceprovider-license-details/serviceprovider-license-details.component';
import { DocumentSearchDetailsComponent } from './modules/details_pages/document-search-details/document-search-details.component';
import { PropertyValuationDetailsComponent } from './modules/details_pages/property-valuation-details/property-valuation-details.component';
import { VeiwGuidelineDetailsComponent } from './modules/details_pages/veiw-guideline-details/veiw-guideline-details.component';
import { DutyCalculationDetailsComponent } from './modules/details_pages/duty-calculation-details/duty-calculation-details.component';
import { NonInteractiveVideokycComponent } from './modules/details_pages/non-interactive-videokyc/non-interactive-videokyc.component';
import { InteractiveVideokycComponent } from './modules/details_pages/interactive-videokyc/interactive-videokyc.component';
import { SlotAvailabilityRescheduleComponent } from './modules/details_pages/slot-availability-reschedule/slot-availability-reschedule.component';
import { RaiseSupportTicketComponent } from './modules/details_pages/raise-support-ticket/raise-support-ticket.component';
import { SrSpLocationsComponent } from './modules/details_pages/sr-sp-locations/sr-sp-locations.component';
import { DeedAcceptanceDetailsComponent } from './modules/details_pages/deed-acceptance-details/deed-acceptance-details.component';
import { SrOfficeDisplayNewComponent } from './modules/sr-office-display-new/sr-office-display-new.component';
 


const routes: Routes = [{
  path: '',
  component: DefaultComponent,
  children: [{
    path: '',
    component: HomeComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'property-valuation',
    component: PropertyValuationComponent
  },
  
  {
    path: 'property-valuation2',
    component: PropertyValuationComponent
  },

  {
    path: 'gmap-pv',
    component: PropertyValuationComponent
  },



 
  {
    path: 'registration',
    component: CitizenRegistrationComponent
  },
  {
    path: 'citizen/forgot-password',
    component: CitizenForgotPasswordComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'mobile-verify',
    component: MobileVerifyComponent,
  },
  {
    path: 'login-kyc',
    component: LoginKycComponent,
  },

  {
    path: 'select-office',
    component: SelectofficeComponent
  },
  {
    path: 'living-person',
    component: LivingPersonRegistrationComponent
  },
  {
    path: 'kyc-details',
    component: KycDetailsComponent
  },
  {
    path: 'demographic',
    component: DemographicDetailsComponent
  },
  {
    path: 'legal-person',
    component: LegalPersonRegistrationComponent
  },
  {
    path: 'complaint-request',
    component: ComplaintRequestComponent
  },
  {
    path: 'complaint-status',
    component: ComplaintStatusComponent
  },
  {
    path: 'duty-calculation',
    component: DutyCalculationComponent
  },
  {
    path: 'Sales-Purchase',
    component: SaleAssignmentComponent
  },
 
  {
    path: 'estamp-Login',
    component: EstampLoginComponent, data: { breadcrumb: 'Next' }
  },

  {
    path: 'estamp-Login/:estampId',
    component: EstampLoginComponent, data: { breadcrumb: 'Next' }
  },

  {
    path: 'estamp-Login/:status/:msg',
    component: EstampLoginComponent, data: { breadcrumb: 'Next' }
  },

  {
    path: 'estamp-Login/:estampId/:MobileNo/:eregFlag',
    component: EstampLoginComponent, data: { breadcrumb: 'Next' },
    pathMatch:'full'
  },

  {
    path: 'estamp-Login/:estampId/:MobileNo/:eregFlag/:linkDirectionFlag',
    component: EstampLoginComponent, data: { breadcrumb: 'Next' },
    pathMatch:'full'
  },

  {
    path: 'estamp-Login/:estampId/:MobileNo/:userId/:eregFlag/:linkDirectionFlag',
    component: EstampLoginComponent, data: { breadcrumb: 'Next' },
    pathMatch:'full'
  },



  {
    path: 'estamp-list',
    component: EstampUpdationListComponent, data: { breadcrumb: 'Next' }
  },
  {
    path: 'estamp-deed-updation',
    component: EstampContentUpdationComponent, data: { breadcrumb: 'Next' }
  },

  {
    path: 'instrument-field-mapp',
    component: InstrumentFieldMappingFormComponent, data: { breadcrumb: 'Next' }
  },
  {
    path: 'instrument-field-mapp',
    component: InstrumentFieldMappingFormComponent, data: { breadcrumb: 'Next' }
  },
  {
    path: 'citizenSuggestion',
    component: GlDraftSuggestionComponent

  },
  {
    path: 'instrument-field-mapp',
    component: InstrumentFieldMappingFormComponent, data: { breadcrumb: 'Next' }
  },
  {
    path: 'owm-printer',
    component: OwmPrinterComponent, data: { breadcrumb: 'Next' }
  },


  {
    path: 'estamp-deed-templete',
    component: DeedTemplateComponent, data: { breadcrumb: 'Next' }
  },

  {
    path: 'e-sign',
    component: EsignComponent, data: { breadcrumb: 'Next' }
  },
  {
    path: 'EregEsignTemplate',
    component: EregDeedTemplateComponent, data: { breadcrumb: 'Next' }
  },
  {
    path: 'qrCodeView/:estampId',
    component: QrCodeViewComponent
  },
  {
    path: 'quick-registration',
    component: QuickRegistrationComponent
  },
  {
    path: 'quick-reg-demographic',
    component: QuickRegDemographicDetailsComponent
  },

  {
    path: 'printGuideline/:disttrict',
    component: PrintGuidelineComponent
  },
  {
    path: "raiseTicket",
    component: UnregistuserTicketComponent
  },
  {
    path: "raiseTicketView",
    component: UnregisTicketViewComponent
  },
  {
    path: "unregisTicketCreation",
    component: UnregisTicketCreationComponent
  },
  {
    path: 'SROfficeDisplay',
    component: SrOfficeDisplayComponent
  },
 
  {
    path: 'encryptEregId/:eregNo',
    component: EncryptEregidComponent
  },
  {
    path: 'FingerprintScanner',
    component: FingerprintScannerComponent
  },
  {
    path: "QrcodeViewEregi/:eregId", component: QrcodeViewEregiComponent
  },
 
  {  path: 'Software_Download',
  component:SoftwDownloadComponent
 },
 
  {  path: 'userManual',
  component:UserManualComponent
 },
  {  path: 'privacyPolicy',
  component:PrivacyPolicyComponent
 },
  {  path: 'Ereg_Details',
    component:EregDetailsComponent
  },
  {  path: 'Estamp_Details',
    component:EstampDetailsComponent
  },
  {  path: 'SAMPADAWalletDetails',
    component:WalletDetailsComponent
  },
  {  path: 'ServiceProviderLicenseDetails',
    component:ServiceproviderLicenseDetailsComponent
  },
  {  path: 'DocumentSearchDetails',
    component:DocumentSearchDetailsComponent
  },
  {  path: 'PropertyValuationDetails',
    component:PropertyValuationDetailsComponent
  },
   {  path: 'VeiwGuidelineDetails',
    component:VeiwGuidelineDetailsComponent
  },
   {  path: 'DutyCalculationDetails',
    component:DutyCalculationDetailsComponent
  },
   {  path: 'NonInteractiveVideoKYCDetails',
    component:NonInteractiveVideokycComponent
  },
   {  path: 'InteractiveVideoKYCDetails',
    component:InteractiveVideokycComponent
  },
   {  path: 'SlotAvailabilityRescheduleDetails',
    component:SlotAvailabilityRescheduleComponent
  },
   {  path: 'RaiseSupportTicketDetails',
    component:RaiseSupportTicketComponent
  },
   {  path: 'SrSpLocationsComponent',
    component:SrSpLocationsComponent
  },
   {  path: 'DeedAcceptanceDetails',
    component:DeedAcceptanceDetailsComponent
  },
 
 {
    path: 'SROfficeDisplayMobile',
    component: SrOfficeDisplayNewComponent
  },
  ],

},






];

@NgModule({
  imports: [RouterModule.forRoot(routes )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
