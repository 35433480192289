export class EstampPartyElement {
  sNo: number;
  partyType: number;
  userType: number;
  applicantType: string;
  cinPanGstin: string;
  mobileNo: string;
  partyName: string;
  emailId: string;
  estampId: number;
  id: number;
  ekycStatus: string;

  constructor(partyType: number,
    userType: number,
    applicantType: string,
    cinGstin: string,
    mobileNo: string,
    partyName: string,
    emailId: string,
    estampid: number,
    id: number,
    ekycStatus: string) {
    this.partyType = partyType;
    this.userType = userType;
    this.applicantType = applicantType;
    this.cinPanGstin = cinGstin;
    this.mobileNo = mobileNo;
    this.partyName = partyName;
    this.emailId = emailId;
    this.estampId = estampid;
    this.id = id;
    this.ekycStatus = ekycStatus

  }
}


export class EstampDetails {

  id: number;
  estampId: number;
  categoryId: number;
  deedCategoryEn: string;
  deedCategoryHi: string;
  deedTypeId: number;
  deedTypeEn: string;
  deedTypeHi: string;
  instrumentId: number;
  instrumentNameEn: string;
  instrumentNameHi: string;
  transactionBehalf: string;
  amount: number;
  initialContent: string;
  deedTemplateId: number;
  createdBy: number;
  createdDate: string;
  modifiedBy: number;
  modifiedDate: string;
  finalContent: string;
  contentStatus: number;
  estampStatus: number;
  totalDuty: number;
  upkarDuty: number;
  principalStampDuty: number
  janpadDuty: number
  municipalDuty: number;
  dutyExemption: number;
  natureOfInstrument: string
  dmsDocId: string;
  estampCode: string;
  dutyId: number;
  mstEstampTypeBean: MstEstampTypeBean;
  qrCode: string;
  ipAddress: string;
  deedRemarks: string;
  finalEstampId: number;
  finalSignFileName: string;
  paymentMode: string;
  editFlag: number;
  editFlagTime: Date;
  eregFlag: number;
  eregId: number;
  month: string;
  year: string;
  districtId:any;
}


export class MstEstampTypeBean {

  id: number;
  estampNameEn: string;;
  estampNameHi: string;
  estampDiscriptionEn: string;;
  estampDiscriptionHi: string;;

}
