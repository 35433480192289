
<lib-alertmessage #alertMessage></lib-alertmessage>
<div class="input-group search-input-hroup">
    <div class="" *ngIf="showOption">
        <select class="form-control searchSelection" type="number" [(ngModel)]="selectedSearchoption" (change)="onSearchSelectionChange()">
            <option *ngFor="let level of searchOption" [ngValue]="level.value">{{level.name_en}}</option>
          </select>
    </div>
    <input type="text" class="form-control inputAdd" placeholder="Search..." id="inputAdd" name="inputAdd" #inputAdd  autocomplete="off" (input)="keyupAction(false)">


    <button type="button" class="btn bg-transparent searchClose" style="z-index: 100;" (click)="keyupAction(true)">
        <i class="fa fa-search"></i>
    </button>
    <div class="vl" id="vOne" style="display: block;" *ngIf="showcrosButton"></div>
    <button type="button" class="btn bg-transparent" style="z-index: 100;" *ngIf="showcrosButton" (click)="clearInputBox()">
        <i class="fa fa-times"></i>
    </button>
    <div #suggetionDiv class="suggetionDiv">
        <ng-container *ngIf="islistShow">
        <ul #suggetionList class="suggetionList" >
            <!-- <li class='suggetionListing'  *ngFor="let item of suggestionDatan; let i = index" (click)="placeaddress(item)" title="{{item.placeAddress}}">
                <i class="fa fa-map-marker"></i> &nbsp;  <b>{{item.placeName}}</b>
            </li> -->
        </ul>
    </ng-container>
    </div>
</div>