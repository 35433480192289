import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ForgotPasswordComponent } from '../modules/forgot-password/forgot-password.component';
import { CitizenForgotPasswordComponent } from '../modules/citizen-forgot-password/citizen-forgot-password.component';
import { SelectofficeComponent } from '../modules/selectoffice/selectoffice.component';
import { CitizenRegistrationComponent } from '../modules/citizen-registration/citizen-registration.component';
import { LivingPersonRegistrationComponent } from '../modules/living-person-registration/living-person-registration.component';
import { KycDetailsComponent } from '../modules/kyc-details/kyc-details.component';
import { DemographicDetailsComponent } from '../modules/demographic-details/demographic-details.component';
import { LegalPersonRegistrationComponent } from '../modules/legal-person-registration/legal-person-registration.component';
import { ComplaintRequestComponent } from '../modules/complaint-request/complaint-request.component';
import { ComplaintStatusComponent } from '../modules/complaint-status/complaint-status/complaint-status.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CommonLibModule, httpTranslateLoader } from 'projects/common-lib/src/projects';
import { LoginKycComponent } from '../modules/login-kyc/login-kyc.component';
import { PropertyValuationComponent } from '../modules/property-valuation/property-valuation.component';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { DutyCalculationComponent } from '../modules/duty-calculation/duty-calculation.component';
import { InstrumentSearchComponent } from '../modules/duty-calculation/instrument-search/instrument-search.component';
import { InstrumentFilterComponent } from '../modules/duty-calculation/instrument-filter/instrument-filter.component';
import { CalculateDutyComponent } from '../modules/duty-calculation/calculate-duty/calculate-duty.component';
import { SaleAssignmentComponent } from '../modules/duty-calculation/sale-assignment/sale-assignment.component';
import { GiftDeedComponent } from '../modules/duty-calculation/gift-deed/gift-deed.component';
import { LeaseDeedComponent } from '../modules/duty-calculation/lease-deed/lease-deed.component';
import { MortgageComponent } from '../modules/duty-calculation/mortgage/mortgage.component';
import { WillDeedComponent } from '../modules/duty-calculation/will-deed/will-deed.component';
import { CalculatorComponent } from '../modules/duty-calculation/calculator/calculator.component';
import { PlotValuationComponent } from '../modules/property-valuation/plot-valuation/plot-valuation.component';
import { BuildingValuationComponent } from '../modules/property-valuation/building-valuation/building-valuation.component';
import { AgriValuationComponent } from '../modules/property-valuation/agri-valuation/agri-valuation.component';
import { AveragecalculateComponent } from '../modules/duty-calculation/averagecalculate/averagecalculate.component';
import { CalculateGuidelineValueComponent } from '../modules/duty-calculation/calculate-guideline-value/calculate-guideline-value.component';
import { CalculateOtherComponent } from '../modules/duty-calculation/calculate-other/calculate-other.component';
import { MineralSelectionComponent } from '../modules/duty-calculation/mineral-selection/mineral-selection.component';
import { DeedContentUpdationComponent } from '../modules/deed-content-updation/deed-content-updation.component';
import { EstampLoginComponent } from '../modules/deed-content-updation/estamp-login/estamp-login.component';
import { EstampContentUpdationComponent } from '../modules/deed-content-updation/estamp-content-updation/estamp-content-updation.component';
import { DetailViewComponent } from '../modules/duty-calculation/detail-view/detail-view.component';
import { InstrumentFieldMappingFormComponent } from '../modules/duty-calculation/instrument-field-mapping-form/instrument-field-mapping-form.component';
import { ActionComponentComponent } from '../modules/action-component/action-component.component';
import { OrderModule } from 'ngx-order-pipe';
import { GlDraftSuggestionComponent } from '../modules/gl-draft-suggestion/gl-draft-suggestion.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ShowMoreComponent } from '../modules/duty-calculation/show-more/show-more.component';
import { PropertySubclauseMasterComponent } from '../modules/property-subclause-master/property-subclause-master.component';
import { ExemptionMasterComponent } from '../modules/duty-calculation/exemption-master/exemption-master.component';
import { QuickRegistrationComponent } from '../modules/quick-registration/quick-registration.component';
import { QuickRegDemographicDetailsComponent } from '../modules/quick-reg-demographic-details/quick-reg-demographic-details.component';
import { PrintGuidelineComponent } from '../modules/print-guideline/print-guideline.component';
import { UnregistuserTicketComponent } from '../modules/unregistuser-ticket/unregistuser-ticket.component';
import { UnregisTicketCreationComponent } from '../modules/unregistuser-ticket/unregis-ticket-creation/unregis-ticket-creation.component';
import { UnregisTicketViewComponent } from '../modules/unregistuser-ticket/unregis-ticket-view/unregis-ticket-view.component';
import { MobileVerifyComponent } from '../modules/mobile-verify/mobile-verify.component';
import { EstampUpdationListComponent } from '../modules/deed-content-updation/estamp-updation-list/estamp-updation-list.component';
import { MaterialModule } from 'projects/common-lib/material/material.module';
import { SoftwDownloadComponent } from '../modules/softw-download/softw-download.component';
import { CitizenDemographyHierarchyLoadComponent } from '../modules/citizen-demography-hierarchy-load/citizen-demography-hierarchy-load.component';
import { MannualProcessPropertyIdentifationComponent } from '../modules/mannual-process-property-identifation/mannual-process-property-identifation.component';
import { MannualBuildingComponent } from '../modules/mannual-process-property-identifation/enagarpalika/mannual.building.component';
import { MannualPlotComponent } from '../modules/mannual-process-property-identifation/pnrd/mannual.plot.component';
import { HttpClient } from '@angular/common/http';
//  import { UserManualComponent } from '../modules/user-manual/user-manual.component';
import { PrivacyPolicyComponent } from '../modules/privacy-policy/privacy-policy.component';
import { EncryptEregidComponent } from '../modules/encrypt-eregid/encrypt-eregid.component';
import { DocumentSearchDetailsComponent } from '../modules/details_pages/document-search-details/document-search-details.component';
import { DutyCalculationDetailsComponent } from '../modules/details_pages/duty-calculation-details/duty-calculation-details.component';
import { EregDetailsComponent } from '../modules/details_pages/ereg-details/ereg-details.component';
import { EstampDetailsComponent } from '../modules/details_pages/estamp-details/estamp-details.component';
import { InteractiveVideokycComponent } from '../modules/details_pages/interactive-videokyc/interactive-videokyc.component';
import { NonInteractiveVideokycComponent } from '../modules/details_pages/non-interactive-videokyc/non-interactive-videokyc.component';
import { PropertyValuationDetailsComponent } from '../modules/details_pages/property-valuation-details/property-valuation-details.component';
import { RaiseSupportTicketComponent } from '../modules/details_pages/raise-support-ticket/raise-support-ticket.component';
import { ServiceproviderLicenseDetailsComponent } from '../modules/details_pages/serviceprovider-license-details/serviceprovider-license-details.component';
import { SlotAvailabilityRescheduleComponent } from '../modules/details_pages/slot-availability-reschedule/slot-availability-reschedule.component';
import { SrSpLocationsComponent } from '../modules/details_pages/sr-sp-locations/sr-sp-locations.component';
import { VeiwGuidelineDetailsComponent } from '../modules/details_pages/veiw-guideline-details/veiw-guideline-details.component';
import { WalletDetailsComponent } from '../modules/details_pages/wallet-details/wallet-details.component';
 import { UserManualComponent } from '../modules/user-manual/user-manual.component';
import { DeedAcceptanceDetailsComponent } from '../modules/details_pages/deed-acceptance-details/deed-acceptance-details.component';
import { SrOfficeDisplayNewComponent } from '../modules/sr-office-display-new/sr-office-display-new.component';
import { ParentInstrumentSearchComponent } from '../modules/duty-calculation/parent-instrument-search/parent-instrument-search.component';



@NgModule({
  declarations: [
    ForgotPasswordComponent,
    CitizenForgotPasswordComponent,
    SelectofficeComponent,
    CitizenRegistrationComponent,
    LivingPersonRegistrationComponent,
    KycDetailsComponent,
    DemographicDetailsComponent,
    LegalPersonRegistrationComponent,
    ComplaintRequestComponent,
    ComplaintStatusComponent,
    MobileVerifyComponent,
    LoginKycComponent,
    PropertyValuationComponent,
    PlotValuationComponent,
    BuildingValuationComponent,
    AgriValuationComponent,
    DetailViewComponent,
    DutyCalculationComponent,
    InstrumentSearchComponent,
    InstrumentFilterComponent,
    CalculateDutyComponent,
    SaleAssignmentComponent,
    MortgageComponent,
    WillDeedComponent,
    LeaseDeedComponent,
    GiftDeedComponent,
    CalculatorComponent,
    AveragecalculateComponent,
    CalculateGuidelineValueComponent,
    DetailViewComponent,
    CalculateOtherComponent,
    MineralSelectionComponent,
    DeedContentUpdationComponent,
    EstampLoginComponent,
    EstampUpdationListComponent,
    EstampContentUpdationComponent,
    InstrumentFieldMappingFormComponent,
    ActionComponentComponent,
    GlDraftSuggestionComponent,
    ShowMoreComponent,
   // SpinnerViewComponent,
    ExemptionMasterComponent,
    PropertySubclauseMasterComponent,
    QuickRegistrationComponent,
    QuickRegDemographicDetailsComponent,
    PrintGuidelineComponent,
    UnregistuserTicketComponent,
    UnregisTicketCreationComponent,
    UnregisTicketViewComponent,
    SoftwDownloadComponent,
    CitizenDemographyHierarchyLoadComponent,
    MannualProcessPropertyIdentifationComponent,
   MannualPlotComponent,
   MannualBuildingComponent,
  UserManualComponent,
    PrivacyPolicyComponent,
    EncryptEregidComponent,
    DocumentSearchDetailsComponent,
    EregDetailsComponent,
    SrSpLocationsComponent,
    EstampDetailsComponent,
    PropertyValuationDetailsComponent,
    ServiceproviderLicenseDetailsComponent,
    VeiwGuidelineDetailsComponent,
    WalletDetailsComponent,
    DutyCalculationDetailsComponent,
    InteractiveVideokycComponent,
    NonInteractiveVideokycComponent,
    SlotAvailabilityRescheduleComponent,
    RaiseSupportTicketComponent,
    DeedAcceptanceDetailsComponent,
    SrOfficeDisplayNewComponent,
    ParentInstrumentSearchComponent
    
 
  ],
  imports: [
    OrderModule,
    CommonModule,
    RouterModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxUiLoaderModule,
    CommonLibModule,
    ContentLoaderModule,
    NgxSkeletonLoaderModule,
    MaterialModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      },
      defaultLanguage: 'hi'
    })
    
  ],
  exports:[ExemptionMasterComponent,
     LivingPersonRegistrationComponent,
     InstrumentSearchComponent,
      DutyCalculationComponent,
     KycDetailsComponent, 
     PropertySubclauseMasterComponent,
  ActionComponentComponent,
  UnregistuserTicketComponent,PropertyValuationComponent,
  PlotValuationComponent,
  BuildingValuationComponent,
  AgriValuationComponent,
  UserManualComponent, CitizenDemographyHierarchyLoadComponent]

})
export class AuthLayoutModule {

}
