import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppSetting, AppSettingService } from 'projects/common-lib/src/lib/helper-lib/app.setting';

@Injectable({
  providedIn: 'root'
})
export class ChatbotService {
  private settings!: AppSetting;
  private apiUrls: { [key: string]: string } = {
    en: 'english/webhooks/rest/webhook',
    hi: 'hindi/webhooks/rest/webhook'
  };

  constructor(private http: HttpClient, private appSettingsService: AppSettingService) {
    this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
        this.settings = settings;
    });
  }

  sendMessage(message: any, language: string): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    const body = {
      sender: 'user',
      message: message.content,
      metadata: { language: language }
    };
    const apiUrl = this.settings.igrs_chatbot_url + this.apiUrls[language];
    return this.http.post<any>(apiUrl, body, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = 'An unknown error occurred!';
    if (error.error instanceof ErrorEvent) {
      // Client-side or network error
      errorMessage = `A client-side error occurred: ${error.error.message}`;
    } else {
      // Backend error
      errorMessage = `A backend error occurred: ${error.status}, ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
