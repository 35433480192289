import {Injectable} from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { catchError } from 'rxjs/internal/operators/catchError';
import { MapMyindiaService } from 'projects/common-lib/src/lib/helper-lib/services/MapMyindia.service';

@Injectable()
export class AddressSearchServices{

    constructor(private http: HttpClient, private mapmyindia:MapMyindiaService) {
       
    }

    
    getPlaceSuggetion<T>(url:string, param:any):Observable<T>{
        let querystring='';
        if(param !== null){
            param.updates.forEach((param:any, i:number)=>{
                //console.log(i, param);
                if(i === 0){
                    querystring='?'+param.param+'='+param.value;
                }else{
                    querystring=querystring+'&'+param.param+'='+param.value;
                }
            })
        }
        //console.log(querystring);
        return this.http.jsonp<T | any>(url+querystring, 'callback').pipe(
            map((data:any) => {
                if (!data) {
                    throw { Message: data, Type: "Failed" };
                }
                return data;
            }),catchError(err=> throwError(this.handleError(err)))
        );
      }

    // getPlaceSuggetion<T>(url:string, param:any):Observable<T>{
    //     let httpHeaders = new HttpHeaders().set('Accept-Language', 'application/json').set('Authorization', 'Bearer '+this.mapmyindia.getToken());
    //     return this.http.get<T | any>(url, {headers:httpHeaders, params:param}).pipe(
    //     map((data:any) => {
    //         if (!data) {
    //             throw { Message: data, Type: "Failed" };
    //         }
    //         return data;
    //     }),catchError(err=> throwError(this.handleError(err)))
    //     );
    // }

      customgetRequest<T>(methodType:any, url:string, param:any, tocken:string):Observable<T>{
        let httpHeaders = new HttpHeaders().set('Accept-Language', 'application/json').set('Authorization', `Bearer ${tocken}`);
        return this.http.request<T | any>(methodType, url,{headers:httpHeaders, params:param}).pipe(
            map((data:any) => {
                if (!data) {
                    throw { Message: data, Type: "Failed" };
                }
                return data;
            }),catchError(err=> throwError(this.handleError(err)))
          );
      }
      

      handleError = (error: HttpErrorResponse) => {
        //console.log('Handle on Component', error);
      };
}