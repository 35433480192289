
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { AlertmessageComponent } from 'projects/common-lib/src/lib/component/alertmessage/alertmessage.component';
import { AESEncryptDecryptService } from 'projects/common-lib/src/lib/helper-lib/services/aesencrypt-decrypt.service';

import Swal from 'sweetalert2';
import { TicketService } from '../../../service/ticket.service';
import { TranslateHEService } from "projects/common-lib/src/lib/helper-lib/services/common/translateHE.service";
import { TranslateService } from "@ngx-translate/core";

export class TicketModelElement {
  ticketNumber : string;
  subject : string;
  description : string;
  priority_name : string;
  category_name : string;
  dueDate : string;
  created_date : string;
  ticketstatus : string;
  admin_name : string;
  edited_name : string;
  assignee_name : string;
  updated_date : string;
  remark : string;
  fileUrl : string;
  remarkList:any[] = new Array();
  ticket_id : string;
  status : string;
  fileData:null;
  fileName:null;
}

@Component({
  selector: 'app-unregis-ticket-view',
  templateUrl: './unregis-ticket-view.component.html',
  styleUrls: ['./unregis-ticket-view.component.scss']
})
export class UnregisTicketViewComponent implements OnInit {
  @ViewChild('catDialogformRef') form: any;
  @ViewChild('alertMessage') alertmessage: AlertmessageComponent;
  ticketNo: any;
  ticketDetailList: TicketModelElement[] = new Array();
  ticketModelElement : TicketModelElement = new TicketModelElement();
 // userProfileModel : CitizenUserProfileDetails=new CitizenUserProfileDetails() ;
  userEmail : string;
  userName : string;
  backUrl :string;
  offSdata : any = null;
  lan: number;
  constructor(private router: Router,private activatedRoute: ActivatedRoute, private httpClient: HttpClient,private ngxService: NgxUiLoaderService, private _AESEncryptDecryptService: AESEncryptDecryptService,private ticketService: TicketService,
    private translateService: TranslateHEService, private translate: TranslateService,) { 
      this.translateService.currentApprovalStageMessage.subscribe(msg => this.lan = msg);
    this.lan = sessionStorage.getItem("selectedLanguage") == 'hi' ? 1 : 0;
    }

  ngOnInit(): void {
     this.ticketNo=sessionStorage.getItem("ticketNo");
    // this.userProfileModel = JSON.parse(sessionStorage.getItem("authData") || "");
    this.userEmail=sessionStorage.getItem('userEmail');
    //this.userName=this.userProfileModel.userName;
    //this.offSdata = sessionStorage.getItem('selectedOffice');
    // if(this.offSdata != null){
    //   this.backUrl = "/admin/ticketCreation"
    //  }else{
    //   this.backUrl = "/citizen/ticketCreation"
    //  }

    this.backUrl = "/unregisTicketCreation"

    // alert("ticketNo::"+ticketNo);
    // this.activatedRoute.params.subscribe(params => {
    //   if (typeof params['ticketNo'] !== 'undefined') {
    //     this.ticketNo = this._AESEncryptDecryptService.decryptInAngular(params['ticketNo']);
    //     //this.ticketNo = params['ticketNo'];
    //   } else {
    //     alert("Something wrong.");
    //     this.ticketNo = 0;
    //   }

    // });

    this.getTicketDetails(this.ticketNo);
  }

  getTicketDetails(ticketNo : any){
    this.ngxService.start();
    const param = {
      "mailID": this.userEmail,
      "ticketNumber": ticketNo,
      "ticketId": ticketNo,
    };
    this.ticketService.getTicketDetails(param).subscribe(res=>{
      var resData =JSON.parse(res.responseData.replace(/\\r\\n/g, ""));
      if(res.responseStatus!="false"){
        this.ticketModelElement = resData.result.TicketsData;
        this.ticketModelElement.ticketstatus = resData.result.TicketsData.status_name;
        //this.ticketModelElement.ticketstatus = resData.result.TicketsStatusList[resData.result.TicketsStatusList.length-1].status_name;
        //this.ticketModelElement.remark = resData.result.TicketsStatusList[resData.result.TicketsStatusList.length-1].remark;
        this.ticketModelElement.remarkList = resData.result.TicketsStatusList;
        if(resData.result.TicketImages.length > 0)
        this.ticketModelElement.fileUrl = resData.result.TicketImages[0].attachment;
        console.log("getTicketDetails ::"+JSON.stringify(resData.result))
        }else{
          console.error('There was an error in getTicketDetails()!', resData.message);
        }
    });
    this.ngxService.stop();
  //   this.ngxService.start();
  //   const headers = { 'ITSUPPORTSECRETKEY': 'e170245abbcfe9b0384704d0851f7f41', 'RNUM': '1671527062', 'APPLICATIONCODE': 'MSDC01' };
  //   const body = { mailID: this.userEmail,TicketNumber: ticketNo };
  //   this.httpClient.post<any>('https://bhabha-coe.mapit.gov.in/helpdesk-demo/api/tickets/gettickets', body, { headers }).subscribe({
  //     next: data => {
  //         this.ticketDetailList = data.result.TicketsData;
  //         this.ticketModelElement = this.ticketDetailList[0];
  //         console.info('getTicketDetails Result:', data);
  //     },
  //     error: error => {
  //         console.error('There was an error in getTicketDetails()!', error);
  //     }
  // })
  // this.ngxService.stop();
  }

  onSubmitClarificationRevert(){
    Swal.fire({
      title: `${this.lan==0?"Are you sure?":"क्या आप सुनिश्चित हैं?"}`,
      // title: 'Are you sure?',
      //text: 'This process is irreversible.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: (this.lan == 0) ? 'Yes':'हाँ ',
      cancelButtonText: (this.lan == 0) ? 'No':'नहीं '
    }).then((result) => {
      if (result.value) {

        var remark = (<HTMLInputElement>document.getElementById("remark")).value;
        this.ngxService.start();
          const param = {
            "mailID": this.userEmail,
            "remark": remark,
            "ticketId":this.ticketModelElement.ticket_id,
           // "userId":this.userProfileModel.id,
           "actionTicketStatus": (this.ticketModelElement.status == '7') ? '5':'3',
           "fileData":this.ticketModelElement.fileData,
           "fileName":this.ticketModelElement.fileName
          };
          this.ticketService.ticketStatusChange(param).subscribe(res=>{
              res = res.responseData.replace(/\\/g, "");
              var data =JSON.parse(res);
            if(res.responseStatus!="false"){
              this.ticketModelElement.remark = "";
              if(this.ticketModelElement.status == '7'){
                this.alertmessage.successAlertMessage("Clarification Reverted Successfully");
              }else{
                this.alertmessage.successAlertMessage("Remark Save Successfully");
              }
                this.getTicketDetails(this.ticketNo);
              }else{
                console.error('There was an error in onSubmitClarificationRevert()!', data.message);
                this.alertmessage.errorAlertMessage(res.responseMessage);
              }
          });
          this.ngxService.stop();

      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  selectedFile : any = null; 
  image: File;
  uploadfileErrorMsg : boolean = true;
  onFileSelected(event : any) {
    this.selectedFile = event.target.files[0];
    // Size Filter Bytes
    const pmax_size = 3000000;
   // const pmax_size = 20000;
    const pallowed_types = ["application/pdf","image/jpeg"];//, "image/jpeg", "image/jpg"
    if (!pallowed_types.includes(event.target.files[0].type, 0)) {
     // this.uploadfileErrorMsg=false;
      this.form.controls[event.target.name].setValue(null);
      Swal.fire({
        title: `${this.lan==0?"UnSupported File Type":"असमर्थित फ़ाइल प्रकार"}`,
        // title: "UnSupported File Type",
        text: `${this.lan==0?"Only files are allowed of type ( pdf/jpg )":"केवल (पीडीएफ/जेपीजी) प्रकार की फ़ाइलों की अनुमति है"}`,
        // text: "Only files are allowed of type ( pdf/jpg )  ",
        icon: "error",
        showCancelButton: false,
        confirmButtonText: (this.lan == 0) ? 'Ok':'ठीक है',
        cancelButtonText: (this.lan == 0) ? 'No, keep it':'नहीं, इसे रखो', 
        allowOutsideClick: false,
      }).then((result) => {
        //return false;
      });
    }else if((event.target.files[0].size > pmax_size)){
     // this.uploadfileErrorMsg=false;
     // this.alertmessage.errorAlertMessage('file-size-error');
      this.form.controls[event.target.name].setValue(null);
      Swal.fire({
        title: `${this.lan==0?"File Size Exceeds Allowed Limit":"फ़ाइल का आकार अनुमत सीमा से अधिक है"}`,
        // title: "File Size Exceeds Allowed Limit",
        text: `${this.lan==0?"Maximum size allowed is " + pmax_size / 1000000 + "mb":"अधिकतम आकार अनुमत है " + pmax_size / 1000000 + "एमबी"}`,
        // text: "Maximum size allowed is " + pmax_size / 1000000 + "mb",
        icon: "error",
        showCancelButton: false,
        confirmButtonText: (this.lan == 0) ? 'Ok':'ठीक है',
        cancelButtonText: (this.lan == 0) ? 'No, keep it':'नहीं, इसे रखो', 
        allowOutsideClick: false,
      }).then((result) => {
       // return false;
      });
    }
    else{
      this.uploadfileErrorMsg=true;
    const preader = new FileReader();
    preader.onload = (pe: any) => {
      const pimage = new Image();
      pimage.src = pe.target.result;
      //this.form.controls[event.target.name].setValue(this.selectedFile.name);
      //this.eregPropertyLinkModel.documentType = fileInput.target.files[0].type.split('/')[1];
      //this.eregPropertyLinkModel.filePath = (pe.target.result).split(',')[1];
      this.ticketModelElement.fileData = (pe.target.result).split(',')[1];
      this.ticketModelElement.fileName = this.selectedFile.name;
    };
    preader.readAsDataURL(event.target.files[0]);
  }
  }

}
