import { Injectable } from '@angular/core';
import { AppSetting, AppSettingService } from 'projects/common-lib/src/lib/helper-lib/app.setting';
import { MstDemographyBean } from '../models/MstDemographyBean';
import { AESEncryptDecryptService } from '../helper-lib/services/aesencrypt-decrypt.service';


@Injectable({
  providedIn: 'root'
})
export class QueryLayerServices {

  private settings!: AppSetting;
  constructor(private appSettingsService: AppSettingService, private _AESEncryptDecryptService: AESEncryptDecryptService) {
    this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
      this.settings = settings;
    });
   
  }

 getBoundryURLs(obj: any) {
    let query: string;
    let url: string = '';
    //let D: any = obj.demographyType;
    switch (obj.demographyTypeId) {
      case 2:
        query = 'div_cd=' + obj.lgdCode;
        //url=this.settings.geoServerWmsUrl+'adminbdy/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=adminbdy%3Ampdivision&CQL_FILTER='+query+'&maxFeatures=50&outputFormat=application%2Fjson';
        url = ""
        break;
      case 4:
        query = 'sr_off_cd='+obj.lgdCode;
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Asro_boundary&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson';
        break;
      case 3:
        query = 'dist_cd=' + obj.lgdCode;
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Ampdistrict&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson';
        break;
      case 7:
        query = "lgd_ulb_cd=" + obj.lgdCode;
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Aulb_igrs&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson';
        break;
      case 8:
        query = "ward_cd=" + obj.lgdCode;
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Award_igrs&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson';
        break;
      case 12:
        query = "teh_cd='" + obj.lgdCode + "'";
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Atehsil_clr_430&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson';
        break;
      case 10:
        query = "vil_cd=" + obj.lgdCode;
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Avillage_igrs&maxFeatures=1&CQL_FILTER=' + query + '&outputFormat=application%2Fjson';
        break;
      case 14:
        query = "col_id='" + obj.colonyId +"' and status=1";
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Adraft_igrs_gis_gl&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson';
        break;
      case 15:
        query = "col_id='" + obj.colonyId +"' and status=1";
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Adraft_igrs_gis_gl&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson';
        break;
    }
    return url;
  }

  getBoundryURLsClipAndMerge(obj:any){
    let query:String;
    let url:string='';
    let D:any=obj.demographyType;
    switch(obj.demographyTypeId){
      case 2:
          url='';
        break;
      case 4:
          query = 'sr_off_cd='+obj.lgdCode;
          url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Asro_boundary&CQL_FILTER='+query+'&maxFeatures=1&outputFormat=application%2Fjson';
        break;
      case 3:
          query='dist_cd='+obj.lgdCode;
          url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Ampdistrict&CQL_FILTER='+query+'&maxFeatures=1&outputFormat=application%2Fjson'
        break;
      case 7:
          query="lgd_ulb_cd="+obj.lgdCode;
          url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Aulb_igrs&CQL_FILTER='+query+'&maxFeatures=1&outputFormat=application%2Fjson'
        break;
      case 8:
        query="ward_cd="+obj.lgdCode+" and status=1";
        url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Adraft_igrs_gis_gl&CQL_FILTER='+query+'&maxFeatures=500&outputFormat=application%2Fjson'
        break;
      case 12:
          query="teh_cd='"+obj.lgdCode+"'";
          url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Atehsil_clr_430&CQL_FILTER='+query+'&maxFeatures=1&outputFormat=application%2Fjson'
        break;
      case 10:
          query="vill_cd="+obj.lgdCode+" and status=1";
          url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Adraft_igrs_gis_gl&CQL_FILTER='+query+'&maxFeatures=500&outputFormat=application%2Fjson'
        break;
      case 14:
          query="col_id='"+obj.colonyId+"'and status=1";
          url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Adraft_igrs_gis_gl&CQL_FILTER='+query+'&maxFeatures=500&outputFormat=application%2Fjson'
        break;
      case 15:
          query="col_id='"+obj.colonyId+"' and status=1";
          url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Adraft_igrs_gis_gl&CQL_FILTER='+query+'&maxFeatures=500&outputFormat=application%2Fjson'
        break;
    }
     return url;
  }

  getBoundryURLsfinal(obj: any) {
    let query: string;
    let url: string = '';
    //let D: any = obj.demographyType;
    switch (obj.demographyTypeId) {
      case 2:
        query = 'div_cd=' + obj.lgdCode;
        //url=this.settings.geoServerWmsUrl+'adminbdy/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=adminbdy%3Ampdivision&CQL_FILTER='+query+'&maxFeatures=50&outputFormat=application%2Fjson';
        url = ""
        break;
      case 4:
        query = 'sr_off_cd='+obj.lgdCode;
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Asro_boundary&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson';
        break;
      case 3:
        query = 'dist_cd=' + obj.lgdCode;
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Ampdistrict&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson';
        break;
      case 7:
        query = "lgd_ulb_cd=" + obj.lgdCode;
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Aulb_igrs&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson';
        break;
      case 8:
        query = "ward_cd=" + obj.lgdCode;
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Award_igrs&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson';
        break;
      case 12:
        query = "teh_cd='" + obj.lgdCode + "'";
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Atehsil_clr_430&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson';
        break;
      case 10:
        query = "vil_cd=" + obj.lgdCode;
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Avillage_igrs&maxFeatures=1&CQL_FILTER=' + query + '&outputFormat=application%2Fjson';
        break;
      case 14:
        query = "col_id='" + obj.colonyId +"'";
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Afinal_igrs_gis_gl&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson';
        break;
      case 15:
        query = "col_id='" + obj.colonyId +"'";
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Afinal_igrs_gis_gl&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson';
        break;
    }
    return url;
  }

  getBoundryURLsEncfinal(obj: any) {
    let query: string;
    let url: string = '';
    //let D: any = obj.demographyType;
    switch (obj.demographyTypeId) {
      case 2:
        query = 'div_cd=' + obj.lgdCode;
        //url=this.settings.geoServerWmsUrl+'adminbdy/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=adminbdy%3Ampdivision&CQL_FILTER='+query+'&maxFeatures=50&outputFormat=application%2Fjson';
        url = ""
        break;
      case 4:
        query = 'sr_off_cd='+obj.lgdCode;
        url = this.settings.geoServerEncUrl + this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Asro_boundary&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson');
        break;
      case 3:
        query = 'dist_cd=' + obj.lgdCode;
        url = this.settings.geoServerEncUrl + this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Ampdistrict&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson');
        break;
      case 7:
        query = "lgd_ulb_cd=" + obj.lgdCode;
        url = this.settings.geoServerEncUrl + this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Aulb_igrs&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson');
        break;
      case 8:
        query = "ward_cd=" + obj.lgdCode;
        url = this.settings.geoServerEncUrl + this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Award_igrs&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson');
        break;
      case 12:
        query = "teh_cd='" + obj.lgdCode + "'";
        url = this.settings.geoServerEncUrl + this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Atehsil_clr_430&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson');
        break;
      case 10:
        query = "vil_cd=" + obj.lgdCode;
        url = this.settings.geoServerEncUrl + this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Avillage_igrs&maxFeatures=1&CQL_FILTER=' + query + '&outputFormat=application%2Fjson');
        break;
      case 14:
        query = "col_id='" + obj.colonyId +"'";
        url = this.settings.geoServerEncUrl + this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Afinal_igrs_gis_gl&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson');
        break;
      case 15:
        query = "col_id='" + obj.colonyId +"'";
        url = this.settings.geoServerEncUrl + this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Afinal_igrs_gis_gl&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson');
        break;
    }
    return url;
  }

  getBoundryURLsEnc(obj: any) {
    let query: string;
    let url: string = '';
    //let D: any = obj.demographyType;
    switch (obj.demographyTypeId) {
      case 2:
        query = 'div_cd=' + obj.lgdCode;
        //url=this.settings.geoServerWmsUrl+'adminbdy/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=adminbdy%3Ampdivision&CQL_FILTER='+query+'&maxFeatures=50&outputFormat=application%2Fjson';
        url = ""
        break;
      case 4:
        query = 'sr_off_cd='+obj.lgdCode;
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Asro_boundary&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson');
        break;
      case 3:
        query = 'dist_cd=' + obj.lgdCode;
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Ampdistrict&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson')
        break;
      case 7:
        query = "lgd_ulb_cd=" + obj.lgdCode;
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Aulb_igrs&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson')
        break;
      case 8:
        query = "ward_cd=" + obj.lgdCode;
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Award_igrs&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson')
        break;
      case 12:
        query = "teh_cd='" + obj.lgdCode + "'";
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Atehsil_clr_430&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson')
        break;
      case 10:
        query = "vil_cd=" + obj.lgdCode;
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Avillage_igrs&maxFeatures=1&CQL_FILTER=' + query + '&outputFormat=application%2Fjson')
        break;
      case 14:
        query = "col_id='" + obj.colonyId +"' and status=1";
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Adraft_igrs_gis_gl&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson')
        break;
      case 15:
        query = "col_id='" + obj.colonyId +"' and status=1";
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Adraft_igrs_gis_gl&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson')
        break;
    }
    return url;
  }

  getFinalGuidelineURL(query:string):string{
    return this.geturlwithQuery(query, "final_igrs_gis_gl");
  }


  geturlwithQuery(query:string, layerName:string){
    return this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3A'+layerName+'&CQL_FILTER=' + query + '&maxFeatures=10&outputFormat=application%2Fjson';
  }

}
