<style>
  .cstmtxt {
    background: aliceblue;
    padding: 0;
    margin-top: -15px;
    margin-bottom: 30px;
    overflow: hidden;
    /* Hide the element content, while height = 0 */
    height: 0;
    opacity: 0;
    /* transition: height 0ms 400ms, opacity 400ms 0ms; */
    transition: all 0.5s ease-in-out;
  }

  .rgisterbtntxt:hover .cstmtxt {
    height: auto;
    opacity: 1;
    padding: 15px;
    /* transition: height 0ms 0ms, opacity 600ms 0ms; */
  }

  .lgnbtnss .loginTitle {
    /* background: #e0f2f1;
    padding: 5px 10px; */
    font-weight: 500;
    font-size: 1.2rem;
  }
</style>

<div class="auth-main">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-4 mb-3">
        <mat-card class="example-card h-100">
          <mat-card-title class="text-primary">{{
            "homepage.User-Registration" | translate
            }}</mat-card-title>
          <mat-card-content class="mat-card-content h-100">
            <!-- <ul style="min-height:auto ;">
                            <li>{{'citizen' | translate}}
                            </li>
                             <li>{{'default-navigation.service-provider' | translate}}
                            </li>  
                             <li>{{'living-point-1' | translate}}
                            </li>
                            <li>{{'living-point-2' | translate}}
                            </li>
                            <li>{{'living-point-3' | translate}}
                            </li>  
                        </ul> -->
            <p *ngIf="lan == 0">
              Register with SAMPADA 2.0 to avail the services related to
              document registration, search, certified copy etc. You can
              register as a citizen and apply for service provider licence.
            </p>
            <p *ngIf="lan == 1">
              दस्तावेज़ पंजीकरण, खोज, प्रमाणित प्रतिलिपि आदि से संबंधित सेवाओं
              का लाभ उठाने के लिए SAMPADA 2.0 के साथ पंजीकरण करें। आप एक नागरिक
              के रूप में पंजीकरण कर सकते हैं और सेवा प्रदाता लाइसेंस के लिए
              आवेदन कर सकते हैं।
            </p>
            <a mat-flat-button="mat-flat-button" routerLink="/living-person" class="btn btn-primary mb-3">
              <!-- <span class="loginoptimg"> <img src="assets/images/living-person.png"
                            height="35"> </span> -->
              <span class="m-0 mr-3">{{ "register" | translate }}</span>
              <i class="fa fa-long-arrow-right"></i>
            </a>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-md-4 mb-3">
        <mat-card class="example-card h-100">
          <mat-card-title class="text-primary">
            {{ "legalRegister" | translate }}
          </mat-card-title>
          <mat-card-content class="mat-card-content h-100">
            <!-- <ul class="two-columns-ul">
                            <li>{{'legal-point-1' | translate}}
                            </li>
                            <li>{{'legal-point-2' | translate}}
                            </li>
                            <li>{{'legal-point-3' | translate}}
                            </li>
                            <li>{{'legal-point-4' | translate}}
                            </li>
                            <li>{{'legal-point-5' | translate}} </li> 
                            <li>{{'legal-point-6' | translate}}
                            </li>
                        </ul> -->
            <p *ngIf="lan == 0">
              Register with SAMPADA 2.0 to avail the services related to
              document registration, search, certified copy etc. You can
              register as a legal entity (Company, Firm, Bank, Society etc.).
            </p>
            <p *ngIf="lan == 1">
              दस्तावेज़ पंजीकरण, खोज, प्रमाणित प्रतिलिपि आदि से संबंधित सेवाओं
              का लाभ उठाने के लिए SAMPADA 2.0 के साथ पंजीकरण करें। आप एक कानूनी
              इकाई (कंपनी, फर्म, बैंक, सोसायटी आदि) के रूप में पंजीकरण कर सकते
              हैं।
            </p>
            <a mat-flat-button="mat-flat-button" class="btn btn-primary mb-3" data-toggle="modal"
              data-target="#required-doc">
              <!-- <span class="loginoptimg"><img src="assets/images/juristic-person.png"
                            height="35"> </span> -->
              <span class="m-0 mr-3">{{ "register" | translate }}</span>
              <i class="fa fa-long-arrow-right"></i>
            </a>
          </mat-card-content>
        </mat-card>
      </div>
      <!-- <div class="col-md-4 mb-3">
        <mat-card class="example-card h-100">
          <mat-card-title class="text-primary">
            {{ "other-department-onboarding-form" | translate }}
          </mat-card-title>
          <mat-card-content class="mat-card-content h-100">
            <ul>
              <p *ngIf="lan == 0">
                Do you want to avail the SAMPADA 2.0 services as a department
                user of your department? please fill out the request form for
                department onboarding.
              </p>

              <p *ngIf="lan == 1">
                क्या आप अपने विभाग के विभागीय उपयोगकर्ता के रूप में SAMPADA 2.0
                सेवाओं का लाभ उठाना चाहते हैं? कृपया विभाग में शामिल होने के लिए
                अनुरोध फ़ॉर्म भरें।
              </p>
            </ul>

            <a
              mat-flat-button="mat-flat-button"
              routerLink="/complaint-request"
              class="btn btn-primary mb-3"
            >
              
              <span class="m-0 mr-3">{{ "onboarding-form" | translate }}</span>
              <i class="fa fa-long-arrow-right"></i>
            </a>
          </mat-card-content>
        </mat-card>
      </div> -->
    </div>

    <!-- <div class="lgnbtnss row justify-content-md-center align-items-center">
        <div class="col-lg-8 col-md-7 col-xs-12 col-sm-12 logInForms bg-white p-4"> <h3
        class="loginTitle text-primary mt-0">{{'citizen-registration-search-doc' |
        translate}}</h3> <div class="rgisterbtntxt" style="flex-direction: column;"> <a
        mat-flat-button routerLink="/quick-reg-demographic" class="btn btn-primary mb-3
        "> <span class="m-0 mr-3">{{'register-living-person' | translate}}</span> <i
        class="fa fa-long-arrow-right"></i> </a> </div> </div> </div> -->
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="required-doc" data-backdrop="static" data-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <h2 *ngIf="lan == 0" class="Consenterdialog-title">
        Required Information for User Registration
      </h2>
      <h2 *ngIf="lan == 1" class="Consenterdialog-title">
        उपयोगकर्ता पंजीकरण के लिए आवश्यक जानकारी
      </h2>

      <div class="modal-body">
        <div *ngIf="lan == 0">
          <fieldset class="legend-section mb-0">
            <legend class="sidebar__widget-title">Legal Entity</legend>
            <h4 class="m-0">
              <b>
                To Register as a public user on the SAMPADA 2.0 portal, you need
                to provide the following details:</b>
            </h4>
            <ul>
              <li>A valid mobile number</li>
              <li>A valid email address</li>
              <li>A valid GSTIN, CIN, or PAN number of the legal entity</li>
              <li>A valid authorized letter issued by the legal entity</li>
              <li>
                One of the following identification documents for KYC (Know Your
                Customer) and address proof: Aadhaar or PAN.Alternatively, there
                is a manual registration process available.
              </li>
            </ul>
          </fieldset>
        </div>
        <div *ngIf="lan == 1">
          <fieldset class="legend-section mb-0">
            <legend class="sidebar__widget-title">
              <b>कानूनी इकाई</b>
            </legend>
            <h4 class="m-0">
              <b>
                संपदा 2.0 पोर्टल पर सार्वजनिक उपयोगकर्ता के रूप में पंजीकरण करने
                के लिए, आपको निम्नलिखित विवरण प्रदान करना होगा:
              </b>
            </h4>
            <ul>
              <li>एक वैध मोबाइल नंबर</li>
              <li>एक वैध ईमेल पता</li>
              <li>कानूनी इकाई का एक वैध जीएसटीआईएन, सीआईएन या पैन नंबर</li>
              <li>कानूनी इकाई द्वारा जारी एक वैध अधिकृत पत्र</li>
              <li>
                केवाईसी (अपने ग्राहक को जानें) और पते के प्रमाण के लिए
                निम्नलिखित पहचान दस्तावेजों में से एक: आधार या पैन। वैकल्पिक रूप
                से, मैन्युअल पंजीकरण प्रक्रिया उपलब्ध है।
              </li>
            </ul>
          </fieldset>
        </div>
      </div>
      <div class="mb-2 text-center">
        <button type="button" class="btn btn-primary" routerLink="/legal-person" data-dismiss="modal">
          Ok
        </button>
      </div>
    </div>
  </div>
</div>