import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Citizen, CitizenForgot, IUserDetailModel, User } from "../../../models";
import jwt_decode from "jwt-decode";
import { ResultModel } from "../../../models/api.result.model/result.model";
import { map } from "rxjs/operators";
import moment from "moment";
import { BehaviorSubject, Observable } from "rxjs";
import { AppSetting, AppSettingService } from "../../app.setting";
import { CustomHttpClient } from "../../httpclient/custom.httpclient";
import { TranslateService } from "@ngx-translate/core";
import { FeatureList } from "../../../models/feature.list.model/featurelist";
import { Router } from "@angular/router";
import { MasterOfficeModel } from "../../../models/api.masterdata.model/office.model";
import { AESEncryptDecryptService } from 'projects/common-lib/src/lib/helper-lib/services/aesencrypt-decrypt.service';
import { Department } from "../../../models/auth.userdetail.model/user.profile.model";
@Injectable({ providedIn: "root" })
export class AuthenticationService {
    private settings!: AppSetting;
    isUserLoggedIn: boolean = false;
    private currentUserSubject: BehaviorSubject<IUserDetailModel>;
    public ud!: IUserDetailModel;
    constructor(private appSettingsService: AppSettingService, private router: Router, private httpClient: HttpClient, private customeHttpClient: CustomHttpClient, private translate: TranslateService,private _AESEncryptDecryptService: AESEncryptDecryptService) {
        this.currentUserSubject = new BehaviorSubject<IUserDetailModel>(null as any);
        this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
            this.settings = settings;
        });
    }
    public get currentUserValue(): IUserDetailModel {
        return this.currentUserSubject.value;
    }

    /*******************LOGIN CAPTCHA FOR ADMIN WEB***************************/

    getLoginCaptcha() {
       // return this.httpClient.get(this.settings.igrs_gateway_url + "common/captcha?type=login-user", { responseType: 'json' });

     return this.customeHttpClient.Get<ResultModel<any>>(
        this.settings.igrs_gateway_url + "common/captcha?type=login-user"
         ).pipe(
             map((result: any) => {
                ;
                 //
                  if (result && result.responseStatus == 'true') {
                     return result;
                } else {
                    return result;
                 }
    
             })
        );


    }
    /*******************LOGIN CODE SECTION ***************************/
    validateDepartmentUser(credential: User, lang: string) {
        let encVal = this._AESEncryptDecryptService.encryptInAngular(JSON.stringify(credential) );
        return this.customeHttpClient
            .Post<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/validate_department_user",
                encVal
            ).pipe(
                map((result: any) => {
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }
       
                })
                // map((result: any) => {
                    
                //     if (result && result.status == 'true') {
                //         this.setSession(result);
                //     }
                //     return result;
                // })
            );
    }

    login(credential: User, lang: string) {
        let encVal = this._AESEncryptDecryptService.encryptInAngular(JSON.stringify(credential) );
        return this.customeHttpClient
            .PostApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/authenticate_department_user",
                encVal, lang
            ).pipe(
                map((result: any) => {
                    
                    if (result && result.status == 'true') {
                        this.setSession(result);
                    }
                    return result;
                })
            );
    }

    loginWithSSO(id: string, lang: string) {
        
        return this.customeHttpClient
            .PostApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/authenticate_sso_department_user",
                id, lang
            ).pipe(
                map((result: any) => {
                    
                    if (result && result.status == 'true') {
                        this.setSession(result);
                    }
                    return result;
                })
            );
    }

    loginDepartmentSearchModule(id: string) {
        const param = {
            "uid" : id
          }
        return this.customeHttpClient
            .Post<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/authenticate_redirect_department_user",
                param
            ).pipe(
                map((result: any) => {
                    
                    if (result && result.status == 'true') {
                        this.setSession(result);
                    }
                    return result;
                })
            );
    }

    setSession(authResult: any) {
       
        const token = authResult.token;
        sessionStorage.setItem("token", token);
        sessionStorage.setItem("loginType", "admin");
        sessionStorage.setItem('isUserLoggedIn',"true");
        let today:any=  Date.now();
        sessionStorage.setItem("tokenTime", today);
        const authData: any = jwt_decode(token);
        const expiresAt = moment().add(authData.exp, "second");

        this.isUserLoggedIn=true;
        sessionStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()));
        this.currentUserSubject.next(this.setCurrentUserSubject(authResult.data));
    }

    featureList: FeatureList[] = [];
    private setCurrentUserSubject(data: any): IUserDetailModel {
        
        this.ud = {
            User_Id: data.responseData.id,
            UserName: data.responseData.userName,
            FullNameEn: data.responseData.nameEn,
            FullNameHi: data.responseData.nameHi,
            DesignationEn: data.responseData.designationEn,
            DesignationHi: data.responseData.designationHi,
            LoginType:"admin"
        };
        //this.featureList = data.responseData.featureList;
        sessionStorage.setItem("featureList", JSON.stringify(data.responseData.featureList));
        sessionStorage.setItem("authData", JSON.stringify(data.responseData));
        sessionStorage.setItem("officeList", JSON.stringify(data.responseData.officeList));
        sessionStorage.setItem("selectedOffice", JSON.stringify(data.responseData.selectedOffice));
        // console.log('data.responseData.selectedOffice: ', data.responseData.selectedOffice);
        //sessionStorage.setItem("subRoleList", JSON.stringify(data.responseData.subRoleList));
        return this.ud;
    }

    getprofileUsingToken(token: any) {
        
        const tokenDecoded: any = jwt_decode(token);
        var udata = JSON.parse(sessionStorage.getItem('authData') || '{}');
        if (udata.userName == tokenDecoded.sub) {
            this.ud = {
                User_Id: udata.id,
                UserName: udata.userName,
                FullNameEn: udata.nameEn,
                FullNameHi: udata.nameHi,
                DesignationEn: udata.designationEn,
                DesignationHi: udata.designationHi
            };

            this.currentUserSubject.next(this.ud)
        }
        else {
            
            this.logout().subscribe(res=>{
				res.status ? console.log("User logged out successfully!"): console.log("Some error during log out.");
            });
        }
    }

    viewCitizenProfile() {
        return this.customeHttpClient.GetApi<ResultModel<any>>(
            this.settings.igrs_gateway_url +
              "department/get_department_user_profile",null
          );
    }
    /*******************LOGOUT CODE SECTION ***************************/

    logout() {
        
        let user: User = {};
        user.userId = this.currentUserValue?.User_Id;
        user.userType = 'DEPARTMENT';
        return this.customeHttpClient
            .PostApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/logoutUser",
                user, sessionStorage.getItem("selectedLanguage")
            ).pipe(
                map((result: any) => {
                    
                    if (result && result.status == 'true') {
                        this.isUserLoggedIn=false;
                        this.clearsessionStorage();
                       if (window.parent.location.href.includes("department") || window.parent.location.href.includes("select-office")) {
                            this.router.navigate(['alogin']);
                        }else
                        
                       if(window.parent.location.href.includes("citizen")){
                           this.router.navigate(['clogin']);
                         }else{
                             this.router.navigate(['clogin']); 
                        } 
                    }
                    return result;
                },
                (error: any) => {
                    return error;
                })
        );   
    } 

    public clearsessionStorage() {

        let routeUrl='';
        if (sessionStorage.getItem('loginType') != undefined && sessionStorage.getItem('loginType') != null && sessionStorage.getItem('loginType') != "citizen") {
            
            routeUrl='/alogin'
          }else{
                        routeUrl='/alogin';
          }
        if (sessionStorage.length > 0) {
            sessionStorage.removeItem("isUserLoggedIn");
            sessionStorage.removeItem("loginType");
          
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("token_data");
            sessionStorage.removeItem("expires_at");
            sessionStorage.removeItem("ud");
            sessionStorage.removeItem("featureList");
            sessionStorage.removeItem("officeList");
            sessionStorage.removeItem("selectedOffice");
            sessionStorage.removeItem("authData");
        }
        if (window.parent.sessionStorage.length > 0) {
            window.parent.sessionStorage.removeItem("isUserLoggedIn");
            sessionStorage.removeItem("loginType");
            window.parent.sessionStorage.removeItem("token");
            window.parent.sessionStorage.removeItem("token_data");
            window.parent.sessionStorage.removeItem("expires_at");
            window.parent.sessionStorage.removeItem("ud");
            window.parent.sessionStorage.removeItem("featureList");
            window.parent.sessionStorage.removeItem("officeList");
            window.parent.sessionStorage.removeItem("selectedOffice");
            window.parent.sessionStorage.removeItem("authData");
        }
        this.currentUserSubject.next(null as any);
        this.router.navigate([routeUrl]);
    }

    tokenExpire() {
        this.logout().subscribe(res=>{
			res.status ? console.log("User logged out successfully!"): console.log("Some error during log out.");
        });
    }

    /*******************FORGOT PASSWORD CODE SECTION ***************************/

    // forgotPassword(credential: User) {
    //     return this.httpClient
    //         .get<ResultModel<any>>(
    //             this.settings.igrs_gateway_url + "common/forget_department_user_password?username=" + credential.userName
    //         ).pipe(map((result: any) => {
    //             
    //            // 
    //             return result;
    //         })
    //         );
    // }

    // getOtpForForget(userName:string){
    //     return this.customeHttpClient.Get<ResultModel<any>>(
    //          this.settings.igrs_gateway_url + "common/generate_otp_for_user?username="+userName+"&usertype=department"
    //      ).pipe(
    //          map((result: any) => {
    //              ;
                 
    //              if (result && result.responseStatus == 'true') {
    //                  return result;
    //              } else {
    //                  return result;
    //              }
    
    //          })
    //      );
    //  }

    getOtpForForget(citizenForgot: CitizenForgot, lang: string){
        
        
        let encVal = this._AESEncryptDecryptService.encryptInAngular(JSON.stringify(citizenForgot) );
        return this.customeHttpClient.Post<ResultModel<any>>(

            this.settings.igrs_gateway_url + "common/generate_otp_for_user", encVal
        ).pipe(
            map((result: any) => {
                if (result) {
                    return result;
                }
            },
                (error: any) => {

                    return error;
                }
            )
        );
        // pipe(
        //     map((result: any) => {
        //         ;

        //         if (result && result.responseStatus == 'true') {
        //             return result;
        //         } else {
        //             return result;
        //         }

        //     })
        // );
    }
    
     verifyOtpForForget(userName:string, otp:string, captchaId:string, captchaStr:string){
        return this.customeHttpClient.Get<ResultModel<any>>(
             this.settings.igrs_gateway_url + "common/verify_otp_for_user?username="+userName+"&usertype=department&otp="+otp+"&captchaid="+captchaId+"&captchastr="+captchaStr
         ).pipe(
             map((result: any) => {
                 ;
               //  
                 if (result && result.responseStatus == 'true') {
                     return result;
                 } else {
                     return result;
                 }
    
             })
         );
     }
    
    //  submitForgetPassword(userName:string, password:string, confPassword:string){
    //     return this.customeHttpClient.Get<ResultModel<any>>(
    //          this.settings.igrs_gateway_url + "common/reset_password_for_user?username="+userName+"&usertype=department&newpassword="+password+"&confirmpassword="+confPassword
    //      ).pipe(
    //          map((result: any) => {
    //              ;
    //              //
    //              if (result && result.responseStatus == 'true') {
    //                  return result;
    //              } else {
    //                  return result;
    //              }
    
    //          })
    //      );
    //  }

    


     getSSOUrl(){
        return this.customeHttpClient.Get<ResultModel<any>>(
             this.settings.igrs_gateway_url + "common/ssourl"
         ).pipe(
             map((result: any) => {
                 ;
                 //
                 if (result && result.responseStatus == 'true') {
                     return result;
                 } else {
                     return result;
                 }
    
             })
         );
     }

     getOfficeListByUsername(credential: User){
        //return this.customeHttpClient.Get<ResultModel<any>>(
        //     this.settings.igrs_gateway_url + "common/get_office_list_by_user?username="+userName
        let encVal = this._AESEncryptDecryptService.encryptInAngular(JSON.stringify(credential) );
        return this.customeHttpClient.Post<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/get_office_list_by_user", encVal    
         ).pipe(
             map((result: any) => {
                 ;
                 //
                 if (result && result.responseStatus == 'true') {
                     return result;
                 } else {
                     return result;
                 }
    
             })
         );
     }

     loginWithOfficeSelect(selectedoffice: number) {
        
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/set_department_user_office",
                selectedoffice
            ).pipe(
                map((result: any) => {
                    
                    if (result && result.status == 'true') {
                        this.setSession(result);
                    }
                    return result;
                })
            );
    }

    submitComplaintRequest(jsonData: string, authFile:any): Observable<any> {
        let httpHeaders = new HttpHeaders({
            "Content-Type":"application/json",
            "Accept-Language":  sessionStorage.getItem("selectedLanguage") || "" 
          });
        return this.customeHttpClient.Post<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/save_complaint_request", {jsonData, authFile},{ headers: httpHeaders}
        ).pipe(
            map((result: any) => {
                if (result ){
                    return result;
                }
            },
            (error: any) => {
                return error;
            })
        );
    }

    checkAvailabilityOfEmail(email:string):Observable<any>{
        return this.customeHttpClient.Get<ResultModel<any>>(
             this.settings.igrs_gateway_url + "common/dept_user_email_exists?email_id="+email
         ).pipe(
             map((result: any) => {
                 if (result && result.responseStatus == 'true') {
                     return result;
                 } else {
                     return result;
                 }
             })
         );
      }

    submitComplaintStatus(registrationNumber:string, requestTypeId:string){
        return this.customeHttpClient.Get<ResultModel<any>>(
             this.settings.igrs_gateway_url + "common/check_complaint_status?registrationNumber="+registrationNumber+"&requestTypeId="+requestTypeId
         ).pipe(
             map((result: any) => {
                 ;
                 //
                 if (result && result.responseStatus == 'true') {
                     return result;
                 } else {
                     return result;
                 }
    
             })
         );
     }

 
     getdscDetails(){
        return this.httpClient
        .get<any>(
            "http://localhost:8060/jsonsigner/certificate"
           // filename
        ).pipe(
            map((result: any) => {
                
                    // var string1 = JSON.stringify(result);
                  //   var data:any;
                  //  filename: 'yourFileName.pdf',
                 //   data= result.blob()
                    return result;
            },
                (error: any) => {
                    alert("not found");
                    return error;
                }
            )
        );
    }
    
    fetchUserCertificateData(cerfData: string,userId:number) {
        
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/fetchUserCertificateDatafromdsc/"+userId,
                cerfData
            ).pipe(
                map((result: any) => {
                    
                    if (result && result.status == 'true') {
                        this.setSession(result);
                    }
                    return result;
                })
            );
    }

    getUserProfilePic(citizenModel: Department){
        let encVal = this._AESEncryptDecryptService.encryptInAngular(JSON.stringify(citizenModel));
        return this.customeHttpClient.Post<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/downloadUserProfilePic", encVal    
         ).pipe(
             map((result: any) => {
                 ;
                 //
                 if (result && result.responseStatus == 'true') {
                     return result;
                 } else {
                     return result;
                 }
    
             })
         );
     }

    //  getUserProfileImgUrl(userId: number, userType: string, ownerType: string):Observable<any> {
    //     return this.customeHttpClient.Get<ResultModel<any>>(
    //         this.settings.igrs_gateway_url + "common/getUserImagUrl?&userId=" + userId + "&userType=" + userType + "&ownerType=" + ownerType
    //     ).pipe(
    //         map((result: any) => {
    //             ;

    //             if (result && result.responseStatus == 'true') {
    //                 return result;
    //             } else {
    //                 return result;
    //             }

    //         })
    //     );
    // }

    // getPartyImgUrl(partyId: number, userType: string, ownerType: string):Observable<any> {
    //     return this.customeHttpClient.Get<ResultModel<any>>(
    //         this.settings.igrs_services_url + "common/ereg/getPartyImageUrl?&partyId=" + partyId + "&userType=" + userType + "&ownerType=" + ownerType
    //     ).pipe(
    //         map((result: any) => {
    //             ;

    //             if (result && result.responseStatus == 'true') {
    //                 return result;
    //             } else {
    //                 return result;
    //             }

    //         })
    //     );
    // }

    getPartyPhotoBase64ByPartyId(partyId: number, userType: string):Observable<any> {
        return this.customeHttpClient.Get<ResultModel<any>>(
            this.settings.igrs_services_url + "common/ereg/getPartyPhotoBase64ByPartyId?&partyId=" + partyId + "&userType=" + userType 
        ).pipe(
            map((result: any) => {
                ;

                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }

    savePartyCameraImage(citizenModel: Citizen) {
        let encVal = this._AESEncryptDecryptService.encryptInAngular(JSON.stringify(citizenModel));
        return this.customeHttpClient.Post<ResultModel<any>>(
            this.settings.igrs_services_url + "common/ereg/downloadPartyImage", encVal
        ).pipe(
            map((result: any) => {
                ;
                //
                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }
 
}