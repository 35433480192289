<div class="banner-header">
    <div class="container">
        <div class="breadcrumb-content">
            <div class="row">
                <div class="col-lg-12 d-flex justify-content-between">
                    <h2>
                        {{'WalletWiseChallan.estampQRCV' | translate}}
                    </h2>
                    <button (click)="onBtnPrintClick(lan)" target="_blank" *ngIf="isViewEstampQRDetails"
                        class="btn btn-primary d-print-none mr-2 mt-1 ml-1">
                        {{'print' | translate}} / {{'admin-profile-page-data.center-serviceprovider-details.download' |
                        translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="qrCodeSection" *ngIf="!isViewEstampQRDetails">


    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <mat-card class="example-card">
                    <mat-card-title></mat-card-title>
                    <mat-card-content class="mat-card-content text-center">
                        <div class="alert alert-info">
                            Estamp Is Not Completed
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
 
    </div>
</div>
    <div class="qrCodeSection" *ngIf="isViewEstampQRDetails">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <mat-card class="example-card">
                        <mat-card-title></mat-card-title>
                        <mat-card-content class="mat-card-content">




                            <div class="modal-box" id="print-card">
                                <fieldset class="legend-section">
                                    <legend class="sidebar__widget-title">
                                        {{ "e-stamp.E-stamp-Details" | translate }}
                                    </legend>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-7">
                                                        <label>{{'Search-Document-Certified.estamp_code'| translate }}:
                                                        </label>
                                                    </div>
                                                    <div class="col-5">
                                                        <span>{{estampDetailsModel?.estampCode}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-7">
                                                        <label>{{'e-stamp.Total-E-Stamp-Amount'| translate }}:</label>
                                                    </div>
                                                    <div class="col-5">
                                                        <span> {{estampDetailsModel?.totalDuty|amount}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-7">
                                                        <label> {{'e-stamp.Govt-Stamp-Duty'| translate }} :
                                                        </label>
                                                    </div>
                                                    <div class="col-5">
                                                        <span>{{estampDetailsModel?.principalStampDuty|amount}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-7">
                                                        <label>{{'e-stamp.Municipality-Duty'| translate }} :
                                                        </label>
                                                    </div>
                                                    <div class="col-5">
                                                        <span>{{estampDetailsModel?.municipalDuty|amount}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-7">
                                                        <label>{{'e-stamp.Janpad-Duty'| translate }} :</label>
                                                    </div>
                                                    <div class="col-5">
                                                        <span>{{estampDetailsModel?.janpadDuty|amount}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-7">
                                                        <label>{{'e-stamp.Upkar-Amount'| translate }} :</label>
                                                    </div>
                                                    <div class="col-5">
                                                        <span>{{estampDetailsModel?.upkarDuty|amount}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="col-md-6">
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-7">
                                                    <label>{{'e-stamp.Exempted-Amount'| translate }} :</label>
                                                </div>
                                                <div class="col-5">
                                                    <span>{{estampDetailsModel?.dutyExemption}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-7">
                                                        <label>{{'e-stamp.E-Stamp-Type'| translate }}:</label>
                                                    </div>
                                                    <div class="col-5">
                                                        <span>{{'e-stamp.estamp-type'| translate }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-7">
                                                        <label>{{'WalletWiseChallan.issueDT'| translate }}:</label>
                                                    </div>
                                                    <div class="col-5">
                                                        <!-- <span> {{estampDetailsModel?.createdDate |
                                                            date:'medium'}}</span> -->
                                                            <span> {{estampDetailsModel?.createdDate |
                                                                date:'dd/MM/YYYY, h:mm:ss a'}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-7">
                                                        <label>{{'e-stamp.Service-Provider-Issuer-Details'| translate
                                                            }}:</label>
                                                    </div>
                                                    <div class="col-5">
                                                        <span> 
                                                            <!-- {{(lan == 0) ? userProfileModel?.firstNameEn +" "+userProfileModel?.middleNameEn+" "+ userProfileModel?.lastNameEn : userProfileModel?.firstNameHi +" "+userProfileModel?.middleNameHi+" "+userProfileModel?.lastNameHi}} -->
                                                            {{(lan == 0) ? serviceProviderName : serviceProviderNameHi}}
                                                            <span
                                                                *ngIf="userProfileModel.serviceProvider && userProfileModel.serviceProvider.sPLicence">
                                                                ({{userProfileModel?.serviceProvider.sPLicence}})
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="col-md-6">
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-7">
                                                    <label>{{'e-stamp.SP/SRO/DRO/HO Details'| translate }}:</label>
                                                </div>
                                                <div class="col-5" *ngIf="userProfileModel.serviceProvider!=null">
                                                    <span>
                                                        {{userProfileModel?.serviceProvider.officeBean?.officeNameEn}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->


                                    </div>
                                </fieldset>

                                <fieldset class="legend-section">
                                    <legend class="sidebar__widget-title">
                                        {{'WalletWiseChallan.qur-tit1' | translate}}
                                    </legend>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-7">
                                                        <label> {{'duty-calculation.Deed-Type'| translate }}: </label>
                                                    </div>
                                                    <div class="col-5">
                                                        <span>{{(lan==0) ? deedType : deedTypeHi}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-7">
                                                        <label> {{'duty-calculation.inst_name'| translate }}: </label>
                                                    </div>
                                                    <div class="col-5">
                                                        <span>{{(lan==0) ? deedInstrument : deedInstrumentHi}} </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>


                                <fieldset class="legend-section" *ngIf="firstpartyList.length!=0 ">

                                    <legend class="sidebar__widget-title">


                                        {{'WalletWiseChallan.firPartD'| translate }}
                                    </legend>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <table class="table table-bordered mb-1">
                                                <thead class="thead-dark">
                                                    <tr>
                                                        <th>{{'duty-calculation.SNO'| translate }}</th>
                                                        <th>
                                                            {{'WalletWiseChallan.orgPD'| translate }}
                                                        </th>
                                                        <th>
                                                            {{'admin-profile-page-data.center-office-details.contactNumber'
                                                            | translate }}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of firstpartyList; let i = index">
                                                        <td>{{i+1}}</td>
                                                        <td>{{(lan==0)?item?.partyName : item?.partyNameHindi}} </td>
                                                        <td>{{item.mobileNo}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset class="legend-section" *ngIf="secondpartyList.length!=0 ">
                                    <legend class="sidebar__widget-title">

                                        {{'WalletWiseChallan.sepD'| translate }}
                                    </legend>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <table class="table table-bordered">
                                                <thead class="thead-dark">
                                                    <tr>
                                                        <th>{{'duty-calculation.SNO'| translate }}</th>
                                                        <th>
                                                            {{'WalletWiseChallan.orgPD'| translate }}
                                                        </th>
                                                        <th>
                                                            {{'admin-profile-page-data.center-office-details.contactNumber'
                                                            | translate }}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of secondpartyList; let i = index">
                                                        <td>{{i+1}}</td>
                                                        <td>{{(lan==0)?item?.partyName : item?.partyNameHindi}}</td>
                                                        <td>{{item.mobileNo}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </fieldset>
                                <!--<fieldset class="legend-section" *ngIf="witnesspartyList.length != 0">
                                    <legend class="sidebar__widget-title">
                                        {{'e-registry-completion.party-details-Verification.witnessDetails'| translate
                                        }}
                                    </legend>
                                     <div class="row">
                                        <div class="col-md-12">
                                            <table class="table table-bordered">
                                                <thead class="thead-dark">
                                                    <tr>
                                                        <th>{{'duty-calculation.SNO'| translate }}</th>
                                                        <th>
                                                            {{'WalletWiseChallan.orgPD'| translate }}
                                                        </th>
                                                        <th>
                                                            {{'admin-profile-page-data.center-office-details.contactNumber'
                                                            | translate }}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr *ngFor="let item of witnesspartyList; let i = index">
                                                        <td>{{i+1}}</td>
                                                        <td>{{(lan==0)?item?.partyName : item?.partyNameHindi}}</td>
                                                        <td>{{item.mobileNo}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div> 
                                </fieldset>-->

                                <!-- <h3 class="card-title mr-2">Affidavit cum Declaration</h3>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <div [innerHTML]="estampDetailsModel?.finalContent"></div>
                                    </div>
                                </div>
                            </div> -->



                            <div class="d-none d-print d-print-block rotingtxt" style="
                            position: absolute;
                         top: 0;
                         bottom: 0;
                         left: 0;
                         right: 0;
                         height: auto;
                         text-align: center;
                         justify-content: center;
                         align-items: center;
                         flex-direction: row;
                         flex-wrap: wrap;
                         opacity: 0.1;
                         width: 100%;
                         display: block !important;
                         overflow: hidden;
                         height: 100%;
                         top: 0;
                         bottom: 0;
                         margin: 0 auto;
                         left: 0;
                         right: 0;" *ngIf="estampDetailsModel.estampStatus==134">
                     <div style="
                      transform: rotate(-45deg);
                         position: relative;
                         top: 0;
                         bottom: 0;
                         left: 0;
                         right: 0;
                         display: flex;
                         justify-content: center;
                         align-items: center;
                         width: 100%;
                         height: 100%;
                     ">
                     
                       <h1 style=" font-family: 'Roboto', sans-serif;   font-size: 79px;
                       margin-bottom: 42px;
                       color: #000;
                       text-transform: uppercase;
                       font-weight: 900;" *ngIf="lan==0">{{msgForRefundEn}}</h1>
                      <h1 style="font-family: 'Roboto', sans-serif;font-size:250px;margin-bottom: 42px;
                     color: #000; text-transform: uppercase;
                     font-weight: 900;" *ngIf="lan==1">{{msgForRefundHi}}</h1>
                     </div>
                     </div>




                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>

    <!-- <ngx-ui-loader></ngx-ui-loader> -->