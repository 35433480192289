<lib-alertmessage></lib-alertmessage>
<div class="PropertyValuation">
    <div class="innerHeader1">
        <div class="container">
            <div class="row pt-1">
                <div class="col-md-12">
                    <h2> {{'property-valuation.property-valuation-title' | translate}}</h2>
                    <ol class="breadcrumb">
                        <li><a href="#" class="text-dark">{{'property-valuation.property-valuation-home' |
                                translate}}</a></li>
                        <li class="active">{{'property-valuation.property-valuation-title' | translate}}</li>
                    </ol>
                </div>
            </div>
        </div>

        <!--
        <div class="innerHeader-image text-right">
            <img src="assets/images/property-valuation-header.png" alt="image">
        </div> -->
    </div>

    <div class="PropertyValuationInnerBody new_search">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-11">
                    <div class="searchPenal">
                        <!-- <p>{{'property-valuation.Search-By' | translate}}</p> -->
                        <form>
                            <div class="search-box d-sm-flex align-items-center">
                                <div class="search-box-content">
                                    <!-- <h2 class="filter_tit">{{filterType}}</h2> -->
                                    <mat-expansion-panel class="" [expanded]="step === 0" (opened)="setStep(0)">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title class="">
                                                <div class="my-panel" (click)="$event.stopPropagation();">

                                                    <form class="formlabel-letter">
                                                        <div class="p-1 tab_div-change">

                                                            <!--Rahul--------------------------------------------------------------------------------->

                                                            <!-- *ngIf="filterType =='byname'"
                                                        <div class="form-check form-check-inline no-radio-box" *ngIf="filterType =='bynameUrbon'">
                                                        <input class="form-check-input" type="radio" [(ngModel)]="filterType"
                                                            (click)="allFormReset1()" [value]="'bynameUrbon'" name="inlineRadioOptions"
                                                            id="inlineRadio5">
                                                            <label class="form-check-label"
                                                            for="inlineRadio1">{{'property-valuation.By-Area' | translate}}</label>
                                                    </div>  -->
                                                            <!--End----------------------------------------------------------------------------------->

                                                            <div
                                                                class="form-check form-check-inline no-radio-box childlabel">
                                                                <input class="form-check-input" type="radio"
                                                                    [(ngModel)]="filterType"
                                                                    (click)="allFormReset(drawer, 'khasraid')"
                                                                    [value]="'khasraid'" name="inlineRadioOptions"
                                                                    id="inlineRadio2" [checked]="true">
                                                                <label class="form-check-label"
                                                                    for="inlineRadio2">{{'property-valuation.Agriculture-Property'
                                                                    |
                                                                    translate}}
                                                                    <span
                                                                        class="d-block small1">{{'property-valuation.Khasra-Unique-ID'
                                                                        | translate}}</span>
                                                                </label>
                                                            </div>
                                                            <div
                                                                class="form-check form-check-inline no-radio-box childlabel">
                                                                <input class="form-check-input" type="radio"
                                                                    [(ngModel)]="filterType"
                                                                    (change)="changePlaceHolder()"
                                                                    (click)="allFormReset(drawer, 'propid')"
                                                                    [value]="'propid'" name="inlineRadioOptions"
                                                                    id="inlineRadio3">
                                                                <label class="form-check-label"
                                                                    for="inlineRadio3">{{'property-valuation.Property-ID'
                                                                    | translate}}
                                                                    <span
                                                                        class="d-block small1">{{'property-valuation.E-Nagarpalika'
                                                                        | translate}}</span>
                                                                </label>
                                                            </div>
                                                            <!--<div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" [(ngModel)]="filterType"
                                                            value="ulpin" name="inlineRadioOptions" id="inlineRadio3">
                                                        <label class="form-check-label" for="inlineRadio3">{{'property-valuation.ULPIN'
                                                            | translate}}</label>
                                                    </div>-->
                                                            <div
                                                                class="form-check form-check-inline no-radio-box childlabel">
                                                                <input class="form-check-input" type="radio"
                                                                    [(ngModel)]="filterType"
                                                                    (change)="changePlaceHolder()"
                                                                    (click)="allFormReset(drawer, 'panchayatid')"
                                                                    [value]="'panchayatid'" name="panchayatid"
                                                                    id="panchayatid">
                                                                <label class="form-check-label"
                                                                    for="inlineRadio4">{{'property-valuation.Panchayat-ID'
                                                                    | translate}}
                                                                    <span
                                                                        class="d-block small1">{{'property-valuation.Swamitva-ID'
                                                                        | translate}}</span>
                                                                </label>
                                                            </div>
                                                            <div
                                                                class="form-check form-check-inline no-radio-box childlabel">
                                                                <input class="form-check-input" type="radio"
                                                                    [(ngModel)]="filterType"
                                                                    (click)="searchBygeoTagAndAdress()"
                                                                    [value]="'bynameUrbon'" name="bynameUrbon"
                                                                    id="inlineRadio1">
                                                                <label class="form-check-label"
                                                                    for="inlineRadio1">{{'property-valuation.By-Area' |
                                                                    translate}}
                                                                    <span
                                                                        class="d-block small1">{{'property-valuation.Address-Search'
                                                                        | translate}}</span>
                                                                </label>
                                                            </div>

                                                            <div
                                                                class="form-check form-check-inline no-radio-box childlabel">
                                                                <input class="form-check-input" type="radio"
                                                                    [(ngModel)]="filterType"
                                                                    (change)="changePlaceHolder()"
                                                                    (click)="searchBygeoTagAndAdress()"
                                                                    [value]="'searchByGeotag'" name="searchByGeotag"
                                                                    id="inlineRadio5">
                                                                <label class="form-check-label"
                                                                    for="inlineRadio5">{{'property-valuation.Geo-Tag-ID'
                                                                    | translate}}
                                                                    <span
                                                                        class="d-block small1">{{'property-valuation.By-Property-Photo'
                                                                        | translate}}</span>
                                                                </label>
                                                            </div>
                                                            <!-- <div> <i class="fa fa-whatsapp py-3 text-success fa-2x"></i></div> -->
                                                        </div>
                                                    </form>
                                                </div>
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div class="input-group pb-1 searchField zindex-1">

                                            <div *ngIf="filterType!='byname' && filterType!='bynameUrbon'"
                                                class="d-flex w-100 p-1">

                                                <div class="input-group-prepend">
                                                    <span
                                                        class="input-group-text text-muted rounded-pill border-0 ps-3 bg-transparent"
                                                        id="button-addon2"><i class="fa fa-search"></i> </span>
                                                </div>
                                                <div *ngIf="filterType!='khasraid'&& filterType!='searchByGeotag'"
                                                    class="w-100">
                                                    <input type="text" class="form-control" name="customId"
                                                        [(ngModel)]="id" id="inp" maxlength="50"
                                                        [placeholder]="placeHolder" [appCopyPastValidation]="true"
                                                        aria-label="" appPositiveNumbersOnlyDirective
                                                        (keypress)="omit_special_char($event)"
                                                        aria-describedby="button-addon2">
                                                </div>

                                                <div *ngIf="filterType=='searchByGeotag'" class="w-100">
                                                    <input type="text" class="form-control" name="customId"
                                                        [(ngModel)]="id" id="inp" maxlength="50"
                                                        [placeholder]="placeHolder" aria-describedby="button-addon2">
                                                </div>
                                                <div *ngIf="filterType=='khasraid'" class="w-100">
                                                    <mat-form-field class="example-chip-list border-0 bg_white pt-2">
                                                        <mat-label>{{'property-valuation.enter-Khasra-ID' |
                                                            translate}}</mat-label>
                                                        <mat-chip-list #chipList aria-label="">
                                                            <mat-chip *ngFor="let param of params; let i = index"
                                                                (removed)="remove(param,drawer)"
                                                                [style]="(khasraColor[i])?khasraColor[i].bg+khasraColor[i].color:''">
                                                                {{param}}
                                                                <button matChipRemove>
                                                                    <mat-icon>close</mat-icon>
                                                                </button>
                                                            </mat-chip>
                                                            <input appAlphaNumericSpace maxlength="50"
                                                                placeholder="{{'property-valuation.Write-Then-Enter' | translate}}"
                                                                [matChipInputFor]="chipList"
                                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                                [matChipInputAddOnBlur]="addOnBlur"
                                                                (matChipInputTokenEnd)="add($event)">
                                                        </mat-chip-list>
                                                    </mat-form-field>
                                                </div>


                                                <!-- <div class="input-group-append" *ngIf="filterType=='khasraid'">

                                                            <button mat-flat-button class="mt-1" type="button"
                                                                (click)="addMoreKhashra()" id="button-addon2">
                                                                <mat-icon class="btn btn-primary w-auto h-auto ">add_circle</mat-icon></button>
                                                        </div> -->

                                                <!-- <div class="input-group-append" *ngIf="filterType=='khasraid'">
                                                            <button mat-flat-button class="btn btn-primary mt-1 mr-1" type="button"
                                                                (click)="addMoreKhashra()" id="button-addon2">
                                                        add more</button>
                                                        </div> -->

                                                <div class="input-group-append">
                                                    <button mat-flat-button class="btn btn-primary mt-1 mr-1"
                                                        type="button" (click)="selectKhIdAndPrIdAndUnid(filterType)"
                                                        id="button-addon2">
                                                        {{'property-valuation.Submit' | translate}}</button>

                                                    <button type="button" class="btn btn-primary btn-sm"
                                                        style="z-index: 100;" *ngIf="islClearBtnShow"
                                                        (click)="clearSearch()">
                                                        {{'reset' | translate}}
                                                    </button>
                                                </div>

                                            </div>

                                            <div class="ng-autocomplete d-flex pt-2  px-2 dd-field w-100"
                                                *ngIf="filterType=='byname' || filterType=='bynameUrbon'">
                                                <div class="dropdown bdr-rt" *ngIf="false">
                                                    <!-- <button class="btn  dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                                                             By Area Type
                                                            </button> -->
                                                    <select class="form-control bdr-rt border-0 w-auto"
                                                        id="exampleFormControlSelect1"
                                                        (change)="selectSearchType($event)">
                                                        <option value="R">{{'rural' | translate}}</option>
                                                        <option value="U">{{'urban' | translate}}</option>

                                                    </select>
                                                    <!-- <div class="dropdown-menu">
                                                                <a class="dropdown-item" (click)="selectSearchType('R')">Rural</a>
                                                                <a class="dropdown-item" (click)="selectSearchType('U')">Urban</a>

                                                              </div> -->
                                                    <!-- <div class="dropdown-menu">
                                                              <a class="dropdown-item" href="#">Rural</a>
                                                              <a class="dropdown-item" href="#">Urban</a>

                                                            </div> -->
                                                </div>
                                                <div class="input-group-prepend" *ngIf="filterType=='byname'">
                                                    <span
                                                        class="input-group-text text-muted rounded-pill border-0 ps-3 bg-transparent"
                                                        id="button-addon2"><i class="fa fa-search"></i> </span>
                                                </div>
                                                <!-- <ng-autocomplete (inputChanged)='onChangeSearch($event)' [data]="demographyBeanSearch" [searchKeyword]="keyword"
                                                            placeholder="{{'property-valuation.Enter Area/Locality' | translate}}" (selected)='selectEvent($event)'
                                                             (inputFocused)='onFocused($event)'
                                                            historyIdentifier="MstDemographyBeanListHistory"
                                                            [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
                                                        </ng-autocomplete> -->
                                                <!-- <input id="inputAdd" name="inputAdd" type="text" appAlphaNumericSpace
                                                            placeholder="{{'property-valuation.Enter-address' | translate}}" #inputAdd
                                                            class="noborder w-100" *ngIf="filterType=='byname'" (keypress)="omit_special_char($event)"> -->

                                                <!-- Rahul -->
                                                <div class="search_section_both w-100"
                                                    *ngIf="filterType=='bynameUrbon'">
                                                    <div class="ng-autocomplete d-flex dd-field">
                                                        <div class="input-group-prepend">
                                                            <span
                                                                class="input-group-text text-muted rounded-pill border-0 ps-3 bg-transparent"
                                                                id="button-addon2"><i class="fa fa-search"></i> </span>
                                                        </div>
                                                        <input type="text" maxlength="50"
                                                            placeholder="{{'property-valuation.Search-By'| translate}} {{'address' | translate}}"
                                                            #inputMapMyIndia class="noborder wurban"
                                                            (keyup)="getsuggetions(inputMapMyIndia)"
                                                            (input)="getsuggetions(inputMapMyIndia)"
                                                            specialIsAlphaNumeric>

                                                        <div #suggetionDiv class="suggetionDiv" *ngIf="islistShow">
                                                            <ul #suggetionList class="suggetionList">

                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <!-- advanceFilterOption -->
                                                    <div class="search_location" *ngIf="!islCencelBtnShow">
                                                        <a class="search_locationBtn" (click)="toggleStatus()">
                                                            <i class="fa fa-search searchicon_color"></i> Search by
                                                            guideline location
                                                        </a>
                                                        <div class="advanceFilterOption  slideDown  d-block {{hiddenvalue}}"
                                                            *ngIf="resetDemo" cdkDrag>
                                                            <button class="filterClosebtn"
                                                                (click)="toggleStatus()">x</button>
                                                            <div class="p-2">
                                                                <citizen-demography-hierarchy-load style="width: 100%;"
                                                                    [componentFlag]="'columnwise'" [type_id]="type_id"
                                                                    [parent_id]="parent_id" [lastdemograpgy_id]="15"
                                                                    (loadgldata)="getDemography($event)"></citizen-demography-hierarchy-load>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="button" class="btn btn-primary btn-sm"
                                                    style="z-index: 100;" *ngIf="islClearBtnShow"
                                                    (click)="clearSearch()">
                                                    {{'reset' | translate}}
                                                </button>
                                                <!-- end rahul -->
                                            </div>

                                        </div>
                                    </mat-expansion-panel>
                                </div>
                                <!-- <button (click)="mapOpenClose()" mat-icon-button
                                                aria-label="Example icon button with a vertical three dot icon">
                                                <mat-icon>add_location</mat-icon>
                                            </button> -->


                            </div>
                        </form>


                        <!-- newsearchbox -->


                        <!-- End -->
                    </div>
                </div>
            </div>
            <div class="row">

                <mat-drawer-container [hasBackdrop]="false" opened="false" class="example-container app-drawer-wrapper1"
                    mode="side" position="end">

                    <mat-drawer #drawer class="drawer " mode="side" position="end">

                        <fieldset class="mt-5">
                            <div class="p-1 border-0"
                                *ngIf="( selectedMstDemographyBean?.location?.ward_colony!=null && selectedMstDemographyBean?.location?.ward_colony!=undefined)|| selectedMstDemographyBean?.location?.village_colony!=null">
                                <legend class="w-100 bg-secondary text-light">
                                    <h4 class="m-1 p-0">{{'property-valuation.Choose-option-to-calculate-property-rates'
                                        |
                                        translate}} </h4>
                                </legend>
                                <div class="propertyTypeFilter">
                                    <div class="btn-group btn-group-toggle active_color position-relative w-100"
                                        data-toggle="buttons">
                                        <div *ngFor="let mainpt of mstPropertyTypeBean">
                                            <div *ngIf="mainpt.id==1||mainpt.id==2||mainpt.id==3">

                                                <label class="btn btn-sm mr-2 pt-2 width-auto"
                                                    *ngIf="checkIsConstCheck(mainpt.id)"
                                                    [ngClass]="{ 'blur': checkIsConstCheck(mainpt.id)}"
                                                    [ngClass]="{ 'active': mainAreaType==mainpt.id }"
                                                    (click)="clickAreaTypeEventReset(mainpt)">

                                                    <input type="radio" [value]="mainpt.id" [(ngModel)]="mainAreaType"
                                                        (ngModelChange)="clickAreaTypeEventReset(mainpt)" name="options"
                                                        id="typemain_{{mainpt.id}}" class="d-none">
                                                    <mat-icon *ngIf="mainpt.id==1">picture_in_picture_alt</mat-icon>
                                                    <mat-icon *ngIf="mainpt.id==2"> business</mat-icon>
                                                    <mat-icon *ngIf="mainpt.id==3"> local_florist</mat-icon>
                                                    {{lan==0?mainpt.nameEn:mainpt.nameHi}}
                                                </label>
                                                <button class="btn btn-primary restbutton"
                                                    *ngIf="this.propertyValuationRequestBean.isConstOnAgriLand && mainpt.id==2"
                                                    (click)="resetMainOption(mainpt.id)">{{'property-valuation.Reset-Button'
                                                    | translate}}</button>
                                                <button class="btn btn-primary restbutton"
                                                    *ngIf="(this.propertyValuationRequestBean.isPlotOnAgriLand && this.propertyValuationRequestBean.usage=='undiverted') && mainpt.id==1"
                                                    (click)="resetMainOption(mainpt.id)">{{'property-valuation.Reset-Button'
                                                    | translate}}</button>
                                            </div>

                                        </div>

                                    </div>
                                </div>


                            </div>

                        </fieldset>
                        <mat-toolbar class="header mar-t-500px">


                            <mat-icon class="close drawer-nav-btn d-sm-block d-md-none"
                                (click)="drawer.close();mapReset()">close</mat-icon>

                            <div class="col-lg-12 "
                                *ngIf="( selectedMstDemographyBean?.location?.ward_colony!=null && selectedMstDemographyBean?.location?.ward_colony!=undefined)|| selectedMstDemographyBean?.location?.village_colony!=null">
                                <app-plot-valuation *ngIf="isPlot" [selectDemographyData]="selectedMstDemographyBean"
                                    [propertyValuationRequestBeanData]="propertyValuationRequestBean"
                                    [subClauseMasterBeanData]="subClauseMasterBean"
                                    [fromCheckerModule]="fromCheckerModule" [fromDutyModule]="fromDutyModule"
                                    [builderShareData]="builderShare" [fromCMSModule]="fromCMSModule"
                                    [isImpoundAddProperty]="isImpoundAddProperty">
                                </app-plot-valuation>
                                <app-building-valuation *ngIf="isBuilding"
                                    [selectDemographyData]="selectedMstDemographyBean"
                                    [propertyValuationRequestBeanData]="buildingPropertyValuationRequestBean"
                                    [mpenagarpalikaResponsModel]="mpenagarpalikamodel" [pnrdDataModel]="prndData"
                                    [builderShareData]="builderShare" [subClauseMasterBeanData]="subClauseMasterBean"
                                    [fromCheckerModule]="fromCheckerModule"
                                    [isImpoundAddProperty]="isImpoundAddProperty" [fromDutyModule]="fromDutyModule"
                                    [fromCMSModule]="fromCMSModule" [guildeValuationData]="guildeLineObj">
                                </app-building-valuation>
                                <app-agri-valuation *ngIf="isArgiculture" [isPartialTransactFlag]="isPartialTransact"
                                    [selectDemographyData]="selectedMstDemographyBean"
                                    [webGisResponseData]="webGisResponseData" [fromCheckerModule]="fromCheckerModule"
                                    [propertyValuationRequestBeanData]="agriPropertyValuationRequestBean"
                                    [builderShareData]="builderShare" [subClauseMasterBeanData]="subClauseMasterBean"
                                    [uomListData]="uomList" [fromCMSModule]="fromCMSModule"
                                    [isImpoundAddProperty]="isImpoundAddProperty" [fromDutyModule]="fromDutyModule"
                                    [propertyTypeData]="mstPropertyTypeBean">
                                </app-agri-valuation>
                                <div *ngIf="guildeLineObj"
                                    class="card-footer sticky left_st_box position-sticky top-30">
                                </div>
                            </div>
                        </mat-toolbar>

                    </mat-drawer>
                    <mat-drawer-content class="content">

                        <button mat-raised-button (click)="drawer.toggle();mapReset()"
                            class="fixed-pos systemwise-position" *ngIf="selectedMstDemographyBean!=null">
                            <mat-icon>arrow_left</mat-icon><mat-icon>arrow_right</mat-icon>
                        </button>
                        <div class="col-md-12 col-sm-12 h-100">

                            <div class="row h-100">
                                <div *ngIf="selectedMstDemographyBean!=null"
                                    [ngClass]=" flag1 ? 'col-md-0' : 'col-lg-3'" class="p-0 new-chnage">
                                    <button mat-raised-button (click)="leftColtoggle();mapReset()"
                                        class="fixed-pos"><mat-icon>arrow_left</mat-icon><mat-icon>arrow_right</mat-icon></button>
                                    <div class="card mb-4 border-0">
                                        <div class="card-body pt-0 pb-0 px-3 badge-light">
                                            <div class="searchRecord mb-0 heading_guideline justify-content-center">
                                                <div class="heading_guideline-tit">{{'property-valuation.ward_colony' |
                                                    translate}}</div>
                                                <div *ngIf="selectedMstDemographyBean?.location?.district?.demographyName!=null&&selectedMstDemographyBean?.location?.district?.demographyName!=null"
                                                    class="new-design-box p-0 ">
                                                    <div class="single-block">
                                                        <div id="bg">
                                                            <div id="bg-bottom"></div>
                                                            <div id="top"><img src="./assets/images/district.png"></div>

                                                            <div id="bottom"></div>
                                                        </div>
                                                        <p class="text-theme">{{'property-valuation.District' |
                                                            translate}}</p>
                                                        <h3 class="mb-0 pb-0">
                                                            {{lan==0?selectedMstDemographyBean?.location?.district?.demographyNameEn:selectedMstDemographyBean?.location?.district?.demographyNameHi}}
                                                        </h3>
                                                    </div>
                                                </div>

                                                <div *ngIf="selectedMstDemographyBean?.demographyType?.demographyAreaType=='R'"
                                                    class="new-design-box p-0 ">
                                                    <div class="single-block">
                                                        <div id="bg">
                                                            <div id="bg-bottom"></div>
                                                            <div id="top"><img src="./assets/images/areatype.png"></div>
                                                            <div id="bottom"></div>
                                                        </div>
                                                        <p class="text-theme">{{'property-valuation.Area-Type' |
                                                            translate}}</p>
                                                        <h3 class="mb-0 pb-0">{{'property-valuation.Rural' | translate}}
                                                        </h3>
                                                    </div>
                                                </div>

                                                <div *ngIf="selectedMstDemographyBean?.demographyType?.demographyAreaType=='U'"
                                                    class="new-design-box p-0 ">
                                                    <div class="single-block">
                                                        <div id="bg">
                                                            <div id="bg-bottom"></div>
                                                            <div id="top"><img src="./assets/images/areatype.png"></div>
                                                            <div id="bottom"></div>
                                                        </div>
                                                        <p class="text-theme">{{'property-valuation.Area-Type' |
                                                            translate}}</p>
                                                        <h3 class="mb-0 pb-0">{{'property-valuation.Urban' | translate}}
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div *ngIf="selectedMstDemographyBean?.location?.tehsil?.demographyName!=null&&selectedMstDemographyBean?.location?.tehsil?.demographyName!=''"
                                                    class="new-design-box p-0 ">
                                                    <div class="single-block">
                                                        <div id="bg">
                                                            <div id="bg-bottom"></div>
                                                            <div id="top"><img src="./assets/images/tehsile.png"></div>
                                                            <div id="bottom"></div>
                                                        </div>
                                                        <p class="text-theme">{{'property-valuation.Tehsil' |
                                                            translate}}</p>
                                                        <h3 class="mb-0 pb-0">
                                                            {{lan==0?selectedMstDemographyBean?.location?.tehsil?.demographyNameEn:selectedMstDemographyBean?.location?.tehsil?.demographyNameHi}}
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div *ngIf="selectedMstDemographyBean?.location?.localBody?.demographyName!=null&&selectedMstDemographyBean?.location?.localBody?.demographyName!=''"
                                                    class="new-design-box p-0 ">
                                                    <div class="single-block">
                                                        <div id="bg">
                                                            <div id="bg-bottom"></div>
                                                            <div id="top"><img src="./assets/images/tehsile.png"></div>
                                                            <div id="bottom"></div>
                                                        </div>
                                                        <p class="text-theme">{{'property-valuation.localbody' |
                                                            translate}}</p>
                                                        <h3 class="mb-0 pb-0">
                                                            {{lan==0?selectedMstDemographyBean?.location?.localBody?.demographyNameEn:selectedMstDemographyBean?.location?.localBody?.demographyNameHi}}
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div *ngIf="selectedMstDemographyBean?.location?.ward?.demographyName!=null&&selectedMstDemographyBean?.location?.ward?.demographyName!=''"
                                                    class="new-design-box p-0 ">
                                                    <div class="single-block">
                                                        <div id="bg">
                                                            <div id="bg-bottom"></div>
                                                            <div id="top"><img src="./assets/images/village.png"></div>
                                                            <div id="bottom"></div>
                                                        </div>
                                                        <p class="text-theme">{{'property-valuation.Ward' | translate}}
                                                        </p>
                                                        <h3 class="mb-0 pb-0">
                                                            {{lan==0?selectedMstDemographyBean?.location?.ward?.demographyNameEn:selectedMstDemographyBean?.location?.ward?.demographyNameHi}}
                                                        </h3>
                                                    </div>
                                                </div>


                                                <div *ngIf="selectedMstDemographyBean?.location?.village?.demographyName!=null&&selectedMstDemographyBean?.location?.village?.demographyName!=''"
                                                    class="new-design-box p-0 ">
                                                    <div class="single-block">
                                                        <div id="bg">
                                                            <div id="bg-bottom"></div>
                                                            <div id="top"><img src="./assets/images/village.png"></div>
                                                            <div id="bottom"></div>
                                                        </div>
                                                        <p class="text-theme">{{'property-valuation.Village' |
                                                            translate}}</p>
                                                        <h3 class="mb-0 pb-0">
                                                            {{lan==0?selectedMstDemographyBean?.location?.village?.demographyNameEn:selectedMstDemographyBean?.location?.village?.demographyNameHi}}


                                                        </h3>
                                                    </div>
                                                </div>
                                                <div *ngIf="selectedMstDemographyBean?.location?.village_colony?.demographyName!=null&&selectedMstDemographyBean?.location?.village_colony?.demographyName!=''"
                                                    class="new-design-box p-0 ">
                                                    <div class="single-block">
                                                        <div id="bg">
                                                            <div id="bg-bottom"></div>
                                                            <div id="top"><img src="./assets/images/guidline.png"></div>
                                                            <div id="bottom"></div>
                                                        </div>
                                                        <p class="text-theme">{{'property-valuation.village_colony' |
                                                            translate}}</p>
                                                        <h3 class="mb-0 pb-0 lh-13 truncate">
                                                            {{lan==0?selectedMstDemographyBean?.location?.village_colony?.demographyNameEn:selectedMstDemographyBean?.location?.village_colony?.demographyNameHi}}
                                                            {{selectedMstDemographyBean?.upBandhDesc}}
                                                        </h3>
                                                        <div class="d-flex">
                                                            <p class="text-theme">
                                                                {{lan==0?selectedMstDemographyBean?.ulbTypeNmEn:selectedMstDemographyBean?.ulbTypeNmHi}}
                                                            </p>

                                                        </div>
                                                        <div>
                                                            <p class="text-theme">
                                                                {{lan==0?selectedMstDemographyBean?.isPlanningAreaNmEn:selectedMstDemographyBean?.isPlanningAreaNmHi}}
                                                            </p>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="selectedMstDemographyBean?.location?.ward_colony?.demographyName!=null&&selectedMstDemographyBean?.location?.ward_colony?.demographyName!=''"
                                                    class="new-design-box p-0 ">
                                                    <div class="single-block">
                                                        <div id="bg">
                                                            <div id="bg-bottom"></div>
                                                            <div id="top"><img src="./assets/images/guidline.png"></div>
                                                            <div id="bottom"></div>
                                                        </div>
                                                        <p class="text-theme">{{'property-valuation.ward_colony' |
                                                            translate}}</p>
                                                        <h3 class="mb-0 pb-0 lh-13 truncate">
                                                            {{lan==0?selectedMstDemographyBean?.location?.ward_colony?.demographyNameEn:selectedMstDemographyBean?.location?.ward_colony?.demographyNameHi}}
                                                            {{selectedMstDemographyBean?.upBandhDesc}}
                                                        </h3>
                                                        <div>
                                                            <span class="text-theme">
                                                                {{lan==0?selectedMstDemographyBean?.ulbTypeNmEn:selectedMstDemographyBean?.ulbTypeNmHi}}</span>

                                                        </div>
                                                        <div>
                                                            <p class="text-theme">
                                                                {{lan==0?selectedMstDemographyBean?.isPlanningAreaNmEn:selectedMstDemographyBean?.isPlanningAreaNmHi}}
                                                            </p>

                                                        </div>
                                                    </div>
                                                </div>




                                                <!--SELECT DEMOGRAPHY HIDE/SHOW-->
                                                <div class="new-design-box p-0 "
                                                    *ngIf="!hideDropdownForMultipleDemography">
                                                    <div class="single-block">
                                                        <div id="bg">
                                                            <div id="bg-bottom"></div>
                                                            <div id="top"><img src="./assets/images/village.png"></div>
                                                            <div id="bottom"></div>
                                                        </div>
                                                        <p class="text-theme">{{'property-valuation.select' |
                                                            translate}}</p>
                                                        <select class="input-boxselect form-control h-50 p-0"
                                                            name="selectedDemography"
                                                            [disabled]="this.propertyValuationRequestBean.isConstOnAgriLand"
                                                            [(ngModel)]="propertyValuationRequestBean.demography"
                                                            palaceholder="select demography"
                                                            (ngModelChange)="selectDemographyByDropDown($event, drawer)">
                                                            <option [value]="propertyValuationRequestBean.demography">
                                                                {{'property-valuation.Select-your-demography' |
                                                                translate}}</option>
                                                            <option class="option-width"
                                                                *ngFor="let demography of mstDemographyBeanList"
                                                                [ngValue]="demography">
                                                                {{lan==0?demography.demographyNameEn:demography.demographyNameHi}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>


                                                <div class="new-design-box p-0 ">
                                                    <div class="single-block badge-secondary">
                                                        <div id="bg">
                                                            <div id="bg-bottom"></div>
                                                            <div id="top" data-toggle="modal"
                                                                data-target="#ViewGuidelineRates">
                                                                <mat-icon
                                                                    class="line-ht27 text-light">remove_red_eye</mat-icon>
                                                            </div>
                                                            <div id="bottom"></div>
                                                        </div>


                                                        <div><a class="click-btn btn-style507 text-light"
                                                                data-toggle="modal" data-target="#ViewGuidelineRates">
                                                                {{'guideline.guideline' |
                                                                translate}}</a>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="new-design-box p-0 "
                                                    *ngIf="isWebGisData || isEnagarPalika||isPnrd">
                                                    <div class="single-block badge-secondary">
                                                        <div id="bg">
                                                            <div id="bg-bottom"></div>
                                                            <div id="top"
                                                                (click)="openverificationMobileDialog(ViewPropertyDetail)">
                                                                <mat-icon
                                                                    class="line-ht27 text-light">remove_red_eye</mat-icon>
                                                            </div>
                                                            <div id="bottom"></div>
                                                        </div>


                                                        <div><a class="click-btn btn-style507 text-light"
                                                                (click)="openverificationMobileDialog(ViewPropertyDetail)">
                                                                {{'valuationproperty.Property' | translate}}</a>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>


                                <div class="d-print-none h-100" [ngClass]=" flag ? 'col-md-12' : 'col-md-9'">
                                    <div class="pv-mapview ">
                                        <app-map (MapLoadEvent)="onLoadMap($event)"></app-map>
                                        <app-measure-tool *ngIf="showToolsMeasureTool" [map]="map"></app-measure-tool>



                                        <div id="k-dragablediv"
                                            *ngIf="agriPropertyValuationRequestBean?.webGisResponseBeanDataArr?.length>0 || prndData || mpenagarpalikamodel?.propertyOwner?.length>0">
                                            <div id="k-tableFixHead">
                                                <table class="table table-bordered m-0"
                                                    *ngIf="agriPropertyValuationRequestBean?.webGisResponseBeanDataArr?.length>0">
                                                    <thead class="thead-dark">
                                                        <tr>
                                                            <th scope="col">{{'property-valuation.Land-Parecel-Id' |
                                                                translate}}</th>
                                                            <th scope="col">{{'property-valuation.ULPIN-id' |
                                                                translate}} </th>
                                                            <th scope="col">{{'ereg-property-details.khasraNo' |
                                                                translate}}</th>
                                                            <th scope="col">{{'ereg-property-details.action' |
                                                                translate}}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <ng-container
                                                            *ngFor="let row of agriPropertyValuationRequestBean.webGisResponseBeanDataArr; let i = index">
                                                            <tr *ngIf="row.latitude && row.longitude">
                                                                <td>{{ row.landParcelId }}</td>
                                                                <td>{{ row.ULPin }}</td>
                                                                <td>{{ row.surveyNo }}</td>
                                                                <td>
                                                                    <button type="button"
                                                                        class="btn btn-primary btn-sm "
                                                                        aria-label="Close"
                                                                        (click)="removeAddedKhasra(row, i)">
                                                                        <mat-icon class="ionn">delete_outline</mat-icon>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </ng-container>
                                                    </tbody>
                                                </table>
                                                <table class="table table-bordered m-0" *ngIf="prndData">
                                                    <thead class="thead-dark">
                                                        <tr>
                                                            <th scope="col">{{'property-valuation.Panchayat-ID' |
                                                                translate}}</th>
                                                            <th scope="col">{{'ereg-property-details.khasraNo' |
                                                                translate}}</th>
                                                            <th scope="col">
                                                                {{'property-identificationValuation.property_Address' |
                                                                translate}} </th>
                                                            <th scope="col">{{'ereg-property-details.action' |
                                                                translate}}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngIf="prndData.latitude && prndData.longitude">
                                                            <td>{{ prndData.propertyId }}</td>
                                                            <td>{{ prndData.khasraNo }}</td>
                                                            <td>{{ prndData.property_Address }}</td>
                                                            <td>
                                                                <button type="button" class="btn btn-primary btn-sm "
                                                                    aria-label="Close"
                                                                    (click)="removeAddedPunchayatProperty()">
                                                                    <mat-icon class="ionn">delete_outline</mat-icon>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table class="table table-bordered m-0"
                                                    *ngIf="mpenagarpalikamodel?.propertyOwner.length>0">
                                                    <thead class="thead-dark">
                                                        <tr>
                                                            <th scope="col">{{'property-valuation.Property-ID' |
                                                                translate}}</th>
                                                            <th scope="col">{{ "property-valuation.Owner" | translate }}
                                                            </th>
                                                            <th scope="col">{{'ereg-property-details.action' |
                                                                translate}}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            *ngIf="mpenagarpalikamodel.latitude && mpenagarpalikamodel.longitude">
                                                            <td>{{ mpenagarpalikamodel.propertyNo }}</td>
                                                            <td>{{ mpenagarpalikamodel.name }}</td>
                                                            <td>
                                                                <button type="button" class="btn btn-primary btn-sm "
                                                                    aria-label="Close" (click)="removeAddedProperty()">
                                                                    <mat-icon class="ionn">delete_outline</mat-icon>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <p class="ali-typgrap"><b>{{'ereg-property-details.note' | translate}} </b>
                                                : {{'property-valuation.dragDropPin' | translate}}</p>

                                        </div>


                                    </div>

                                </div>
                            </div>
                            <div class="clear"></div>


                        </div>




                    </mat-drawer-content>
                </mat-drawer-container>


            </div>



        </div>

    </div>
    <div cdkDrag>


    </div>
    <!--DEMOGRAPHY DETAIL && GUIDELINE-->
    <div class="modal right  fade leftbox_detail new_width" id="ViewGuidelineRates" data-backdrop="false"
        data-keyboard="false" tabindex="-1" aria-labelledby="ViewGuidelineRates" aria-hidden="true">

        <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content loginoptionsmodal d-block">
                <div class="modal-header bg-theme p-0">
                    <h3 class="text-light p-1 mb-1 font-weight-normal fixed-top1">
                        {{'property-valuation.GuildeLine-Rate-Details' | translate}} {{guildeLineObj?.financialYear}}
                        <button type="button" class="close closemapbtn" data-dismiss="modal" aria-label="Close"
                            (click)="popucClose()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </h3>
                </div>

                <div class="modal-body p-2">
                    <div class="row">
                        <div class="col-md-12">
                            <fieldset class="legend-section">
                                <legend class="sidebar__widget-title">
                                    {{'property-valuation.ward_colony' | translate}}
                                </legend>
                                <div class="table-responsive shadow-none">
                                    <table class="table table-bordered">
                                        <tr>
                                            <th>{{'property-valuation.District' |
                                                translate}}</th>
                                            <th>

                                                {{'property-valuation.Area-Type' |
                                                translate}}

                                            </th>
                                            <th
                                                *ngIf="selectedMstDemographyBean?.location?.tehsil?.demographyName!=null&&selectedMstDemographyBean?.location?.tehsil?.demographyName!=''">
                                                {{'property-valuation.Tehsil' |
                                                translate}}
                                            </th>
                                            <th
                                                *ngIf="selectedMstDemographyBean?.location?.localBody?.demographyName!=null&&selectedMstDemographyBean?.location?.localBody?.demographyName!=''">
                                                {{'property-valuation.localbody' |
                                                translate}}
                                            </th>
                                            <th
                                                *ngIf="selectedMstDemographyBean?.location?.ward?.demographyName!=null&&selectedMstDemographyBean?.location?.ward?.demographyName!=''">
                                                {{'property-valuation.Ward' | translate}}
                                            </th>
                                            <th
                                                *ngIf="selectedMstDemographyBean?.location?.village?.demographyName!=null&&selectedMstDemographyBean?.location?.village?.demographyName!=''">
                                                {{'property-valuation.Village' |
                                                translate}}
                                            </th>
                                            <th
                                                *ngIf="selectedMstDemographyBean?.location?.village_colony?.demographyName!=null&&selectedMstDemographyBean?.location?.village_colony?.demographyName!=''">
                                                {{'property-valuation.village_colony' |
                                                translate}}
                                            </th>

                                            <th
                                                *ngIf="selectedMstDemographyBean?.location?.ward_colony?.demographyName!=null&&selectedMstDemographyBean?.location?.ward_colony?.demographyName!=''">
                                                {{'property-valuation.ward_colony' |
                                                translate}}
                                            </th>


                                        </tr>

                                        <tr>
                                            <td>{{lan==0?selectedMstDemographyBean?.location?.district?.demographyNameEn:selectedMstDemographyBean?.location?.district?.demographyNameHi}}
                                            </td>
                                            <td>
                                                <ng-container
                                                    *ngIf="selectedMstDemographyBean?.demographyType?.demographyAreaType=='R'">
                                                    {{'property-valuation.Rural' | translate}}
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="selectedMstDemographyBean?.demographyType?.demographyAreaType=='U'">
                                                    {{'property-valuation.Urban' | translate}}
                                                </ng-container>

                                            </td>
                                            <td
                                                *ngIf="selectedMstDemographyBean?.location?.tehsil?.demographyName!=null&&selectedMstDemographyBean?.location?.tehsil?.demographyName!=''">
                                                {{lan==0?selectedMstDemographyBean?.location?.tehsil?.demographyNameEn:selectedMstDemographyBean?.location?.tehsil?.demographyNameHi}}
                                            </td>
                                            <td
                                                *ngIf="selectedMstDemographyBean?.location?.localBody?.demographyName!=null&&selectedMstDemographyBean?.location?.localBody?.demographyName!=''">
                                                {{lan==0?selectedMstDemographyBean?.location?.localBody?.demographyNameEn:selectedMstDemographyBean?.location?.localBody?.demographyNameHi}}
                                            </td>


                                            <td
                                                *ngIf="selectedMstDemographyBean?.location?.ward?.demographyName!=null&&selectedMstDemographyBean?.location?.ward?.demographyName!=''">
                                                {{lan==0?selectedMstDemographyBean?.location?.ward?.demographyNameEn:selectedMstDemographyBean?.location?.ward?.demographyNameHi}}
                                            </td>


                                            <td
                                                *ngIf="selectedMstDemographyBean?.location?.village?.demographyName!=null&&selectedMstDemographyBean?.location?.village?.demographyName!=''">
                                                {{lan==0?selectedMstDemographyBean?.location?.village?.demographyNameEn:selectedMstDemographyBean?.location?.village?.demographyNameHi}}
                                            </td>

                                            <td
                                                *ngIf="selectedMstDemographyBean?.location?.village_colony?.demographyName!=null&&selectedMstDemographyBean?.location?.village_colony?.demographyName!=''">
                                                {{lan==0?selectedMstDemographyBean?.location?.village_colony?.demographyNameEn:selectedMstDemographyBean?.location?.village_colony?.demographyNameHi}}



                                                <ng-container class="text-theme">
                                                    {{lan==0?selectedMstDemographyBean?.ulbTypeNmEn:selectedMstDemographyBean?.ulbTypeNmHi}}
                                                </ng-container>
                                                <ng-container class="text-theme">
                                                    {{lan==0?selectedMstDemographyBean?.isPlanningAreaNmEn:selectedMstDemographyBean?.isPlanningAreaNmHi}}
                                                </ng-container>

                                            </td>
                                            <td
                                                *ngIf="selectedMstDemographyBean?.location?.ward_colony?.demographyName!=null&&selectedMstDemographyBean?.location?.ward_colony?.demographyName!=''">
                                                {{lan==0?selectedMstDemographyBean?.location?.ward_colony?.demographyNameEn:selectedMstDemographyBean?.location?.ward_colony?.demographyNameHi}}

                                                <ng-container *ngIf="selectedMstDemographyBean.ulbType>0">
                                                    {{lan==0?selectedMstDemographyBean?.ulbTypeNmEn:selectedMstDemographyBean?.ulbTypeNmHi}}
                                                </ng-container>

                                                <ng-container *ngIf="selectedMstDemographyBean.isPlanningArea>0">
                                                    {{lan==0?selectedMstDemographyBean?.isPlanningAreaNmEn:selectedMstDemographyBean?.isPlanningAreaNmHi}}
                                                </ng-container>

                                            </td>



                                        </tr>




                                    </table>
                                </div>
                            </fieldset>
                        </div>
                        <!--GUIDELINE DETAILS-->
                        <ng-container *ngIf="guildeLineObj else otherBlock">
                            <div class="col-md-6">
                                <fieldset class="legend-section">
                                    <legend class="sidebar__widget-title">
                                        {{'guideline.plot' | translate}} {{'guideline.sqm' |
                                        translate}}
                                        <span class="text-right fs1" *ngIf="guildeLineObj?.isVishistGram!=0">
                                            {{'property-valuation.is-Vishist-Gram' |translate}}{{'guideline.sqm' |
                                            translate}}

                                            <i class="fa fa-check" aria-hidden="true"></i>
                                        </span>
                                    </legend>
                                    <div class="table-responsive shadow-none">
                                        <table class="table table-bordered">
                                            <tr>
                                                <th>{{'guideline.resi' | translate}}</th>
                                                <th>{{'guideline.comm' | translate}}</th>
                                                <th>{{'guideline.ind' | translate}}</th>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;{{guildeLineObj?.ratePlotResidential |amount}}</td>
                                                <td>&nbsp;{{guildeLineObj?.ratePlotCommercial|amount}}</td>
                                                <td>&nbsp;{{guildeLineObj?.ratePlotIndustrial |amount}}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </fieldset>
                            </div>
                            <div class="col-md-6">
                                <fieldset class="legend-section">
                                    <legend class="sidebar__widget-title">
                                        {{'guideline.buil-resi' | translate}} {{'guideline.sqm' |
                                        translate}}
                                    </legend>
                                    <div class=" table-responsive shadow-none">
                                        <table class="table table-bordered">
                                            <tr>
                                                <th>{{'guideline.rcc' | translate}} </th>
                                                <th>{{'guideline.rbc' | translate}} </th>
                                                <th> {{'guideline.tin_shade' | translate}}</th>
                                                <th> {{'guideline.kaccha_abelu' | translate}}</th>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;{{guildeLineObj?.rateBuildingRcc |amount}}</td>
                                                <td>&nbsp;{{guildeLineObj?.rateBuildingRbc |amount}}</td>
                                                <td>&nbsp;{{guildeLineObj?.rateBuildingTShade|amount}}</td>
                                                <td>&nbsp;{{guildeLineObj?.rateBuildingKabelu|amount}}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </fieldset>
                            </div>
                            <div class="col-md-6">
                                <fieldset class="legend-section">
                                    <legend class="sidebar__widget-title">
                                        {{'guideline.buil-comm' | translate}} {{'guideline.sqm' | translate}}
                                    </legend>
                                    <div class=" table-responsive shadow-none">
                                        <table class="table table-bordered">
                                            <tr>
                                                <th> {{'guideline.Shop' | translate}}</th>
                                                <th> {{'guideline.office' | translate}}</th>
                                                <th>{{'guideline.godown' | translate}}</th>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;{{guildeLineObj?.rateBuildingShop|amount}}</td>
                                                <td>&nbsp;{{guildeLineObj?.rateBuildingOffice|amount}}</td>
                                                <td>&nbsp;{{guildeLineObj?.rateBuildingGodown|amount}}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </fieldset>
                            </div>
                            <div class="col-md-6">
                                <fieldset class="legend-section">
                                    <legend class="sidebar__widget-title">
                                        {{'guideline.buil-multi' | translate}} {{'guideline.sqm' |
                                        translate}}
                                    </legend>
                                    <div class=" table-responsive shadow-none">
                                        <table class="table table-bordered">
                                            <tr>
                                                <th>{{'guideline.resi' | translate}}</th>
                                                <th>{{'guideline.comm' | translate}}</th>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;{{guildeLineObj?.rateBuildingMultiResi|amount}}</td>
                                                <td>&nbsp;{{guildeLineObj?.rateBuildingMultiComm |amount}}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </fieldset>
                            </div>
                            <div class="col-md-6">
                                <fieldset class="legend-section">
                                    <legend class="sidebar__widget-title">
                                        {{'guideline.agri-land' | translate}} {{'guideline.hectare' |
                                        translate}}
                                    </legend>
                                    <div class=" table-responsive shadow-none">
                                        <table class="table table-bordered">
                                            <tr>
                                                <th> {{'guideline.irgd' | translate}}</th>
                                                <th>{{'guideline.unirgd' | translate}}</th>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;{{guildeLineObj?.rateAgriIrregated |amount}}</td>
                                                <td>&nbsp;{{guildeLineObj?.rateAgriUnirregated |amount}}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </fieldset>
                            </div>
                            <div class="col-md-6">
                                <fieldset class="legend-section">
                                    <legend class="sidebar__widget-title">
                                        {{'guideline.agri-plot' | translate}} {{'guideline.sqm' |
                                        translate}}
                                    </legend>
                                    <div class="table-responsive shadow-none">
                                        <table class="table table-bordered">
                                            <tr>
                                                <th>{{'guideline.sub-clause-resi' | translate}}</th>
                                                <th>{{'guideline.sub-clause-comm' | translate}}</th>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;{{guildeLineObj?.rateLandUptoResi |amount}}</td>
                                                <td>&nbsp;{{guildeLineObj?.rateLandUptoComm |amount}}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </fieldset>
                            </div>
                        </ng-container>
                        <ng-template #otherBlock>
                            <!-- Content to render if condition is false -->
                            <div class="col-md-12 text-center">
                                <b> {{'No-data-found' | translate}}</b>
                            </div>
                        </ng-template>
                    </div>

                </div>
            </div>
        </div><!-- <button>View Guideline Rates</button> -->
        <!-- <button mat-raised-button (click)="openDialog()">Pick one</button> -->

    </div>


    <ng-template #ViewPropertyDetail let-c="close" let-d="dismiss">

        <h2 class="Consenterdialog-title"> {{'property-valuation.Property-Details'| translate}}</h2>
        <button type="button" class="colsebtn" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>

        <div class="modal-body">

            <div class="row" *ngIf="isWebGisData">
                <div class="col-md-12">

                    <!-------------property DETAIL WEBGIS-------------->
                    <fieldset class="legend-section"
                        *ngFor="let webGis of  agriPropertyValuationRequestBean.webGisResponseBeanDataArr; index as i">
                        <legend class="sidebar__widget-title">
                            {{'valuationproperty.Property' | translate}} - {{i+1}}
                        </legend>
                        <div class="innner-box">
                            <fieldset class="legend-section">
                                <legend class="sidebar__widget-title">
                                    {{'property-valuation.Property-Details'| translate}}
                                </legend>
                                <div class="info-list">
                                    <ul class="p-0">
                                        <li>
                                            <strong>
                                                <span>
                                                    {{'property-valuation.Land-Parcel' | translate}}: </span>
                                            </strong>
                                            <span>
                                                {{webGis.landParcelId}}
                                            </span>
                                        </li>
                                        <li>
                                            <strong>
                                                <span>
                                                    {{'property-valuation.ULPIN' | translate}}:</span>
                                            </strong>
                                            <span>
                                                {{webGis.ULPin}}
                                            </span>
                                        </li>
                                        <li>
                                            <strong>
                                                <ng-container *ngIf="webGis.khasraType=='P' else span">
                                                    <span>
                                                        {{lan==0?'Plot No.':'प्लॉट नंबर'}}
                                                    </span>
                                                </ng-container>
                                                <ng-template #span>
                                                    {{lan==0?'Khasra No.':'खसरा नंबर'}}
                                                </ng-template>
                                            </strong>
                                            <span>
                                                {{webGis.surveyNo}} </span>
                                        </li>

                                        <li>
                                            <strong>
                                                <span>
                                                    {{'property-valuation.Is-Irrigated' | translate}}: </span>
                                            </strong>
                                            <span> {{webGis.isIrrigated}}
                                            </span>
                                        </li>
                                        <li>
                                            <strong>
                                                <span>
                                                    {{'property-valuation.Total-Area1' | translate}}: </span>
                                            </strong>
                                            <span> {{webGis.totalArea}}
                                            </span>
                                        </li>
                                        <li>
                                            <strong>
                                                <span>
                                                    {{'property-valuation.Unit' | translate}}: </span>
                                            </strong>
                                            <span>{{webGis.unit}}
                                            </span>
                                        </li>
                                        <li>
                                            <strong>
                                                <span>
                                                    {{'property-valuation.Usage' | translate}}: </span>
                                            </strong>
                                            <span>
                                                {{webGis.usage=='diverted'?lan==0?'diverted':'व्यपवर्तित':lan==0?'undiverted':'अव्यपवर्तित'}}
                                            </span>
                                        </li>

                                        <li>
                                            <strong>
                                                <span>
                                                    {{'property-valuation.khasraType' | translate}}: </span>
                                            </strong>
                                            <span> {{webGis.khasraType}}
                                            </span>
                                        </li>
                                        <li>
                                            <strong>
                                                <span>
                                                    {{'property-valuation.Land-Use-Type' | translate}}: </span>
                                            </strong>
                                            <span> {{webGis.landUseType}}
                                            </span>
                                        </li>

                                        <li>
                                            <strong>
                                                <span>
                                                    {{'property-valuation.Land-Type-Name:' | translate}} </span>
                                            </strong>
                                            <span> {{webGis.landTypeName}}
                                            </span>
                                        </li>


                                    </ul>
                                    <div class="w-remark">
                                        <strong>
                                            <span>
                                                {{'e-stamp.Remarks' | translate}}
                                            </span>
                                        </strong>
                                        <span> {{webGis.remarks}}
                                        </span>
                                    </div>
                                    <div class="w-remark">
                                        <strong>
                                            <span>
                                                {{"e-registry-completion.mortageRemarks" | translate}}
                                            </span>
                                        </strong>
                                        <span> {{webGis.mortageRemarks}}
                                        </span>
                                    </div>
                                </div>
                            </fieldset>
                            <!-------------OWNER  DETAILS WEBGIS-------------->
                            <fieldset class="legend-section">
                                <legend class="sidebar__widget-title">
                                    {{ "property-valuation.Owner-Details" | translate }}
                                </legend>
                                <div class="table table-responsive shadow-none">
                                    <table class="table table-bordered">
                                        <thead class="thead-dark">
                                            <tr>
                                                <th>{{ "no" | translate }}</th>
                                                <th>
                                                    {{ "property-valuation.Owner" | translate }}{{
                                                    "property-valuation.Name" | translate }}
                                                </th>

                                                <th>
                                                    {{
                                                    "property-valuation.Father-Name" | translate
                                                    }}
                                                </th>
                                                <th>
                                                    {{ "address" | translate }}
                                                </th>
                                                <th>
                                                    {{
                                                    "property-valuation.Owner-Caste" | translate
                                                    }}
                                                </th>
                                                <th>
                                                    {{
                                                    "property-valuation.OwnerShip-Type" | translate
                                                    }}
                                                </th>
                                                <th>
                                                    {{
                                                    "property-valuation.Owner-Share" | translate
                                                    }}
                                                </th>

                                            </tr>

                                        </thead>
                                        <tbody *ngFor="let owner of webGis.propertyOwner; index as i">
                                            <td>{{i+1}}</td>
                                            <td>{{ owner?.ownerName }}</td>

                                            <td> {{owner?.fatherName}}</td>
                                            <td>{{owner?.address}}</td>
                                            <td>{{owner?.ownerCaste}}</td>
                                            <td> {{owner?.ownershiptype}}</td>
                                            <td>{{ owner?.ownerShare}}
                                            </td>
                                        </tbody>
                                    </table>
                                </div>
                            </fieldset>
                        </div>
                    </fieldset>


                    <!--Property  Detail ENAGARPALIKA -->

                </div>
            </div>
            <div class="col-md-12" *ngIf="isEnagarPalika&&mpenagarpalikamodel!=null">
                <fieldset class="legend-section">
                    <legend class="sidebar__widget-title">
                        {{'property-valuation.Property-Details'| translate}}
                    </legend>
                    <div class="info-list">
                        <ul class="p-0">
                            <li>
                                <strong>
                                    <span> {{'property-valuation.Total-Area1' | translate}}: </span>
                                </strong>
                                <span>{{mpenagarpalikamodel.totalArea}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'property-valuation.area-type' | translate}}:</span>
                                </strong>
                                <span> {{mpenagarpalikamodel.areaType}} </span>
                            </li>

                        </ul>

                    </div>
                </fieldset>
            </div>
            <!--Property  Detail PNRD -->
            <div class="col-md-12" *ngIf="isPnrd">
                <fieldset class="legend-section">
                    <legend class="sidebar__widget-title">
                        {{'property-valuation.Property-Details'| translate}}
                    </legend>
                    <div class="info-list">
                        <ul class="p-0">
                            <li>
                                <strong>
                                    <span> {{'property-valuation.Total-Area1' | translate}}: </span>
                                </strong>
                                <span>{{prndData.totalArea}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'property-valuation.area-type' | translate}}:</span>
                                </strong>
                                <span> {{prndData.AreaType}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'valuationproperty.UniquePropertyID' | translate}}:</span>
                                </strong>
                                <span> {{prndData.unique_Property_ID}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'valuationproperty.PropertyID' | translate}}:</span>
                                </strong>
                                <span> {{prndData.propertyId}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'valuationproperty.PropertyTypeID' | translate}}:</span>
                                </strong>
                                <span> {{prndData.propertyTypeId}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'valuationproperty.ApplicationID' | translate}}:</span>
                                </strong>
                                <span> {{prndData.applicationNo}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'valuationproperty.HouseNo' | translate}}:</span>
                                </strong>
                                <span> {{prndData.houseNo}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'valuationproperty.DistrictName' | translate}}:</span>
                                </strong>
                                <span> {{prndData.districtName}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'valuationproperty.localBodyNo' | translate}}:</span>
                                </strong>
                                <span> {{prndData.localBody}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'valuationproperty.NoOfFloor' | translate}}:</span>
                                </strong>
                                <span> {{prndData.noOfFloor}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'valuationproperty.gramPanchayat' | translate}} :</span>
                                </strong>
                                <span> {{prndData.gramPanchayat}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'valuationproperty.pincode' | translate}}:</span>
                                </strong>
                                <span> {{prndData.pincode}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'valuationproperty.wardName' | translate}} :</span>
                                </strong>
                                <span> {{prndData.wardName}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'valuationproperty.wardNo' | translate}}:</span>
                                </strong>
                                <span> {{prndData.wardNo}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'valuationproperty.wardName' | translate}} :</span>
                                </strong>
                                <span> {{prndData.wardName}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'valuationproperty.wardNo' | translate}}:</span>
                                </strong>
                                <span> {{prndData.wardNo}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'valuationproperty.village' | translate}} :</span>
                                </strong>
                                <span> {{prndData.village}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'valuationproperty.villageCode' | translate}}:</span>
                                </strong>
                                <span> {{prndData.villageCode}} </span>
                            </li>


                            <li>
                                <strong>
                                    <span>{{'valuationproperty.property_Address' | translate}}:</span>
                                </strong>
                                <span> {{prndData.property_Address}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'valuationproperty.propertyStatus' | translate}} :</span>
                                </strong>
                                <span> {{prndData.propertyStatus}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'valuationproperty.propertyValue' | translate}}:</span>
                                </strong>
                                <span> {{prndData.propertyValue}} </span>
                            </li>

                            <li>
                                <strong>
                                    <span>{{'valuationproperty.eKYCDate' | translate}} :</span>
                                </strong>
                                <span> {{prndData.eKYCDate}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'valuationproperty.eKYCStatus' | translate}}:</span>
                                </strong>
                                <span> {{prndData.eKYCStatus}} </span>
                            </li>

                        </ul>

                    </div>
                </fieldset>
            </div>
            <!--Owner Detail PNRD && ENAGARPALIKA-->
            <div class="col-md-12" *ngIf="isEnagarPalika||isPnrd">
                <fieldset class="legend-section">
                    <legend class="sidebar__widget-title">
                        {{ "property-valuation.Owner-Details" | translate }}

                    </legend>

                    <ul class="p-0" *ngFor="let owner of propertyOwner; index as i">
                        <li>
                            <strong><span> {{ "property-valuation.Owner" | translate }}</span> </strong>
                            <span>
                                {{ owner?.ownerName }}
                            </span>
                        </li>
                        <li>
                            <strong> <span>
                                    {{ "property-valuation.Name" | translate }}
                                </span> </strong>
                            <span>
                                {{ owner?.ownerName }}
                            </span>
                        </li>
                        <li>
                            <strong> <span>
                                    {{
                                    "property-valuation.Father-Name" | translate
                                    }}
                                </span> </strong>
                            <span>
                                {{owner?.fatherName}}
                            </span>
                        </li>
                        <li>
                            <strong> <span>
                                    {{ "address" | translate }}
                                </span> </strong>
                            <span>
                                {{
                                owner?.address
                                }}
                            </span>
                        </li>
                        <li>
                            <strong> <span>
                                    {{
                                    "property-valuation.Owner-Caste" | translate
                                    }}

                                </span> </strong>
                            <span>
                                {{
                                owner?.ownerCaste
                                }}
                            </span>
                        </li>
                        <li>
                            <strong> <span>
                                    {{
                                    "property-valuation.OwnerShip-Type" | translate
                                    }}
                                </span> </strong>
                            <span>
                                {{
                                owner?.ownershiptype
                                }}
                            </span>
                        </li>
                        <li>
                            <strong> <span>
                                    {{
                                    "property-valuation.Owner-Share" | translate
                                    }}
                                </span> </strong>
                            <span>
                                {{
                                owner?.ownerShare
                                }}
                            </span>
                        </li>

                    </ul>

                </fieldset>



            </div>

        </div>
    </ng-template>

    <!--UADD POP MODEL-->
    <ng-template #propertySelectionModal>

        <button mat-button matDialogClose color="primary" class="colsebtn" (click)="closeModal2()">
            <em class="fa fa-times"></em></button>

        <h2 matDialogTitle class="Consenterdialog-title">
            {{'valuationproperty.SelectYourProperty' | translate}}</h2>
        <mat-dialog-content class="otpverification">

            <fieldset class="legend-section">


                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group">
                            <label> {{'valuationproperty.SelectYourProperty' | translate}} </label>
                            <select class="form-control" name="uaddpropId" [(ngModel)]="uaddpropId"
                                (ngModelChange)="getUaddObjectByEnpId($event)">
                                <option value="1" disabled>{{'valuationproperty.selectpropertyDetails' | translate}}
                                </option>
                                <option *ngFor="let uadd of uaddResponseModel" [value]="uadd.enp_prop_id"
                                    [disabled]="uadd.enp_prop_id==null">
                                    {{uadd.enp_prop_id}},{{uadd.own_nm_e}},{{uadd.ward_nm}}</option>

                            </select>
                        </div>
                    </div>
                    <div class="col-lg-12 text-right">
                        <!-- <button class="btn btn-primary">Submit</button> -->
                    </div>
                </div>


            </fieldset>
        </mat-dialog-content>
    </ng-template>
    <!--CLR POP MODEL-->
    <ng-template #propertyClrModal>

        <button mat-button matDialogClose color="primary" class="colsebtn" (click)="closeModal2()">
            <em class="fa fa-times"></em></button>

        <h2 matDialogTitle class="Consenterdialog-title">
            {{'valuationproperty.SelectYourLandParcelId' | translate}}
        </h2>
        <mat-dialog-content class="otpverification">

            <fieldset class="legend-section">


                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group">
                            <label> {{'valuationproperty.SelectYourProperty' | translate}} </label>
                            <select class="form-control" name="clrPropId" [(ngModel)]="clrPropId"
                                (ngModelChange)="getClrObjectBylandParcelId($event)">
                                <option value="1" disabled>{{'valuationproperty.selectpropertyDetails' | translate}}
                                </option>
                                <option *ngFor="let clr of khasraDetailsDataList" [value]="clr.landParcelId"
                                    [disabled]="clr.landParcelId==null">
                                    {{clr.landParcelId}}</option>

                            </select>
                        </div>
                    </div>
                    <div class="col-lg-12 text-right">

                    </div>
                </div>


            </fieldset>
        </mat-dialog-content>
    </ng-template>


    <!-- <a (click)="openParcelAsBuildingDialog(parcelAsBuilding)">Modal Open</a>
    <ng-template #parcelAsBuilding let-c="close" let-d="dismiss">

        <h2 class="Consenterdialog-title"> parcel As Building</h2>
        <button type="button" class="colsebtn" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>

        <div class="modal-body">
            <div>
                <div class="form-check-inline">
                    <label class="form-check-label">
                        <input type="radio" class="form-check-input" name="optradio">Option 1
                    </label>
                </div>
            </div>
        </div>
    </ng-template> -->


    <ngx-ui-loader [loaderId]="'pv-01'"></ngx-ui-loader>
    <ngx-ui-loader [loaderId]="'loader-propvalCommon'"></ngx-ui-loader>