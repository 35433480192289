import { Component, AfterViewInit, EventEmitter, Output, Input, ViewEncapsulation, ViewChild, ElementRef, OnInit } from '@angular/core';
//import { Loader } from '@googlemaps/js-api-loader';
import * as L from 'leaflet';
//import 'leaflet.vectorgrid'
//import * as turf from '@turf/turf';
//import Swal from 'sweetalert2';
import 'src/assets/ts/leaflet-panel-layers';
import 'src/assets/js/LayerPannel.js';
import 'src/assets/js/L.Control.LayerListbtn.js';
import 'src/assets/js/Leaflet.Coordinates.js';
import 'src/assets/js/L.Control.FullScreen.js';
//import 'leaflet.gridlayer.googlemutant';
import * as LayerConfig from 'src/config/layerConfig.json'
import * as Layerlist from 'src/config/layerList.json';
// import * as Layerlist1 from 'src/config/arealayerList.json';
// import * as Layerlist2 from 'src/config/analyticlayerList.json';
import * as BaseMaps from 'src/config/basemaps.json';
//import * as mpBoundary from 'src/config/mpstate.json';
import { AppSetting, AppSettingService } from 'projects/common-lib/src/lib/helper-lib/app.setting';
//import { PropertyValuationService } from 'src/app/front-end/modules/property-valuation/property-valuation.service';
import { AlertmessageComponent } from '../alertmessage/alertmessage.component';
import { ActivatedRoute, Router } from '@angular/router';
import { OfficeList } from '../../models/api.masterdata.model/office-list';
import { GuidelineService } from 'projects/front-web/src/app/service/GuidelineService';
import * as Esri from 'esri-leaflet';
//import { HttpClient } from '@angular/common/http';
//import { forkJoin } from 'rxjs';


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class MapComponent implements AfterViewInit,OnInit {
   //@ViewChild('inputAdd')inputValue:ElementRef
  @ViewChild('inputAdd') inputValue:ElementRef;
  @Input() searchValue:string
  //@Output() MapButtonEvent = new EventEmitter();
  @Output() MapLoadEvent = new EventEmitter();
  //@Output() dataNotFound =  new EventEmitter();

  @Input() officeSessionData?: OfficeList;

  public map: any;
  baseLayers:any;
  baseLayerArray:any[]=[];
  //public markers: any = [];
  //public geocoder: any;
  //public newMarker:any;
  private settings!: AppSetting;
  private layerConfig:any=LayerConfig;
  private allLayerList:any=Layerlist;
  private basemaps:any =BaseMaps;
  layerList:any={};
  layerlistArray:any={};
  overlaylayerList:any=[];
  groupLayerList:any=[];
  private routeURL:string;
  //private selectPropertyLayer:any;
  //private selectGuideLineLayer:any;
  //selectguidlineLayer:any;
  //private mpBoundaryGeom:any=mpBoundary;
  //private demographyLoaded:boolean = false;

  private panelLayers:any;
  private setautoChnagebasemap:boolean=true;
  defaultBaseMap:any;

  @Input() alertmessage: AlertmessageComponent;
  
  southWest:any;
  northEast:any;
  
  innerHTML :any;
  financialyearList: any[]=new Array();
  finYearStartDate:string="";
  finYearEndDate:string="";
  
  constructor(private appSettingsService: AppSettingService, private router: Router, private activatedRoute: ActivatedRoute, private glService: GuidelineService,){ 
      this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
        this.settings = settings;
      });
      let rooturl:string="";
      if(this.router.url.split("?")[0])
        rooturl =this.router.url.split("?")[0];
      else
        rooturl =this.router.url
      for(let u in this.activatedRoute.snapshot.params){
        let regex:any = new RegExp("/"+encodeURIComponent(this.activatedRoute.snapshot.params[u]));
        rooturl= rooturl.replace(regex,"");
      }
      //console.log(rooturl);
      if(this.router.url == "/"){
        this.routeURL = "home";
      }else if(rooturl.split("/")[3])
        this.routeURL=rooturl.split("/")[3];
      else if(rooturl.split("/")[2])
        this.routeURL=rooturl.split("/")[2];
      else if(rooturl.split("/")[1])
        this.routeURL=rooturl.split("/")[1];
      else
        this.routeURL="";
      console.log(this.routeURL);
      if(this.layerConfig[this.routeURL]){
        this.layerList['list'] = this.layerConfig[this.routeURL].layerlist==""?[]:this.allLayerList[this.layerConfig[this.routeURL].layerlist];
        this.layerList['basemaps'] = this.basemaps.basemaps;
        this.defaultBaseMap = this.layerConfig[this.routeURL].defaultBaseMap
      }else{
        this.layerList['list'] = this.allLayerList[this.layerConfig[""].layerlist];
        this.layerList['basemaps'] = this.basemaps.basemaps;
        this.defaultBaseMap = this.layerConfig[""].defaultBaseMap
      }
      console.log(this.router.url, this.layerList);
  }

  ngOnInit(): void {
    //this.mpBoundaryGeom = this.mpBoundaryGeom.features[0].geometry;
  }


  private initMap(): void {
    setTimeout(()=>{
      this.initComponent();
    },1000)
  }


  public initComponent(value?:any):void{
    console.log(this.layerList);
    this.southWest = L.latLng(this.settings.mapBound.southWest[0], this.settings.mapBound.southWest[1])
    this.northEast = L.latLng(this.settings.mapBound.northEast[0], this.settings.mapBound.northEast[1])
    var bounds = L.latLngBounds(this.southWest, this.northEast);

    this.map = L.map('map', {
      center: [23.24345162975741, 77.42977898250595],
      zoom: 6,
      maxZoom: 28,
      minZoom: 6,
      markerZoomAnimation: false,
      zoomControl: false,
    });
    this.map.options.maxBoundsViscosity = 1; 
    this.baseLayers={};
    this.layerList?.basemaps?.forEach((basemap:any)=>{
      let url:string=(basemap.appurl)?this.settings.basemaps+basemap.url:basemap.url;
      var bmap=L.tileLayer.wms(url, {
          maxZoom: 28,
          subdomains:basemap.subdomains,
          layers: basemap.layer,
          format: basemap.format,
          crs:L.CRS.EPSG4326
      })
      //const campingURL = 'http://localhost/generated_tile_district/generated_tile/{z}/{x}/{y}.pbf';
      //const camping_vectorgrid = L.vectorGrid.protobuf(campingURL);
      this.baseLayerArray.push({name: basemap.alias,layer:bmap, value:basemap.name});
      this.baseLayers[basemap.name]=bmap
      if(basemap.name == this.defaultBaseMap){
        bmap.addTo(this.map);
        if(basemap.name == "SATELLITE")
          this.setautoChnagebasemap=false;
      }
    });

    this.map.setMaxBounds(bounds);

    L.control.scale({ position: 'bottomright' }).addTo(this.map);
    L.control.coordinates({
			position:"bottomleft",
			decimals:3,
      enableUserInput:false,
			labelTemplateLat:"{y}",
			labelTemplateLng:"{x}",
			useLatLngOrder:true
		}).addTo(this.map);

    this.glService.fetchFinancialYear().subscribe((resp:any)=>{
      if(resp.responseStatus == "true"){
        this.financialyearList = resp.responseData;
        resp.responseData.forEach((fyObject:any)=>{
          if(fyObject.fyType==='CURRENT'){
            this.finYearStartDate=fyObject.finYearStartDate;
            this.finYearEndDate=fyObject.finYearEndDate;
          }
         });
      }
      this.addLayers();
    },(error:any)=>{
      this.addLayers();
    });
      
    // for zoomin/zoomout

    var zoomHome = L.control.zoom({ position: 'bottomleft' });
    zoomHome.addTo(this.map);

    L.Marker.prototype.options.icon = L.icon({
      iconUrl: "./assets/images/marker.png",
      //shadowUrl: "./assets/images/marker-shadow.png",
      iconSize: [60, 60],
      iconAnchor: [30, 62]
    });
  
    this.MapLoadEvent.emit(this.map);

    L.control.fullscreen({
      position: 'topright', // change the position of the button can be topleft, topright, bottomright or bottomleft, default topleft
      title: 'Show me the fullscreen !', // change the title of the button, default Full Screen
      titleCancel: 'Exit fullscreen mode', // change the title of the button when fullscreen is on, default Exit Full Screen
      content: null, // change the content of the button, can be HTML, default null
      forceSeparateButton: true, // force separate button to detach from zoom buttons, default false
      forcePseudoFullscreen: true, // force use of pseudo full screen even if full screen API is available, default false
      fullscreenElement: false // Dom element to render in full screen, false by default, fallback to map._container
    }).addTo(this.map);
	
    

    this.map.on('zoomend', (event:any) =>{
      if(this.map.getZoom()>13){
        if(this.baseLayers?.satellitebasemap?._mapToAdd && this.baseLayers.satellitebasemap._mapToAdd != null ){
          this.map.removeLayer(this.baseLayers.satellitebasemap);
          this.map.addLayer(this.baseLayers.SATELLITE);
          this.setBasemapClick();
        }
      }else{
        if(this.baseLayers?.SATELLITE?._mapToAdd && this.baseLayers.SATELLITE._mapToAdd != null && this.setautoChnagebasemap ){
          this.map.removeLayer(this.baseLayers.SATELLITE);
          this.map.addLayer(this.baseLayers.satellitebasemap);
          this.setBasemapClick();
        }
      }
    });
    
    setTimeout(()=>{
      this.setBasemapClick();
    },1000)
    //this.googlesearch();
    //this.initialize();
  }

  ngAfterViewInit(): void {
    this.initMap();
    console.log(this.inputValue)
  }

//-Rahul--------------------------------------------------------------------

  addLayers(){
      this.layerList?.list?.forEach((layerDetail:any)=>{
        let url = (layerDetail.url)?layerDetail.url:this.settings.geoServerWmsUrl;
        if(layerDetail?.server_type =="arcserver"){
          //console.log(layerDetail);
          this.layerlistArray[layerDetail.name]=Esri.dynamicMapLayer({
            url: layerDetail?.url,
            layers:[layerDetail?.layer],
            transparent:true,
            format:'png',
            f: 'image'
          })
        }
        else if(layerDetail?.server_type =="zxy"){
          //console.log(layerDetail);
          this.layerlistArray[layerDetail.name]=L.tileLayer(layerDetail.geo_url, {
            maxZoom: (layerDetail.max_zoom !== undefined)?layerDetail.max_zoom:"",
            minZoom: (layerDetail.min_znoom !== undefined)?layerDetail.min_zoom:"",
          })
        }else{
          this.layerlistArray[layerDetail.name]=L.tileLayer.wms(
            url+'wms',
            {
              layers: (layerDetail.workSpace)?layerDetail.workSpace+":"+layerDetail.layers:this.settings.workSpace+":"+layerDetail.layers,
              format: (layerDetail.format !== undefined)?layerDetail.format:"",
              transparent: (layerDetail.transparent !== undefined)?layerDetail.transparent:"",
              maxZoom: (layerDetail.maxZoom !== undefined)?layerDetail.maxZoom:"",
              minZoom: (layerDetail.minZoom !== undefined)?layerDetail.minZoom:"",
              crs:L.CRS.EPSG4326
              //CQL_FILTER:(layerDetail.CQL_FILTER !== undefined)?layerDetail.CQL_FILTER:'1=1'
            }
          );
        }
        if(layerDetail.officeFilter != undefined && this.officeSessionData && layerDetail.officeFilter[this.officeSessionData.demography.demographyType.demographyTypeName] != undefined){
          let officeData:any=this.officeSessionData;
          let offLocations:any = officeData.demography[layerDetail.officeFilter[this.officeSessionData.demography.demographyType.demographyTypeName].s_column];
          let new_offLocations:string="";
          if (Array.isArray(offLocations)) {
            offLocations.forEach((location:any)=>{
              new_offLocations =new_offLocations+ "'"+location+"'"
            })
          }else{
            new_offLocations = "'"+offLocations+"'"
          }
          this.layerlistArray[layerDetail.name].wmsParams.CQL_FILTER=((layerDetail.CQL_FILTER !== undefined)?layerDetail.CQL_FILTER+' and ':'')+layerDetail.officeFilter[this.officeSessionData.demography.demographyType.demographyTypeName].d_column+" in ("+new_offLocations+")";
        }else{
          if(layerDetail?.server_type =="zxy"){}
          else if(layerDetail?.server_type =="arcserver"){}
          else{
            this.layerlistArray[layerDetail.name].wmsParams.CQL_FILTER=(layerDetail.CQL_FILTER !== undefined)?layerDetail.CQL_FILTER:'1=1'; 
          }
        }

        if(layerDetail.activeFromTofilter != undefined && this.finYearStartDate != "" && this.finYearEndDate !="" ){
          if(layerDetail.activeFromTofilter.from){
            this.layerlistArray[layerDetail.name].wmsParams.CQL_FILTER=((layerDetail.CQL_FILTER !== undefined)?layerDetail.CQL_FILTER+' and ':'')+layerDetail.activeFromTofilter.fromColName+">="+this.finYearStartDate;
          }
          if(layerDetail.activeFromTofilter.to){
            this.layerlistArray[layerDetail.name].wmsParams.CQL_FILTER=((layerDetail.CQL_FILTER !== undefined)?layerDetail.CQL_FILTER+' and ':'')+layerDetail.activeFromTofilter.toColname+"<="+this.finYearEndDate;
          }
        }
        if(layerDetail.added){
          this.layerlistArray[layerDetail.name].addTo(this.map);
        }
        this.createLayerlist(layerDetail);
      });
    
      var baseLayers = [
        {
          group: "Basemap",
          collapsed: true,
          layers: this.baseLayerArray
        }
      ];

      let layerList=L.control.layerListbtn({
        position: 'topright'
      });
      this.panelLayers = new L.control.panelLayers(baseLayers,this.overlaylayerList, {
        //compact: true,
        collapsed: true,
        collapsibleGroups: true
      });
      this.map.addControl(layerList);
      this.map.addControl(this.panelLayers);

      layerList._container.addEventListener('click',(e:any)=> {
        L.DomEvent.stopPropagation(e);
        this.panelLayers._expand();
      }); 
      // create a fullscreen button and add it to the map
  }


  createLayerlist(layerDetail:any){
    let iconStyle = (layerDetail.iconStyle !== undefined)? "style='"+layerDetail.iconStyle+"'":"";
    let rule = (layerDetail.rule !== undefined)?layerDetail.rule:"";
    let workSpace = (layerDetail.workSpace)?layerDetail.workSpace:this.settings.workSpace;
    let iconCustomstyle=(layerDetail.iconCustomStyle)?"&style="+layerDetail.iconCustomStyle:"";
    if(layerDetail.group && this.overlaylayerList[this.groupLayerList[layerDetail.group]]){
      this.overlaylayerList[this.groupLayerList[layerDetail.group]]['layers'].push({
        "name":layerDetail.alias,
        "icon":(layerDetail.icon)?"<img "+iconStyle+" src='"+this.settings.geoServerWmsUrl+"wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER="+workSpace+":"+layerDetail.layers+iconCustomstyle+rule+"' /> ":"",
        "layer":this.layerlistArray[layerDetail.name],
        "layerDetail":layerDetail
      });
    }else{
      if(layerDetail.group){
        this.groupLayerList[layerDetail.group]=this.overlaylayerList.length;
        this.overlaylayerList[this.groupLayerList[layerDetail.group]]=  {
          group: layerDetail.group,
          collapsed: true,
          layers: []
        }
        this.overlaylayerList[this.groupLayerList[layerDetail.group]]['layers'].push({
          "name":layerDetail.alias,
          "icon":(layerDetail.icon)?"<img "+iconStyle+"  src='"+this.settings.geoServerWmsUrl+"wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER="+workSpace+":"+layerDetail.layers+iconCustomstyle+rule+"' /> ":"",
          "layer":this.layerlistArray[layerDetail.name],
          "layerDetail":layerDetail
        });
      }else{
        this.overlaylayerList.push({
          "name":layerDetail.alias,
          "icon":(layerDetail.icon)?"<img "+iconStyle+" src='"+this.settings.geoServerWmsUrl+"wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER="+workSpace+":"+layerDetail.layers+iconCustomstyle+rule+"' /> ":"",
          "layer":this.layerlistArray[layerDetail.name],
          "layerDetail":layerDetail
        });
      }  
    }
  }

  setBasemapClick():void{
    this.panelLayers?._groups?.Basemap?.childNodes.forEach((e:any)=>{
      if(e.tagName == "DIV"){
        e.children[0].children[0].addEventListener('click',(e:any)=>{
          //console.log(e)
          if(e.target.type && e.target.type=="radio" && e.target.labels[0].innerText == "Satellite (MPSSDI, MPSeDC)"){
            this.setautoChnagebasemap=true;
          }
          else if(e.target.type && e.target.type=="radio" && this.setautoChnagebasemap){
            this.setautoChnagebasemap=false;
          }
        });
      }
    });
  }
//--End----------------------------------------------------------------------- 

}
