import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, delay, map } from "rxjs/operators";
import { AppSetting, AppSettingService } from "../../app.setting";
import { CustomHttpClient } from '../../httpclient/custom.httpclient';
import { NgxXmlToJsonService } from 'ngx-xml-to-json';
import { throwError } from 'rxjs';
const shajs = require('sha.js');
@Injectable({
  providedIn: 'root'
})

export class EkycService {

  status: boolean = false;
  private settings!: AppSetting;
  constructor(private appSettingsService: AppSettingService, private httpClient: HttpClient, private customeHttpClient: CustomHttpClient, private ngxXmlToJsonService: NgxXmlToJsonService) {
    this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
      this.settings = settings;
    });
  }

  generateAadharOtp(param: any) {

    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept-Language": sessionStorage.getItem("selectedLanguage") || ""
    });
    return this.customeHttpClient.Post<any>(this.settings.igrs_gateway_url + "ekyc/generate_otp", param, { headers: httpHeaders }) //common/vkyc/generate_otp   //ekyc/generate_otp
      .pipe(
        map((result: any) => {

          return result;
        }));
  }

  verifyAadharOtp(aadharEkycOTPAuthRequest: any) {

    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept-Language": sessionStorage.getItem("selectedLanguage") || ""
    });
    return this.httpClient.post<any>(this.settings.igrs_gateway_url + "ekyc/authenticate_otp", aadharEkycOTPAuthRequest, { headers: httpHeaders }) //common/vkyc/authenticate_otp_auth    //ekyc/authenticate_otp
      .pipe(
        map((result: any) => {

          return result;
        }));
  }

  verifyAadharBio(aadharEkycOTPAuthRequest: any) {

    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept-Language": sessionStorage.getItem("selectedLanguage") || ""
    });
    return this.httpClient.post<any>(this.settings.igrs_gateway_url + "ekyc/authenticate_bio", aadharEkycOTPAuthRequest, { headers: httpHeaders })
      .pipe(
        map((result: any) => {

          return result;
        }));
  }

  verifyAadharIrisBio(aadharEkycOTPAuthRequest: any) {

    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept-Language": sessionStorage.getItem("selectedLanguage") || ""
    });
    return this.httpClient.post<any>(this.settings.igrs_gateway_url + "ekyc/authenticate_iris", aadharEkycOTPAuthRequest, { headers: httpHeaders })
      .pipe(
        map((result: any) => {

          return result;
        }));
  }
  verifyAadharIrisMantraMIS100v2Bio(aadharEkycOTPAuthRequest: any) {

    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept-Language": sessionStorage.getItem("selectedLanguage") || ""
    });
    return this.httpClient.post<any>(this.settings.igrs_gateway_url + "ekyc/authenticate_iris", aadharEkycOTPAuthRequest, { headers: httpHeaders })
      .pipe(
        map((result: any) => {

          return result;
        }));
  }



  checkMantraRdService() {
    //
    // 
    return this.customeHttpClient
      .GetRdSerice(this.settings.mantra_url, "RDSERVICE").pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  checkRdServiceInfo(element: any) {
    return this.customeHttpClient
      .GetRdSerice(this.settings.rd_url + element, "RDSERVICE").pipe(
        map((result: any) => {
          return result;
        })
      );
  }
  checkRdServiceIrisMIS100v2Info(element: any) {
    return this.customeHttpClient
      .GetRdSerice(this.settings.mis100v2_url + element, "RDSERVICE").pipe(
        map((result: any) => {
          return result;
        })
      );
  }


  checkFM2202RdServiceInfo(element: any) {
    return this.customeHttpClient
      .GetRdSerice(this.settings.FM220_rd_url + element, "RDSERVICE").pipe(
        map((result: any) => {
          return result;
        })
      );
  }
  checkMFS110RdService(element: any) {
    return this.customeHttpClient
      .GetRdSerice(this.settings.mfs110_url + element, "RDSERVICE").pipe(
        map((result: any) => {
          return result;
        })
      );
  }
  checkMorphoRdServiceInfo() {
    return this.customeHttpClient
      .GetRdSerice(this.settings.morpho_rd_url, "RDSERVICE").pipe(
        map((result: any) => {
          return result;
        })
      );
  }
  checkRdServiceCaptureFP() {

    // this.settings.mantra_evn = 'pp';
    let wadh = btoa(shajs('sha256').update('2.5' + 'F' + 'Y' + 'N' + 'N' + 'N').digest('hex'));
    let rebody = '<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="1" fType="2" iCount="0" pCount="0" format="0" pidVer="2.0" wadh="' + wadh + '" timeout="' + this.settings.mantra_timeout + '" posh="UNKNOWN" env="' + this.settings.mantra_evn + '" />  </PidOptions>'

    // 
    return this.customeHttpClient
      .captureRdSerice(this.settings.mantra_url, "CAPTURE", rebody).pipe(
        map((result: any) => {
          return result;
        })
      );
  }


  checkRdServiceFM220UCaptureFP(element: any, componentName: string) {

    // this.settings.mantra_evn = 'pp';
    let wadh = btoa(shajs('sha256').update('2.5' + 'F' + 'Y' + 'N' + 'N' + 'N').digest('hex'));
    if (componentName === 'EKYC')
      wadh = "E0jzJ/P8UopUHAieZn8CKqS4WPMi5ZSYXgfnlfkWjrc=";
    else
      wadh = "";

    let rebody = '<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="1" fType="2" iCount="0" pCount="0" format="0" pidVer="2.0" wadh="' + wadh + '" timeout="' + this.settings.FM220_rd_timeout + '" posh="UNKNOWN" env="' + this.settings.FM220_rd_evn + '" />  </PidOptions>'
    console.log(wadh + "           " + rebody);
    //11104 , 11200 // "rd_url": "https://127.0.0.1:11200/",

    let result = null
    const i = 0;
    //    let devicePort: string[] = ['11106/', '11107/', '11200/', '11104/', '11208/', '11209/'];
    //for (let i = 0; i < devicePort.length; i++) {
    //    let element = devicePort[1];
    //result = this.validatecaptureRdSerice(element, rebody);
    //console.log("Parent_result", result);

    result = this.customeHttpClient.captureRdSerice(this.settings.FM220_rd_url + element + "rd/capture", "CAPTURE", rebody).pipe(delay(5000),
      //result = this.customeHttpClient.captureRdSerice(this.settings.FM220_rd_url + "rd/capture", "CAPTURE", rebody).pipe(delay(5000),
      map((result: any) => {
        let captureJsondata = this.ngxXmlToJsonService.xmlToJson(result, "");
        console.log("captureJsondata_rd_service", captureJsondata);
        if (captureJsondata?.PidData.Resp?.attr?.errCode === "0") {
          this.status = true;
          console.log("rd_service_result", result);
          console.log("rd_service_result", this.status);
        }
        return result;
      }),
      catchError(() => {
        let error = "8";
        return error;
      })
    );

    // if (this.status) {
    //   this.status = false;
    //   //break;
    // }

    //}

    return result

    // return this.customeHttpClient
    //   .captureRdSerice(this.settings.rd_url + "rd/capture", "CAPTURE", rebody).pipe(
    //     map((result: any) => {
    //       return result;
    //     })
    //   );
  }

  // validatecaptureRdSerice(element: any, rebody: any) {
  //   let result = this.customeHttpClient.captureRdSerice(this.settings.rd_url + element + "rd/capture", "CAPTURE", rebody).pipe(
  //     map((result: any) => {
  //       let captureJsondata = this.ngxXmlToJsonService.xmlToJson(result, "");
  //       console.log("captureJsondata_rd_service", captureJsondata);
  //       if (captureJsondata?.PidData.Resp?.attr?.errCode === "0") {
  //         this.status = true;
  //         console.log("rd_service_result", result);
  //         console.log("rd_service_result", this.status);
  //         //return result;
  //       }
  //       return result;
  //     }),
  //     catchError(() => {
  //       let error = "8";
  //       return error;
  //     })
  //     //catchError(this.handleError)
  //   );

  //   return result
  // }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('<PidData xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema"><Resp fType="0" iCount="0" pCount="0" errCode="730" errInfo="Capture Failed / TimeOut" fCount="0" ts="" nmPoints="" qScore="" /><DeviceInfo dpId="STARTEK.ACPL" rdsId="ACPL.WIN.001" rdsVer="1.0.4" dc="" mi="FM220U" mc="" error=""></DeviceInfo><Skey ci=""></Skey><Hmac></Hmac><Data type=""></Data></PidData>'));
    //return result;
  }

  checkMorphoRdServiceCaptureFP() {

    // this.settings.mantra_evn = 'pp';
    let wadh = btoa(shajs('sha256').update('2.5' + 'F' + 'Y' + 'N' + 'N' + 'N').digest('hex'));
    // let rebody='<?xml version="1.0"?> <PidOptions ver="1.0">'+'<Opts fCount="1" fType="0" iCount="0" iType="0" pCount="0" pType="0" format="0" pidVer="2.0" timeout="'+this.settings.morpho_rd_timeout+'" otp="0" wadh="'+wadh+'" posh="UNKNOWN" env="'+this.settings.morpho_rd_evn+'" />  </PidOptions>'
    let rebody = '<?xml version="1.0"?> <PidOptions ver=\"1.0\">' + '<Opts fCount=\"1\" fType=\"2\" iCount=\"\" iType=\"\" pCount=\"\" pType=\"\" format=\"0\" pidVer=\"2.0\" timeout=\"10000\" otp=\"\" wadh=\"\" posh=\"\"/>' + '</PidOptions>';

    // 
    return this.customeHttpClient
      .captureRdSerice(this.settings.morpho_rd_url + "/capture", "CAPTURE", rebody).pipe(
        map((result: any) => {
          alert(result);
          return result;
        })
      );
  }


  checkMantraFMS100RdServiceCaptureFP() {

    // this.settings.mantra_evn = 'pp';
    let wadh = btoa(shajs('sha256').update('2.5' + 'F' + 'Y' + 'N' + 'N' + 'N').digest('hex'));
    wadh = "E0jzJ/P8UopUHAieZn8CKqS4WPMi5ZSYXgfnlfkWjrc=";
    let rebody = '<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="1" iType="" fType="2" iCount="0" pCount="0" errCode="0" errInfo="" nmPoints="32" qScore="80" format="0" pidVer="2.0" wadh="' + wadh + '" timeout="' + this.settings.mantra_timeout + '" posh="UNKNOWN" env="' + this.settings.mantra_evn + '" />  </PidOptions>'
    console.log(wadh + "           " + rebody);
    // 
    return this.customeHttpClient
      .captureRdSerice(this.settings.mantra_url + "rd/capture", "CAPTURE", rebody).pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  checkRdServiceIritechDataCaptureFP(element: any) {

    // this.settings.mantra_evn = 'pp';
    //Here ‘+’ indicates string concatenation of eKYC user inputs. For example, for ver=”2.5”, ra=”F”, rc=”Y”, lr=”N”, de=”N”, pfr=”N” the wadh value 
    //passed in PidOptions XML will be SHA-256 based hashed and Base64 encoded value of string “2.5FYNNN”.
    let wadh = btoa(shajs('sha256').update('2.5' + 'I' + 'Y' + 'N' + 'N' + 'N').digest('hex'));
    let rebody = '<?xml version="1.0"?> <PidOptions ver="1.0"><Opts fCount="" fType="2" iCount="1" iType="0" pCount="" pType="" errCode="0" errInfo="" nmPoints="32" qScore="80" format="0" pidVer="2.0" timeout="' + this.settings.rd_timeout + '" otp="" wadh="8QSrHOmcQhlyjiSpIgCi7o2ugs78w+4jhckNk1jeIJg=" posh="UNKNOWN" env="' + this.settings.rd_evn + '"/><CustOpts> <Param name="enable_preview" value="yes" /></CustOpts></PidOptions>'

    return this.customeHttpClient
      .captureRdSerice(this.settings.rd_url + element + "rd/capture", "CAPTURE", rebody).pipe(
        map((result: any) => {
          return result;
        }),
        catchError(() => {
          let error = "8";
          return error;
        })
      );
    // return this.customeHttpClient
    //   .captureRdSerice(this.settings.rd_url + "rd/capture", "CAPTURE", rebody).pipe(
    //     map((result: any) => {
    //       return result;
    //     })
    //   );
  }
  checkMFS110RdServiceCaptureFP(element: any, authType: any) {


    // this.settings.mantra_evn = 'p';
    let wadh = btoa(shajs('sha256').update('2.5' + 'F' + 'Y' + 'N' + 'N' + 'N').digest('hex'));
    wadh = "E0jzJ/P8UopUHAieZn8CKqS4WPMi5ZSYXgfnlfkWjrc=";

    let rebody = null;
    if (authType === "EKYC")
      rebody = '<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="1" iType="" fType="2" iCount="0" pCount="0" errCode="0" errInfo="" nmPoints="32" qScore="80" format="0" pidVer="2.0" wadh="' + wadh + '" timeout="' + this.settings.mfs110_timeout + '" posh="UNKNOWN" env="' + this.settings.mfs110_evn + '" /> <CustOpts><Param name="mantrakey" value="" /></CustOpts>  </PidOptions>' //EKYC
    else
      rebody = '<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fType="2" fCount="1" iType="" iCount="0" pType="" pCount="0" errCode="0" errInfo="" nmPoints="32" qScore="80" timeout="' + this.settings.mfs110_timeout + '" posh="UNKNOWN" env="' + this.settings.mfs110_evn + '" /> <CustOpts><Param name="mantrakey" value="" /></CustOpts>  </PidOptions>' //Auth


    //let rebody='<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="1" fType="0" iCount="0" pCount="0" pgCount="2" format="0"   pidVer="2.0" timeout="10000" pTimeout="20000" posh="UNKNOWN" env="PP" wadh="'+wadh+'" /> <CustOpts><Param name="mantrakey" value="" /></CustOpts> </PidOptions>'
    //  let rebody='<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="1" fType="0" iCount="0" pCount="0" format="0" pidVer="2.0" wadh="'+wadh+'" timeout="'+this.settings.mfs110_timeout+'" posh="UNKNOWN" env="'+this.settings.mfs110_evn+'" /> <CustOpts><Param name="" value="" /></CustOpts>  </PidOptions>'
    //let rebody='<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="1" iType="" fType="2" iCount="0" pCount="0" errCode="0" errInfo="" nmPoints="19" qScore="77" format="0" pidVer="2.0" wadh="'+wadh+'" timeout="'+this.settings.mfs110_timeout+'" posh="UNKNOWN" env="'+this.settings.mfs110_evn+'" />  </PidOptions>'
    // 
    return this.customeHttpClient
      .captureRdSerice(this.settings.mfs110_url + element + "rd/capture", "CAPTURE", rebody).pipe(
        map((result: any) => {
          return result;
        }),
        catchError(() => {
          let error = "8";
          return error;
        })
      );
  }
  checkRdServiceMantraIrisMIS100v2DataCaptureFP(element: any) {

    // this.settings.mantra_evn = 'pp';
    //Here ‘+’ indicates string concatenation of eKYC user inputs. For example, for ver=”2.5”, ra=”F”, rc=”Y”, lr=”N”, de=”N”, pfr=”N” the wadh value 
    //passed in PidOptions XML will be SHA-256 based hashed and Base64 encoded value of string “2.5FYNNN”.
    let wadh = btoa(shajs('sha256').update('2.5' + 'I' + 'Y' + 'N' + 'N' + 'N').digest('hex'));
    let rebody = '<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="0" fType="2" iCount="1" pCount="0" pgCount="2" format="0"   pidVer="2.0" timeout="' + this.settings.rd_timeout + '" otp="" wadh="8QSrHOmcQhlyjiSpIgCi7o2ugs78w+4jhckNk1jeIJg=" pTimeout="20000" posh="UNKNOWN" env="' + this.settings.rd_evn + '" /> <CustOpts><Param name="mantrakey" value="" /></CustOpts> </PidOptions>'

    return this.customeHttpClient
      .captureRdSerice(this.settings.mis100v2_url + element + "rd/capture", "CAPTURE", rebody).pipe(
        map((result: any) => {
          return result;
        }),
        catchError(() => {
          let error = "8";
          return error;
        })
      );
    // return this.customeHttpClient
    //   .captureRdSerice(this.settings.rd_url + "rd/capture", "CAPTURE", rebody).pipe(
    //     map((result: any) => {
    //       return result;
    //     })
    //   );
  }





}


