<lib-alertmessage #alertMessage></lib-alertmessage>


<div class="container mt-t-10">
  <div *ngIf="isMarquee && publishDistrictList.length>0">
    <div *ngIf="lan==0; else elseMarquee">
      <marquee direction="left" class="p-1 pm-2" style="font-family: Rajdhani, sans-serif; color: #FFFFFF"
        bgcolor="#00224f" scrolldelay="50" behavior="loop">{{districtNameEn}}</marquee>
    </div>
    <ng-template #elseMarquee>
      <marquee direction="left" class="p-1 pm-2" style="font-family: Rajdhani, sans-serif; color: #FFFFFF"
        bgcolor="#00224f" scrolldelay="50" behavior="loop">{{districtNameHi}}</marquee>
    </ng-template>
  </div>
  <div class="card mb-1 suggestiontable">
    <div class="card-body guidline_tab">

      <mat-tab-group animationDuration="0ms" [selectedIndex]="selectedIndex"
        (selectedTabChange)="onTabChanged($event);">
        <mat-tab class="tab_bg" label="{{'guideline.draftGuidelinetab' | translate}}"
          *ngIf="publishDistrictList.length>0">




          <div class="searchtop">
            <form class="row  ml-0 pt-2" #DraftGuideline="ngForm" novalidate autocomplete="off">
              <div class="form-group col-md-2">
                <label>{{'guideline.citizen-suggestion-form-financial-year' | translate}}
                </label>
                <!-- <select class="form-control">
                <option value="{{finYear}}" disabled="disabled" selected>{{finYear}}</option>
              </select> -->
                <!--<input type="text" name="financialYear" value="{{finYear}}" class="form-control" readonly>-->
                <select class="form-control" [(ngModel)]="financialYear" name="financialYear">
                  <option *ngFor="let finyearObject of financialyearList " [value]="finyearObject.id">
                    {{finyearObject.finYear}}</option>
                </select>

              </div>
              <div class="form-group col-md-3">
                <label>{{'guideline.select-district'|translate}}
                  <span style="color: red;">*</span>
                </label>
                <select class="form-control" required name="districtIds" #districtIds="ngModel"
                  [(ngModel)]="serviceprovider.districtId" (change)="onChangeDistrict($event)">
                  <option value="null" disabled selected>{{'guideline.select-district'|translate}}</option>
                  <option *ngFor="let ds of districtList" [value]="ds.id">
                    {{lan==1?ds.demographyNameHi:ds.demographyNameEn}}</option>
                </select>
                <div *ngIf="districtIds.invalid" class="invalid-feedback">
                  <div *ngIf="districtIds.errors?.required">{{'required' | translate}}</div>
                </div>
              </div>
              <div class="form-group col-md-3">
                <label>{{'guideline.select-office' |
                  translate}} <span style="color: red;">*</span></label>
                <select class="form-control" required name="officeId" #officeId="ngModel"
                  [(ngModel)]="serviceprovider.officeId" (change)="onChangeOffice($event)">
                  <option value="null" disabled>{{'guideline.select-office' | translate}}</option>
                  <option *ngFor="let ofc of customOfficeList" [value]="ofc.id">
                    {{lan==0?ofc.officeNameEn:ofc.officeNameHi}}
                  </option>
                </select>
                <div *ngIf="officeId.invalid" class="invalid-feedback">
                  <div *ngIf="officeId.errors?.required">{{'required' | translate}}</div>
                </div>
              </div>

              <div class="form-group" style="margin-top: 27px;">
                <button type="submit" mat-raised-button color="primary" [disabled]="DraftGuideline.invalid"
                  class="mb-2 btn btn-secondary line1rem"
                  (click)="loadDataGLDraftSearchList(districtIds.value, officeId.value)">
                  <mat-icon>search</mat-icon>{{'searchbtn' | translate}}
                </button>

              </div>
              <div class="form-group p-0 px-2 pl-2" style="margin-top: 27px;">
                <button type="reset" mat-raised-button color="primary" [disabled]="DraftGuideline.invalid"
                  class="mb-2 btn btn-secondary line1rem" (click)="reset(DraftGuideline)">
                  {{'reset' | translate}}
                </button>
              </div>
              <div *ngIf="PostSuggestion" class="form-group p-0 " style="margin-top: 27px;">
                <button mat-raised-button color="primary" data-toggle="modal" data-target="#exampleModal"
                  class="btn btn-sm btn-primary">{{'guideline.post-suggestion' | translate}}</button>
              </div>

            </form>
          </div>

          <!--Button for download pdf and csv file-->
          <div class="text-right col-lg-12 mb-1 p-0">
            <!-- <button class=" ml-1 mr-2 text-white" mat-raised-button color="primary"
        (click)="downloadGLDraftDetails(false)" data-toggle="modal" data-target="#pdfModal">
        <i class="fa fa-print" aria-hidden="true"></i></button> -->
            <button class="ml-1 mr-1 text-white" mat-raised-button color="primary"
              (click)="downloadGLDraftDetails(true)" placement="top" ngbTooltip=" {{'exCSV' | translate}}"
              *ngIf="isTableDisplayShow">
              <i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
          </div>
          <!--Table-->
          <div class="mb-3">
            <div class="table-responsive shadow-none">
              <table class="table table-bordered table-hover" mat-table matTableExporter [dataSource]="dataSource"
                *ngIf="isTableDisplayShow">
                <ng-container matColumnDef="position">
                  <th mat-header-cell *matHeaderCellDef>{{'guideline.no' | translate}} </th>
                  <td mat-cell *matCellDef="let row; let i = index">
                    {{ row.position}}
                  </td>
                </ng-container>

                <!-- <ng-container matColumnDef="zone">
            <th mat-header-cell *matHeaderCellDef>
              {{'guideline.zone' | translate}}</th>
                <td mat-cell *matCellDef="let row"> {{lan ==0 ? row.zone?.demographyNameEn : row.zone?.demographyNameHi}} </td>
          </ng-container>

          <ng-container matColumnDef="district">
            <th mat-header-cell *matHeaderCellDef>
              {{'guideline.district' | translate}}</th>
                <td mat-cell *matCellDef="let row"> {{lan ==0 ? row.district?.demographyNameEn : row.district?.demographyNameHi}} </td>
          </ng-container>

          <ng-container matColumnDef="localBody">
            <th mat-header-cell *matHeaderCellDef>
              {{'guideline.localbody_tehsil' | translate}}</th>
                <td mat-cell *matCellDef="let row">
                  <div *ngIf="row.localbody?.demographyNameEn!=''">
                    {{lan==0?row.localbody?.demographyNameEn:row.localbody?.demographyNameHi}}
                  </div>
                  <div *ngIf="row.tehsil?.demographyNameEn!=''">
                    {{lan==0?row.tehsil?.demographyNameEn:row.tehsil?.demographyNameHi}}
                  </div>
                </td>
          </ng-container>

          <ng-container matColumnDef="ward">
            <th mat-header-cell *matHeaderCellDef>
              {{'guideline.ward/village' | translate}}</th>
                <td mat-cell *matCellDef="let row">
                  <div *ngIf="row.ward?.demographyNameEn!=''">
                    {{lan==0?row.ward?.demographyNameEn:row.ward?.demographyNameHi}}
                  </div>
                  <div *ngIf="row.village?.demographyNameEn!=''">
                    {{lan==0?row.village?.demographyNameEn:row.village?.demographyNameHi}}
                  </div>
                </td>
          </ng-container>-->

                <ng-container matColumnDef="wardColony">
                  <th mat-header-cell *matHeaderCellDef>
                    {{'guideline.ward/village-colony' | translate}}</th>
                  <td mat-cell *matCellDef="let row">
                    <!-- <div *ngIf="row.ward_colony?.demographyNameEn!=''">
                    {{lan==0?row.ward_colony?.demographyNameEn:row.ward_colony?.demographyNameHi}}
                  </div>
                  <div *ngIf="row.village_coliny?.demographyNameEn!=''">
                    {{lan==0?row.village_coliny?.demographyNameEn:row.village_coliny?.demographyNameHi}}
                  </div> -->
                    {{lan==0?row.wardColonyEn:row.wardColonyHi}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="plotresi">
                  <th mat-header-cell *matHeaderCellDef>
                    {{'guideline.resi' | translate}}</th>
                  <td mat-cell *matCellDef="let row"> {{row.plotresi}} </td>
                </ng-container>

                <ng-container matColumnDef="plotcomm">
                  <th mat-header-cell *matHeaderCellDef>
                    {{'guideline.comm' | translate}}</th>
                  <td mat-cell *matCellDef="let row"> {{row.plotcomm}} </td>
                </ng-container>

                <ng-container matColumnDef="plotindus">
                  <th mat-header-cell *matHeaderCellDef>
                    {{'guideline.ind' | translate}}</th>
                  <td mat-cell *matCellDef="let row"> {{row.plotindus}} </td>
                </ng-container>

                <ng-container matColumnDef="buildrcc">
                  <th mat-header-cell *matHeaderCellDef>
                    {{'guideline.rcc' | translate}}</th>
                  <td mat-cell *matCellDef="let row"> {{row.buildrcc}} </td>
                </ng-container>

                <ng-container matColumnDef="buildrbc">
                  <th mat-header-cell *matHeaderCellDef>
                    {{'guideline.rbc' | translate}}</th>
                  <td mat-cell *matCellDef="let row"> {{row.buildrbc}} </td>
                </ng-container>

                <ng-container matColumnDef="buildtinshade">
                  <th mat-header-cell *matHeaderCellDef>
                    {{'guideline.tin_shade' | translate}}</th>
                  <td mat-cell *matCellDef="let row"> {{row.buildtinshade}} </td>
                </ng-container>

                <ng-container matColumnDef="buildkabelu">
                  <th mat-header-cell *matHeaderCellDef>
                    {{'guideline.kaccha_abelu' | translate}}</th>
                  <td mat-cell *matCellDef="let row"> {{row.buildkabelu}} </td>
                </ng-container>

                <ng-container matColumnDef="buildshop">
                  <th mat-header-cell *matHeaderCellDef>
                    {{'guideline.Shop' | translate}}</th>
                  <td mat-cell *matCellDef="let row"> {{row.buildshop}} </td>
                </ng-container>

                <ng-container matColumnDef="buildoffice">
                  <th mat-header-cell *matHeaderCellDef>
                    {{'guideline.office' | translate}}</th>
                  <td mat-cell *matCellDef="let row"> {{row.buildoffice}} </td>
                </ng-container>

                <ng-container matColumnDef="buildgodown">
                  <th mat-header-cell *matHeaderCellDef>
                    {{'guideline.godown' | translate}}</th>
                  <td mat-cell *matCellDef="let row"> {{row.buildgodown}} </td>
                </ng-container>

                <ng-container matColumnDef="buildmultiresi">
                  <th mat-header-cell *matHeaderCellDef>
                    {{'guideline.resi' | translate}}</th>
                  <td mat-cell *matCellDef="let row"> {{row.buildmultiresi}} </td>
                </ng-container>

                <ng-container matColumnDef="buildmulticomm">
                  <th mat-header-cell *matHeaderCellDef>
                    {{'guideline.comm' | translate}}</th>
                  <td mat-cell *matCellDef="let row"> {{row.buildmulticomm}} </td>
                </ng-container>

                <ng-container matColumnDef="agriirrg">
                  <th mat-header-cell *matHeaderCellDef>
                    {{'guideline.irgd' | translate}}</th>
                  <td mat-cell *matCellDef="let row"> {{row.agriirrg}} </td>
                </ng-container>

                <ng-container matColumnDef="agriunirrg">
                  <th mat-header-cell *matHeaderCellDef>
                    {{'guideline.unirgd' | translate}}</th>
                  <td mat-cell *matCellDef="let row"> {{row.agriunirrg}} </td>
                </ng-container>

                <ng-container matColumnDef="landuptoresi">
                  <th mat-header-cell *matHeaderCellDef>
                    {{'guideline.sub-clause-resi' | translate}}</th>
                  <td mat-cell *matCellDef="let row"> {{row.landuptoresi}} </td>
                </ng-container>

                <ng-container matColumnDef="landuptocomm">
                  <th mat-header-cell *matHeaderCellDef>
                    {{'guideline.sub-clause-comm' | translate}}</th>
                  <td mat-cell *matCellDef="let row"> {{row.landuptocomm}} </td>
                </ng-container>

                <ng-container matColumnDef="no">
                  <th mat-header-cell *matHeaderCellDef [attr.colspan]="1">
                  </th>
                </ng-container>

                <!-- <ng-container matColumnDef="Zone">
            <th mat-header-cell *matHeaderCellDef
                [attr.colspan]="1">
            </th>
          </ng-container>
          <ng-container matColumnDef="District">
            <th mat-header-cell *matHeaderCellDef
                [attr.colspan]="1">
            </th>
          </ng-container>
          <ng-container matColumnDef="tehsil">
            <th mat-header-cell *matHeaderCellDef
                [attr.colspan]="1">
            </th>
          </ng-container>
          <ng-container matColumnDef="village">
            <th mat-header-cell *matHeaderCellDef
                [attr.colspan]="1">
            </th>
          </ng-container> -->
                <ng-container matColumnDef="village-colony">
                  <th mat-header-cell *matHeaderCellDef [attr.colspan]="1">
                  </th>
                </ng-container>

                <ng-container matColumnDef="PLOT">
                  <th mat-header-cell *matHeaderCellDef [attr.colspan]="3">
                    {{'guideline.plot' | translate}}{{'guideline.sqm' | translate}}
                  </th>
                </ng-container>
                <ng-container matColumnDef="BuildingResidential">
                  <th mat-header-cell *matHeaderCellDef [attr.colspan]="4">
                    {{'guideline.buil-resi' | translate}}{{'guideline.sqm' | translate}}
                  </th>
                </ng-container>
                <ng-container matColumnDef="BuildingCommercial">
                  <th mat-header-cell *matHeaderCellDef [attr.colspan]="3">
                    {{'guideline.buil-comm' | translate}}{{'guideline.sqm' | translate}}
                  </th>
                </ng-container>
                <ng-container matColumnDef="BuildingMulti">
                  <th mat-header-cell *matHeaderCellDef [attr.colspan]="2">
                    {{'guideline.buil-multi' | translate}}{{'guideline.sqm' | translate}}
                  </th>
                </ng-container>
                <ng-container matColumnDef="AgricultureLand">
                  <th mat-header-cell *matHeaderCellDef [attr.colspan]="2">
                    {{'guideline.agri-land' | translate}}{{'guideline.hectare' | translate}}
                  </th>
                </ng-container>
                <ng-container matColumnDef="AgriculturePlot">
                  <th mat-header-cell *matHeaderCellDef [attr.colspan]="2">
                    {{'guideline.agri-plot' | translate}}{{'guideline.sqm' | translate}}
                  </th>
                </ng-container>

                <tr mat-header-row
                  *matHeaderRowDef="['no','village-colony', 'PLOT','BuildingResidential','BuildingCommercial','BuildingMulti','AgricultureLand','AgriculturePlot']">
                </tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <!--for grouping-->
                <ng-container matColumnDef="groupHeader">
                  <td colspan="999" class="text-left" mat-cell *matCellDef="let groupBy"
                    style="text-align: left !important;"><strong>{{lan==0?groupBy.zoneEn:groupBy.zoneHi}}</strong></td>
                </ng-container>

                <tr mat-row class="groupSection" *matRowDef="let row; columns: ['groupHeader']; when: isGroup"> </tr>

                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell text-center" colspan="34">
                    <div *ngIf="isDisplayShow ; else serviceElseBlock">
                      <!-- {{'guideline.search-data-not-found' | translate}} -->
                    </div>
                    <ng-template #serviceElseBlock>
                      {{'guideline.no-data-found' | translate}}
                    </ng-template>
                  </td>
                </tr>
              </table>
            </div>
            <div [hidden]="!dataSource.data.length" class="row p-1 m-0">
              <div class="col-md-6 col-xs-12 col-lg-6 p-0 suggestiontable">
                <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
                  [pageSizeOptions]="pageSizeOptions" (page)="pageChangedGL($event)" showFirstLastButtons>
                </mat-paginator>
              </div>
            </div>

          </div>


        </mat-tab>
        <mat-tab lass="tab_bg" label="{{'guideline.cfyGdl' | translate}}">
          <div class="row">
            <div class="col-md-12">
              <!-- <h3><strong><em><i class="fa fa-long-arrow-right" aria-hidden="true"></i> General instructions for Market value assessment year 2022-23</em></strong></h3>-->
              <div class="list-All-dist">
                <ul>
                  <li (click)="editGL(districtObject.id)" *ngFor="let districtObject of districtList"><strong> {{lan ==0
                      ? districtObject.demographyNameEn : districtObject.demographyNameHi}}</strong></li>
                </ul>
              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab lass="tab_bg" label="{{'guideline.viewcfyGdlRate' | translate}}">
          <!-- <a class="click-btn btn-style507 text-light"
          data-toggle="modal" data-target="#ViewGuidelineRates">
          {{'guideline.guideline' |
          translate}}</a> -->
          <div class="d-flex wrap_flex_Div">
            <div class="guidelineMapview" style="height: 66vh;position: relative;" *ngIf="showMap"
              [@mapbodyInOut]="mapbody">
              <div class="citizenSuggestion-demographystyle" *ngIf="showhierarchy" cdkDrag>
                <mat-tab-group disablePagination="true" [(selectedIndex)]="selectedTabIndex">
                  <mat-tab label="Query">
                    <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedHirarchy"
                      (change)="optionChange()">
                      <mat-radio-button value="0" class="mr-2">By Hierarchy</mat-radio-button>
                      <mat-radio-button value="1">By Colony Name</mat-radio-button>
                    </mat-radio-group>
                    <div *ngIf="selectedHirarchy ==0">
                      <citizen-demography-hierarchy-load style="width: 100%;" [componentFlag]="'columnwise'"
                        [type_id]="3" [parent_id]="1" [lastdemograpgy_id]="15"
                        (loadgldata)="getDemography($event)"></citizen-demography-hierarchy-load>
                    </div>
                    <div *ngIf="selectedHirarchy ==1">
                      <citizen-demography-hierarchy-load style="width: 100%;" [componentFlag]="'columnwise'"
                        [type_id]="3" [parent_id]="1" [lastdemograpgy_id]="3"
                        (loadgldata)="getDistrict($event)"></citizen-demography-hierarchy-load>

                      <div>

                        <label class="w-100">Search Colony:</label>

                        <input id="car" #colonySearchInput placeholder="Search Colony By Name" class="form-control"
                          type="text" autocomplete="off" (click)="showSearches = true" (input)="keyupAction(false)">


                        <!--  DropDown Starts  -->
                        <div [hidden]="!showSearches">
                          <div class="search">
                            <div *ngFor="let colony of searchedColony;" tappable (click)="setColony(colony)"
                              class="search-result">
                              {{colony.DEMOGRAPHY_NAME_EN}}
                            </div>
                          </div>
                          <p class="search" *ngIf="searchedColony && searchedColony.length <= 0 && isSearcedColony">No
                            colony found</p>
                          <p class="search" [hidden]="isSearching">Searching colony...</p>
                        </div>
                        <!--  DropDown Ends  -->

                      </div>
                    </div>

                  </mat-tab>
                  <mat-tab label="Result">
                    <div class="searchRecord mb-0 heading_guideline justify-content-center"
                      *ngIf="selectedMstDemographyBean">
                      <div
                        *ngIf="selectedMstDemographyBean?.location?.district?.demographyName!=null&&selectedMstDemographyBean?.location?.district?.demographyName!=null"
                        class="new-design-box p-0 ">
                        <div class="analytic-single-block position-relative">

                          <div class="analytic-demography-icon">
                            <img src="./assets/images/district.png">
                          </div>
                          <h3 class="text-theme analytic-demography-key">{{'property-valuation.District' | translate}} :
                            <span class=" analytic-demography-value text-dark">
                              {{lan==0?selectedMstDemographyBean?.location?.district?.demographyNameEn:selectedMstDemographyBean?.location?.district?.demographyNameHi}}
                            </span>
                          </h3>
                        </div>


                      </div>

                      <div
                        *ngIf="selectedMstDemographyBean?.location?.sroffice?.demographyName!=null&&selectedMstDemographyBean?.location?.district?.demographyName!=null"
                        class="new-design-box p-0 ">
                        <div class="analytic-single-block position-relative">
                          <div class="analytic-demography-icon">
                            <img src="./assets/images/district.png">
                          </div>
                          <h3 class="text-theme analytic-demography-key">
                            {{lan==0?'Sub Registrar office':'सब रजिस्ट्रार कार्यालय'}} :
                            <span class="analytic-demography-value text-dark">
                              {{lan==0?selectedMstDemographyBean?.location?.sroffice?.demographyNameEn:selectedMstDemographyBean?.location?.sroffice?.demographyNameHi}}
                            </span>
                          </h3>


                        </div>
                      </div>

                      <div *ngIf="selectedMstDemographyBean?.demographyType?.demographyAreaType=='R'"
                        class="new-design-box p-0 ">
                        <div class="analytic-single-block position-relative">

                          <div class="analytic-demography-icon">
                            <img src="./assets/images/areatype.png">
                          </div>
                          <h3 class="text-theme analytic-demography-key">{{'property-valuation.Area-Type' |
                            translate}}

                            <span class="analytic-demography-value text-dark">{{'property-valuation.Rural' | translate}}
                            </span>
                          </h3>


                        </div>
                      </div>

                      <div *ngIf="selectedMstDemographyBean?.demographyType?.demographyAreaType=='U'"
                        class="new-design-box p-0 ">
                        <div class="analytic-single-block position-relative">

                          <div class="analytic-demography-icon">
                            <img src="./assets/images/areatype.png">
                          </div>
                          <h3 class="text-theme analytic-demography-key">{{'property-valuation.Area-Type' | translate}}
                            :
                            <span class="mb-0 pb-0 analytic-demography-value  text-dark">{{'property-valuation.Urban' |
                              translate}}
                            </span>
                          </h3>


                        </div>
                      </div>
                      <div
                        *ngIf="selectedMstDemographyBean?.location?.tehsil?.demographyName!=null&&selectedMstDemographyBean?.location?.tehsil?.demographyName!=''"
                        class="new-design-box p-0 ">
                        <div class="analytic-single-block position-relative">
                          <div class="analytic-demography-icon">
                            <img src="./assets/images/tehsile.png">
                          </div>

                          <h3 class="text-theme analytic-demography-key">{{'property-valuation.Tehsil' | translate}}
                            <span class="mb-0 pb-0 analytic-demography-value  text-dark">
                              {{lan==0?selectedMstDemographyBean?.location?.tehsil?.demographyNameEn:selectedMstDemographyBean?.location?.tehsil?.demographyNameHi}}
                            </span>
                          </h3>


                        </div>
                      </div>
                      <div
                        *ngIf="selectedMstDemographyBean?.location?.localBody?.demographyName!=null&&selectedMstDemographyBean?.location?.localBody?.demographyName!=''"
                        class="new-design-box p-0 ">
                        <div class="analytic-single-block position-relative">

                          <div class="analytic-demography-icon">
                            <img src="./assets/images/tehsile.png">
                          </div>
                          <h3 class="text-theme analytic-demography-key">
                            {{'property-valuation.localbody' | translate}}
                            <span class="mb-0 pb-0 analytic-demography-value text-dark">
                              {{lan==0?selectedMstDemographyBean?.location?.localBody?.demographyNameEn:selectedMstDemographyBean?.location?.localBody?.demographyNameHi}}
                            </span>
                          </h3>


                        </div>
                      </div>
                      <div
                        *ngIf="selectedMstDemographyBean?.location?.ward?.demographyName!=null&&selectedMstDemographyBean?.location?.ward?.demographyName!=''"
                        class="new-design-box p-0 ">
                        <div class="analytic-single-block position-relative">

                          <div class="analytic-demography-icon">
                            <img src="./assets/images/village.png">
                          </div>
                          <h3 class="text-theme analytic-demography-key">{{'property-valuation.Ward' | translate}} :
                            <span class="mb-0 pb-0 analytic-demography-value">
                              {{lan==0?selectedMstDemographyBean?.location?.ward?.demographyNameEn:selectedMstDemographyBean?.location?.ward?.demographyNameHi}}
                            </span>
                          </h3>

                        </div>
                      </div>
                      <div
                        *ngIf="selectedMstDemographyBean?.location?.village?.demographyName!=null&&selectedMstDemographyBean?.location?.village?.demographyName!=''"
                        class="new-design-box p-0 ">
                        <div class="analytic-single-block position-relative">

                          <div class="analytic-demography-icon">
                            <img src="./assets/images/village.png">
                          </div>
                          <h3 class="text-theme analytic-demography-key">{{'property-valuation.Village' |
                            translate}}
                            <span class="mb-0 pb-0 analytic-demography-value text-dark">
                              {{lan==0?selectedMstDemographyBean?.location?.village?.demographyNameEn:selectedMstDemographyBean?.location?.village?.demographyNameHi}}
                            </span>
                          </h3>


                        </div>
                      </div>
                      <div
                        *ngIf="selectedMstDemographyBean?.location?.village_colony?.demographyName!=null&&selectedMstDemographyBean?.location?.village_colony?.demographyName!=''"
                        class="new-design-box p-0 ">
                        <div class="analytic-single-block position-relative">

                          <div class="analytic-demography-icon">
                            <img src="./assets/images/guidline.png">
                          </div>
                          <h3 class="text-theme analytic-demography-key">{{'property-valuation.village_colony' |
                            translate}} :
                            <span class="mb-0 pb-0 lh-13 truncate analytic-demography-value text-dark">
                              {{lan==0?selectedMstDemographyBean?.location?.village_colony?.demographyNameEn:selectedMstDemographyBean?.location?.village_colony?.demographyNameHi}}
                            </span>
                          </h3>

                        </div>

                        <div class="d-flex">
                          <p class="text-theme">
                            {{lan==0?selectedMstDemographyBean?.ulbTypeNmEn:selectedMstDemographyBean?.ulbTypeNmHi}}
                          </p>

                        </div>
                        <div>
                          <p class="text-theme">
                            {{lan==0?selectedMstDemographyBean?.isPlanningAreaNmEn:selectedMstDemographyBean?.isPlanningAreaNmHi}}
                          </p>

                        </div>
                      </div>
                      <div
                        *ngIf="selectedMstDemographyBean?.location?.ward_colony?.demographyName!=null&&selectedMstDemographyBean?.location?.ward_colony?.demographyName!=''"
                        class="new-design-box p-0">
                        <div class="analytic-single-block position-relative">

                          <div class="analytic-demography-icon">
                            <img src="./assets/images/guidline.png">
                          </div>
                          <h3 class="text-theme analytic-demography-key">{{'property-valuation.ward_colony' |
                            translate}}
                            <span class="mb-0 pb-0 lh-13 truncate analytic-demography-value text-dark">
                              {{lan==0?selectedMstDemographyBean?.location?.ward_colony?.demographyNameEn:selectedMstDemographyBean?.location?.ward_colony?.demographyNameHi}}
                            </span>
                          </h3>
                        </div>
                      </div>


                      <div
                        *ngIf="selectedMstDemographyBean?.location?.ward_colony?.demographyName!=null&&selectedMstDemographyBean?.location?.ward_colony?.demographyName!=''">
                        <p class="text-theme">
                          {{lan==0?selectedMstDemographyBean?.ulbTypeNmEn:selectedMstDemographyBean?.ulbTypeNmHi}}</p>

                        <p class="text-theme">
                          {{lan==0?selectedMstDemographyBean?.isPlanningAreaNmEn:selectedMstDemographyBean?.isPlanningAreaNmHi}}
                        </p>

                      </div>
                      <div class="btn-cllcc">
                        <button class="btn btn-primary w-100 d-flex align-items-center justify-content-center"
                          data-toggle="modal" data-target="#ViewGuidelineRates">
                          <mat-icon class="line-ht27 text-light mr-1">remove_red_eye</mat-icon>
                          {{'guideline.guideline' |
                          translate}}
                        </button>
                        <button class="btn btn-primary w-100 d-flex align-items-center justify-content-center mt-2"
                          (click)="initPrintMap()">
                          <mat-icon class="line-ht27 text-light mr-1">print</mat-icon>
                          {{'guideline.print-gl-rate' |
                          translate}}
                        </button>
                        <button class="btn btn-primary w-100 d-flex align-items-center justify-content-center mt-2"
                          data-toggle="modal" data-target="#shareGuidelineRates" (click)="openShareModel()">
                          <mat-icon class="line-ht27 text-light mr-1">share</mat-icon>
                          {{lan==0?"Share":"शेयर"}}
                        </button>
                      </div>

                    </div>
                  </mat-tab>
                </mat-tab-group>
              </div>
              <div class="address_search" *ngIf="startSearch"
                style="position: absolute;top: 21px;right: 50px;z-index: 1000;width: 400px;">
                <app-address-search [map]="map" [showOption]="false" [address]="qaddress"
                  [mpBoundaryGeom]="mpBoundaryGeom" (SearchLaodEvent)="searchEvent($event)"></app-address-search>
              </div>
              <app-map (MapLoadEvent)="receiveMap($event)" class="map-box"></app-map>

            </div>


          </div>
        </mat-tab>

        <mat-tab lass="tab_bg" label="{{'guideline.prvfyGdl' | translate}}">
          <div class="p-4 text-center">
            <a class="click-btn btn btn-primary" href="https://www.mpigr.gov.in/#/guidline-view" target="_blank">
              <span *ngIf="lan==0">Click here for viewing previous financial years guideline</span>
              <span *ngIf="lan==1">पिछले वित्तीय वर्षों की गाइडलाइन देखने के लिए यहां क्लिक करें</span>

            </a>
          </div>
        </mat-tab>




      </mat-tab-group>


    </div>
  </div>
  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" style="max-width:750px;" role="document">
      <div class="modal-content">
        <div class="badge-light modal-header p-1">
          <div style="text-align:center;">
            <h2 class="m-0 modal-title p-0" id="exampleModalLabel">{{'guideline.citizen-suggestion-form' | translate}}
            </h2>
          </div>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>

        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-4 mb-4">
              <label>{{'guideline.citizen-suggestion-form-financial-year' | translate}}
              </label>
              <!--<input type="text" name="financialYear" value="{{financialYear}}" class="form-control form-control-sm" readonly>-->

              <select class="form-control" [(ngModel)]="financialYear" name="financialYear" readonly>
                <option *ngFor="let finyearObject of financialyearList " [value]="finyearObject.id">
                  {{finyearObject.finYear}}</option>
              </select>
            </div>
            <div class="col-md-4">
              <label>{{'guideline.citizen-suggestion-form-district' | translate}}
              </label>
              <input value="{{selectedDistrict}}" class="form-control form-control-sm" readonly>
            </div>
            <div class="col-md-4">
              <label>{{'guideline.citizen-suggestion-form-office' | translate}}
              </label>
              <input value="{{selectedOffice}}" class="form-control form-control-sm" readonly>
            </div>
          </div>
          <form (ngSubmit)="onSubmitDraftSuggestion()" [formGroup]="saveSuggestionForm" novalidate autocomplete="off">
            <lib-alertmessage #alertMessage></lib-alertmessage>
            <div class="row">
              <div class="form-group col-md-12">
                <label>{{'guideline.citizen-suggestion-form-fullname' | translate}}
                  <span style="color: red;">*</span>
                </label>
                <div class="input-group mb-3">
                  <!-- <select [ngClass]="{ 'is-invalid':submitted && f.title.errors}"
                      id="title" formControlName="title" class="form-control form-control-sm"
                      style="max-width: 60px;padding: 0;" placeholder="Title">
                      <option [ngValue]="null" disabled>Title</option>
                      <option value="Mr.">Mr.</option>
                      <option value="Mrs.">Mrs.</option>
                      <option value="Miss">Miss</option>
                    </select>   -->
                  <input [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" id="firstName"
                    formControlName="firstName" class="form-control form-control-sm"
                    placeholder="{{'guideline.citizen-suggestion-form-enter-first-name' |translate}}" maxlength="60"
                    alpha [appCopyPastValidation]="true">
                  <input id="middleName" formControlName="middleName" class="form-control form-control-sm"
                    placeholder="{{'guideline.citizen-suggestion-form-enter-middle-name' |translate}}" maxlength="60"
                    alpha [appCopyPastValidation]="true">
                  <input [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" id="lastName"
                    formControlName="lastName" class="form-control form-control-sm"
                    placeholder="{{'guideline.citizen-suggestion-form-enter-last-name' |translate}}" maxlength="60"
                    alpha [appCopyPastValidation]="true">
                  <div *ngIf="submitted && (f.firstName.errors || f.lastName.errors)" class="invalid-feedback">
                    <div *ngIf="f.firstName.errors.required || f.lastName.errors.required">
                      {{'mandatory' | translate}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group col-md-6">
                <label>{{'guideline.citizen-suggestion-form-email'|translate}}
                  <span style="color: red;">*</span></label>
                <input type="text" class="form-control form-control-sm" formControlName="email"
                  placeholder="{{'guideline.citizen-suggestion-form-enter-email'|translate}} " maxlength="60"
                  (contextmenu)="false;" [appCopyPastValidation]="true"
                  [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                  pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                  <div *ngIf="f.email.errors.required">{{'mandatory' | translate}}</div>
                </div>
                <div *ngIf="f.email.errors" class="invalid-feedback-1">
                  <div *ngIf="f.email.errors.pattern">{{'guideline.citizen-suggestion-form-mail-validate' | translate}}
                  </div>
                </div>
              </div>
              <div class="form-group col-md-6">
                <label>{{'guideline.citizen-suggestion-form-mobile-number' |translate}} <span
                    style="color: red;">*</span></label>
                <input id="contactNo" type="text" class="form-control form-control-sm" formControlName="contactNo"
                  placeholder="{{'guideline.citizen-suggestion-form-mobile-enter-number' | translate}}" maxlength="10"
                  minlength="10" (contextmenu)="false;" [appCopyPastValidation]="true"
                  [ngClass]="{ 'is-invalid': submitted && f.contactNo.invalid }" pattern="^[6-9]\d{9}$"
                  [OnlyNumber]="true">
                <div *ngIf="submitted && f.contactNo.errors" class="invalid-feedback">
                  <div *ngIf="f.contactNo.errors.required">{{'mandatory' | translate}}</div>
                </div>
                <div *ngIf="f.contactNo.errors" class="invalid-feedback">
                  <div *ngIf="f.contactNo.errors.pattern">{{'guideline.citizen-suggestion-form-mobile-validate' |
                    translate}}</div>
                </div>
              </div>
              <div class="form-group col-md-12">
                <label>{{'guideline.citizen-suggestion-form-address'|translate}} <span
                    style="color: red;">*</span></label>
                <textarea [ngClass]="{ 'is-invalid': submitted && f.address.errors }" formControlName="address"
                  (contextmenu)="false;" [appCopyPastValidation]="true" class="form-control form-control-sm"
                  maxlength="120" appAlphaNumericSpace
                  placeholder="{{'guideline.citizen-suggestion-form-enter-address' |translate}}"
                  [appCopyPastValidation]="true" required>
                  </textarea>
                <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                  <div *ngIf="f.address.errors.required">{{'mandatory' | translate}}</div>
                </div>
              </div>
              <div class="form-group col-md-12">
                <label>{{'guideline.citizen-suggestion-form-suggestion' | translate}}<span
                    style="color: red;">*</span></label>
                <textarea [ngClass]="{ 'is-invalid': submitted && f.suggestion.errors }" formControlName="suggestion"
                  class="form-control form-control-sm"
                  placeholder="{{'guideline.citizen-suggestion-form-enter-suggestion' |translate}}" maxlength="500"
                  (contextmenu)="false;" required appAlphaNumericSpace [appCopyPastValidation]="true">
                  </textarea>
                <div *ngIf="submitted && f.suggestion.errors" class="invalid-feedback">
                  <div *ngIf="f.suggestion.errors.required">{{'mandatory' | translate}}</div>
                </div>
              </div>
            </div>
            <div>
              <button *ngIf="!otpVerification && !verifiedOTP" type="submit" id="submit" name="submit" value="submit"
                class="btn btn-primary mb-2">{{'guideline.citizen-suggestion-form-otp-verification' |
                translate}}</button>
            </div>

            <div class="form-group">

              <label class="labelH-40">{{'guideline.GL_DRAFT_SUSSESTION_UPLOAD' | translate}} </label>

              <input type="file" class="form-control p-0" id="docPath" formControlName="content"
                (change)="doc1($event)">

              <input type="file" type="hidden" name="fileHidden" formControlName="docPath">

              <div *ngIf="!fileErrorMsg1" class="upload-invalid-feedback">
                <div>{{'guideline.gl-Draft-suggestion-file-type-error' | translate}}</div>
              </div>

            </div>
          </form>

          <form *ngIf="otpVerification">
            <div class="row">
              <div class="col-md-5 col-sm-6 col-6">
                <div class="input-group mb-2" style="align-content: center;">
                  <img src="{{captchaImageBase64Img}}" height="50" />
                </div>
              </div>
              <div class="col-md-1 col-sm-6 col-6 text-center">
                <img src="assets/images/refresh_image.png" height="26px" class="mt-2" (click)="getStartupCaptcha()" />
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="input-group">
                  <input type="text" name="captchaStr" [(ngModel)]="captchaStr" required minlength="4" maxlength="4"
                    class="form-control input_user p-3" placeholder="{{'enter-captcha' | translate}}">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <button mat-raised-button *ngIf="counter == 0 && !otpTouched" mat-button class="btn btn-primary"
                  (click)="setOTPClicked(f.contactNo.value)">{{'get-otp' | translate}}</button>
                <!-- <button  mat-button  *ngIf="counter == 0" class="btn btn-primary" (click)="resetForm()">
                      {{'reset' | translate}}</button> -->
              </div>
            </div>
            <div class="p-3 mt-3" style="background-color: #0000000a!important;" *ngIf="getOtpClicked">
              <div class="row">
                <div class="col-md-12">
                  <div class="input-group mb-3">
                    <label class="w-100 d-flex align-items-center justify-content-between">
                      <span class="float-left">{{'enter-otp' | translate}}</span>
                      <span class="float-right" *ngIf="counter != 0">{{counter | formatTime}}</span>
                    </label>
                    <input class="form-control input_user" name="otp" [(ngModel)]="otp" minlength="6" maxlength="6"
                      required [OnlyNumber]="true" placeholder="{{'enter-otp' | translate}}">
                  </div>
                </div>
              </div>
            </div>
            <div class="" *ngIf="getOtpClicked">
              <button *ngIf="counter > 0" class="btn btn-primary" type="button" (click)="verifyOtp()">{{'verify-otp' |
                translate}}</button>
              <button *ngIf="counter == 0 && otpTouched" mat-button class="btn  btn-primary"
                (click)="setOTPClicked(f.contactNo.value)">{{'resend-otp' | translate}}</button>
            </div>
          </form>

        </div>
        <div class="modal-footer">
          <button *ngIf="verifiedOTP" type="submit" id="submit" name="submit" value="submit"
            (click)="submitDraftSuggestion()" class="btn btn-primary">{{'guideline.citizen-suggestion-form-save' |
            translate}}</button>
          <button type="button" class="btn btn-secondary" (click)="resetForm()"
            data-dismiss="modal">{{'guideline.citizen-suggestion-form-cancel' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal right  fade leftbox_detail new_width" id="ViewGuidelineRates" data-backdrop="false"
  data-keyboard="false" tabindex="-1" aria-labelledby="ViewGuidelineRates" aria-hidden="true">
  <!-- <div class="modal-dialog float-right"> -->
  <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content d-block">
      <div class="modal-header bg-theme p-0">
        <h3 class="text-light p-1 mb-1 font-weight-normal fixed-top1">{{'property-valuation.GuildeLine-Rate-Details' |
          translate}}
          <!-- {{'property-valuation.GuildeLine-Rate-Details' | translate}} {{guildeLineObj?.financialYear}} -->
          <button type="button" class="colsebtn  h4 m-0" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </h3>
      </div>

      <div class="modal-body p-2">
        <div class="row">
          <div class="col-md-12">
            <fieldset class="legend-section">
              <legend class="sidebar__widget-title">
                {{'property-valuation.ward_colony' | translate}}
              </legend>
              <div class="table-responsive shadow-none">
                <table class="table table-bordered">
                  <tr>
                    <th>{{'property-valuation.District' |
                      translate}}</th>
                    <th>
                      {{'property-valuation.Area-Type' |
                      translate}}
                    </th>
                    <th
                      *ngIf="selectedMstDemographyBean?.location?.tehsil?.demographyName!=null&&selectedMstDemographyBean?.location?.tehsil?.demographyName!=''">
                      {{'property-valuation.Tehsil' |
                      translate}}
                    </th>
                    <th
                      *ngIf="selectedMstDemographyBean?.location?.localBody?.demographyName!=null&&selectedMstDemographyBean?.location?.localBody?.demographyName!=''">
                      {{'property-valuation.localbody' |
                      translate}}
                    </th>
                    <th
                      *ngIf="selectedMstDemographyBean?.location?.ward?.demographyName!=null&&selectedMstDemographyBean?.location?.ward?.demographyName!=''">
                      {{'property-valuation.Ward' | translate}}
                    </th>
                    <th
                      *ngIf="selectedMstDemographyBean?.location?.village?.demographyName!=null&&selectedMstDemographyBean?.location?.village?.demographyName!=''">
                      {{'property-valuation.Village' |
                      translate}}
                    </th>
                    <th
                      *ngIf="selectedMstDemographyBean?.location?.village_colony?.demographyName!=null&&selectedMstDemographyBean?.location?.village_colony?.demographyName!=''">
                      {{'property-valuation.village_colony' |
                      translate}}
                    </th>

                    <th
                      *ngIf="selectedMstDemographyBean?.location?.ward_colony?.demographyName!=null&&selectedMstDemographyBean?.location?.ward_colony?.demographyName!=''">
                      {{'property-valuation.ward_colony' |
                      translate}}
                    </th>
                  </tr>
                  <tr>
                    <td>
                      {{lan==0?selectedMstDemographyBean?.location?.district?.demographyNameEn:selectedMstDemographyBean?.location?.district?.demographyNameHi}}
                    </td>
                    <td>
                      <ng-container *ngIf="selectedMstDemographyBean?.demographyType?.demographyAreaType=='R'">
                        {{'property-valuation.Rural' | translate}}
                      </ng-container>
                      <ng-container *ngIf="selectedMstDemographyBean?.demographyType?.demographyAreaType=='U'">
                        {{'property-valuation.Urban' | translate}}
                      </ng-container>

                    </td>
                    <td
                      *ngIf="selectedMstDemographyBean?.location?.tehsil?.demographyName!=null&&selectedMstDemographyBean?.location?.tehsil?.demographyName!=''">
                      {{lan==0?selectedMstDemographyBean?.location?.tehsil?.demographyNameEn:selectedMstDemographyBean?.location?.tehsil?.demographyNameHi}}
                    </td>
                    <td
                      *ngIf="selectedMstDemographyBean?.location?.localBody?.demographyName!=null&&selectedMstDemographyBean?.location?.localBody?.demographyName!=''">
                      {{lan==0?selectedMstDemographyBean?.location?.localBody?.demographyNameEn:selectedMstDemographyBean?.location?.localBody?.demographyNameHi}}
                    </td>
                    <td
                      *ngIf="selectedMstDemographyBean?.location?.ward?.demographyName!=null&&selectedMstDemographyBean?.location?.ward?.demographyName!=''">
                      {{lan==0?selectedMstDemographyBean?.location?.ward?.demographyNameEn:selectedMstDemographyBean?.location?.ward?.demographyNameHi}}
                    </td>
                    <td
                      *ngIf="selectedMstDemographyBean?.location?.village?.demographyName!=null&&selectedMstDemographyBean?.location?.village?.demographyName!=''">
                      {{lan==0?selectedMstDemographyBean?.location?.village?.demographyNameEn:selectedMstDemographyBean?.location?.village?.demographyNameHi}}
                    </td>
                    <td
                      *ngIf="selectedMstDemographyBean?.location?.village_colony?.demographyName!=null&&selectedMstDemographyBean?.location?.village_colony?.demographyName!=''">
                      {{lan==0?selectedMstDemographyBean?.location?.village_colony?.demographyNameEn:selectedMstDemographyBean?.location?.village_colony?.demographyNameHi}}
                      <ng-container class="text-theme">
                        {{lan==0?selectedMstDemographyBean?.ulbTypeNmEn:selectedMstDemographyBean?.ulbTypeNmHi}}
                      </ng-container>
                      <ng-container class="text-theme">
                        {{lan==0?selectedMstDemographyBean?.isPlanningAreaNmEn:selectedMstDemographyBean?.isPlanningAreaNmHi}}
                      </ng-container>
                    </td>
                    <td
                      *ngIf="selectedMstDemographyBean?.location?.ward_colony?.demographyName!=null&&selectedMstDemographyBean?.location?.ward_colony?.demographyName!=''">
                      {{lan==0?selectedMstDemographyBean?.location?.ward_colony?.demographyNameEn:selectedMstDemographyBean?.location?.ward_colony?.demographyNameHi}}
                      <ng-container *ngIf="selectedMstDemographyBean.ulbType>0">
                        {{lan==0?selectedMstDemographyBean?.ulbTypeNmEn:selectedMstDemographyBean?.ulbTypeNmHi}}
                      </ng-container>
                      <ng-container *ngIf="selectedMstDemographyBean.isPlanningArea>0">
                        {{lan==0?selectedMstDemographyBean?.isPlanningAreaNmEn:selectedMstDemographyBean?.isPlanningAreaNmHi}}
                      </ng-container>
                    </td>
                  </tr>
                </table>
              </div>
            </fieldset>
          </div>
          <div class="col-md-6">
            <fieldset class="legend-section">
              <legend class="sidebar__widget-title">
                {{'guideline.plot' | translate}}{{'guideline.sqm' |
                translate}}
                <span class="text-right fs1" *ngIf="guildeLineObj?.isVishistGram!=0">
                  {{'property-valuation.is-Vishist-Gram' |translate}}{{'guideline.sqm' |
                  translate}}
                  <!-- <input _ngcontent-mpo-c212="" type="checkbox"
                                        formcontrolname="isVishistGram" id="isVishistGram" class="form-check-input"> -->
                  <i class="fa fa-check" aria-hidden="true"></i>
                </span>
              </legend>
              <div class="table-responsive shadow-none">
                <table class="table table-bordered">
                  <tr>
                    <th>{{'guideline.resi' | translate}}</th>
                    <th>{{'guideline.comm' | translate}}</th>
                    <th>{{'guideline.ind' | translate}}</th>
                  </tr>
                  <tr>
                    <td>&nbsp;{{guildeLineObj?.ratePlotResidential |amount}}</td>
                    <td>&nbsp;{{guildeLineObj?.ratePlotCommercial|amount}}</td>
                    <td>&nbsp;{{guildeLineObj?.ratePlotIndustrial |amount}}</td>
                  </tr>
                </table>
              </div>
            </fieldset>
          </div>
          <div class="col-md-6">
            <fieldset class="legend-section">
              <legend class="sidebar__widget-title">
                {{'guideline.buil-resi' | translate}}{{'guideline.sqm' |
                translate}}
              </legend>
              <div class=" table-responsive shadow-none">
                <table class="table table-bordered">
                  <tr>
                    <th>{{'guideline.rcc' | translate}} </th>
                    <th>{{'guideline.rbc' | translate}} </th>
                    <th> {{'guideline.tin_shade' | translate}}</th>
                    <th> {{'guideline.kaccha_abelu' | translate}}</th>
                  </tr>
                  <tr>
                    <td>&nbsp;{{guildeLineObj?.rateBuildingRcc |amount}}</td>
                    <td>&nbsp;{{guildeLineObj?.rateBuildingRbc |amount}}</td>
                    <td>&nbsp;{{guildeLineObj?.rateBuildingTShade|amount}}</td>
                    <td>&nbsp;{{guildeLineObj?.rateBuildingKabelu|amount}}</td>
                  </tr>
                </table>
              </div>
            </fieldset>
          </div>
          <div class="col-md-6">
            <fieldset class="legend-section">
              <legend class="sidebar__widget-title">
                {{'guideline.buil-comm' | translate}}{{'guideline.sqm' | translate}}
              </legend>
              <div class=" table-responsive shadow-none">
                <table class="table table-bordered">
                  <tr>
                    <th> {{'guideline.Shop' | translate}}</th>
                    <th> {{'guideline.office' | translate}}</th>
                    <th>{{'guideline.godown' | translate}}</th>
                  </tr>
                  <tr>
                    <td>&nbsp;{{guildeLineObj?.rateBuildingShop|amount}}</td>
                    <td>&nbsp;{{guildeLineObj?.rateBuildingOffice|amount}}</td>
                    <td>&nbsp;{{guildeLineObj?.rateBuildingGodown|amount}}</td>
                  </tr>
                </table>
              </div>
            </fieldset>
          </div>
          <div class="col-md-6">
            <fieldset class="legend-section">
              <legend class="sidebar__widget-title">
                {{'guideline.buil-multi' | translate}}{{'guideline.sqm' |
                translate}}
              </legend>
              <div class=" table-responsive shadow-none">
                <table class="table table-bordered">
                  <tr>
                    <th>{{'guideline.resi' | translate}}</th>
                    <th>{{'guideline.comm' | translate}}</th>
                  </tr>
                  <tr>
                    <td>&nbsp;{{guildeLineObj?.rateBuildingMultiResi|amount}}</td>
                    <td>&nbsp;{{guildeLineObj?.rateBuildingMultiComm |amount}}</td>
                  </tr>
                </table>
              </div>
            </fieldset>
          </div>
          <div class="col-md-6">
            <fieldset class="legend-section">
              <legend class="sidebar__widget-title">
                {{'guideline.agri-land' | translate}}{{'guideline.hectare' |
                translate}}
              </legend>
              <div class=" table-responsive shadow-none">
                <table class="table table-bordered">
                  <tr>
                    <th> {{'guideline.irgd' | translate}}</th>
                    <th>{{'guideline.unirgd' | translate}}</th>
                  </tr>
                  <tr>
                    <td>&nbsp;{{guildeLineObj?.rateAgriIrregated |amount}}</td>
                    <td>&nbsp;{{guildeLineObj?.rateAgriUnirregated |amount}}</td>
                  </tr>
                </table>
              </div>
            </fieldset>
          </div>
          <div class="col-md-6">
            <fieldset class="legend-section">
              <legend class="sidebar__widget-title">
                {{'guideline.agri-plot' | translate}}{{'guideline.sqm' |
                translate}}
              </legend>
              <div class="table-responsive shadow-none">
                <table class="table table-bordered">
                  <tr>
                    <th>{{'guideline.sub-clause-resi' | translate}}</th>
                    <th>{{'guideline.sub-clause-comm' | translate}}</th>
                  </tr>
                  <tr>
                    <td>&nbsp;{{guildeLineObj?.rateLandUptoResi |amount}}</td>
                    <td>&nbsp;{{guildeLineObj?.rateLandUptoComm |amount}}</td>
                  </tr>
                </table>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="modal right  fade leftbox_detail new_width" id="shareGuidelineRates" data-backdrop="false"
  data-keyboard="false" tabindex="-1" aria-labelledby="shareGuidelineRates" aria-hidden="true">
  <!-- <div class="modal-dialog float-right"> -->
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content d-block">
      <div class="modal-body p-2">
        <button type="button" class="colsebtn  h4 m-0" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="sharecontent">
          <p>Share this link via</p>
          <ul class="icons">
            <li title="facebook" (click)="socialclick('https://www.facebook.com/sharer/sharer.php?u=')">
              <mat-icon>facebook</mat-icon>
            </li>
            <li title="whatsapp" (click)="socialclick('https://wa.me/?text=')"><mat-icon>whatsapp</mat-icon></li>
            <li title="Twitter" (click)="socialclick('https://twitter.com/intent/tweet?text=')"><svg
                xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 24 24">
                <path
                  d="M10.053,7.988l5.631,8.024h-1.497L8.566,7.988H10.053z M21,6v12	c0,1.657-1.343,3-3,3H6c-1.657,0-3-1.343-3-3V6c0-1.657,1.343-3,3-3h12C19.657,3,21,4.343,21,6z M17.538,17l-4.186-5.99L16.774,7	h-1.311l-2.704,3.16L10.552,7H6.702l3.941,5.633L6.906,17h1.333l3.001-3.516L13.698,17H17.538z">
                </path>
              </svg></li>
          </ul>
          <p>Or copy link</p>
          <div class="field d-flex">
            <i class="url-icon uil uil-link "></i>
            <input type="text" readonly class="form-control" [(ngModel)]="currentShareUrl">
            <button class="btn btn-sm btn-primary" (click)="doCopy()">Copy</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<ngx-ui-loader></ngx-ui-loader>